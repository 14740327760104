// form
import DatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { CustomDatePickerProps } from './DatePicker.types';

const RHFDatePicker = ({
  name,
  label,
  rules,
  required = false,
  ...other
}: CustomDatePickerProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          inputFormat="dd/MM/yyyy"
          {...other}
          label={label}
          value={field.value}
          onChange={newValue => field.onChange(newValue)}
          renderInput={params => (
            <TextField
              {...params}
              {...field}
              fullWidth
              error={!!error}
              helperText={error?.message}
              required={required}
            />
          )}
        />
      )}
    />
  );
};

export { RHFDatePicker };
