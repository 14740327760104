import { DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModalBody = styled(DialogContent)(({ theme }) => ({
  minWidth: 360,
  marginTop: theme.spacing(2),
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.grey[600],
  paddingBottom: 0,
}));
