import { matchPath } from 'react-router';
import { IDCLayoutPageConfig } from './DoubleColLayout.types';

import SnowImageSrc from 'assets/images/mico-duomo.jpg';
import StadiumImageSrc from 'assets/images/mico-stadium.jpg';
import * as PATHS from 'constants/paths';

export const getLayoutConfig = (
  path: string,
  search: string
): IDCLayoutPageConfig => {
  const config: IDCLayoutPageConfig = {
    backgroundPosition: 'left',
    backgroundSrc: SnowImageSrc,
  };

  if (
    matchPath(PATHS.RESET_PASSWORD, path) ||
    (matchPath(PATHS.NEW_PASSWORD, path) && search.includes('code'))
  ) {
    config.backgroundPosition = 'center';
    config.backgroundSrc = StadiumImageSrc;
  }
  return config;
};
