const ArticleWhiteIcon = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 5.41667V20.5833H5V5.41667H19ZM19 3.25H5C3.9 3.25 3 4.225 3 5.41667V20.5833C3 21.775 3.9 22.75 5 22.75H19C20.1 22.75 21 21.775 21 20.5833V5.41667C21 4.225 20.1 3.25 19 3.25Z"
        fill="white"
      />
      <path
        d="M14 18.4167H7V16.25H14V18.4167ZM17 14.0833H7V11.9167H17V14.0833ZM17 9.75H7V7.58333H17V9.75Z"
        fill="white"
      />
    </svg>
  );
};

export default ArticleWhiteIcon;
