const CloseIcon = ({ fill = '#212B36' }) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4158 6.00409L11.7158 1.71409C12.1079 1.32197 12.1079 0.686214 11.7158 0.294092C11.3237 -0.0980305 10.6879 -0.0980305 10.2958 0.294092L6.0058 4.59409L1.7158 0.294092C1.32368 -0.0980305 0.687921 -0.0980305 0.295798 0.294092C-0.0963238 0.686214 -0.0963238 1.32197 0.295798 1.71409L4.5958 6.00409L0.295798 10.2941C0.106486 10.4819 0 10.7375 0 11.0041C0 11.2707 0.106486 11.5263 0.295798 11.7141C0.483565 11.9034 0.739161 12.0099 1.0058 12.0099C1.27244 12.0099 1.52803 11.9034 1.7158 11.7141L6.0058 7.41409L10.2958 11.7141C10.4836 11.9034 10.7392 12.0099 11.0058 12.0099C11.2724 12.0099 11.528 11.9034 11.7158 11.7141C11.9051 11.5263 12.0116 11.2707 12.0116 11.0041C12.0116 10.7375 11.9051 10.4819 11.7158 10.2941L7.4158 6.00409Z"
        fill={fill}
      />
    </svg>
  );
};

export default CloseIcon;
