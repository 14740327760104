const CheckIcon = () => {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.39482 9.49971C4.18695 9.49904 3.98868 9.41213 3.84732 9.25971L0.202322 5.38221C-0.081414 5.07983 -0.0663036 4.6047 0.236072 4.32096C0.538448 4.03722 1.01359 4.05233 1.29732 4.35471L4.38732 7.64721L10.6948 0.74721C10.8692 0.530021 11.1497 0.427386 11.4231 0.480666C11.6965 0.533947 11.9178 0.734365 11.9979 1.00115C12.078 1.26794 12.0037 1.55714 11.8048 1.75221L4.94982 9.25221C4.80979 9.4074 4.61134 9.49711 4.40232 9.49971H4.39482Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckIcon;
