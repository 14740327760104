const TrashIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 13.9999H24V12.3299C23.9765 11.6897 23.7002 11.085 23.2316 10.6482C22.7629 10.2114 22.1402 9.97831 21.5 9.99991H18.5C17.8598 9.97831 17.2371 10.2114 16.7684 10.6482C16.2998 11.085 16.0235 11.6897 16 12.3299V13.9999H11C10.7348 13.9999 10.4804 14.1053 10.2929 14.2928C10.1054 14.4803 10 14.7347 10 14.9999C10 15.2651 10.1054 15.5195 10.2929 15.707C10.4804 15.8946 10.7348 15.9999 11 15.9999H12V26.9999C12 27.7956 12.3161 28.5586 12.8787 29.1212C13.4413 29.6838 14.2044 29.9999 15 29.9999H25C25.7956 29.9999 26.5587 29.6838 27.1213 29.1212C27.6839 28.5586 28 27.7956 28 26.9999V15.9999H29C29.2652 15.9999 29.5196 15.8946 29.7071 15.707C29.8946 15.5195 30 15.2651 30 14.9999C30 14.7347 29.8946 14.4803 29.7071 14.2928C29.5196 14.1053 29.2652 13.9999 29 13.9999ZM18 12.3299C18 12.1699 18.21 11.9999 18.5 11.9999H21.5C21.79 11.9999 22 12.1699 22 12.3299V13.9999H18V12.3299ZM26 26.9999C26 27.2651 25.8946 27.5195 25.7071 27.707C25.5196 27.8946 25.2652 27.9999 25 27.9999H15C14.7348 27.9999 14.4804 27.8946 14.2929 27.707C14.1054 27.5195 14 27.2651 14 26.9999V15.9999H26V26.9999ZM17 24.9999C17.2652 24.9999 17.5196 24.8946 17.7071 24.707C17.8946 24.5195 18 24.2651 18 23.9999V19.9999C18 19.7347 17.8946 19.4803 17.7071 19.2928C17.5196 19.1053 17.2652 18.9999 17 18.9999C16.7348 18.9999 16.4804 19.1053 16.2929 19.2928C16.1054 19.4803 16 19.7347 16 19.9999V23.9999C16 24.2651 16.1054 24.5195 16.2929 24.707C16.4804 24.8946 16.7348 24.9999 17 24.9999ZM23.7071 24.707C23.5196 24.8946 23.2652 24.9999 23 24.9999C22.7348 24.9999 22.4804 24.8946 22.2929 24.707C22.1054 24.5195 22 24.2651 22 23.9999V19.9999C22 19.7347 22.1054 19.4803 22.2929 19.2928C22.4804 19.1053 22.7348 18.9999 23 18.9999C23.2652 18.9999 23.5196 19.1053 23.7071 19.2928C23.8946 19.4803 24 19.7347 24 19.9999V23.9999C24 24.2651 23.8946 24.5195 23.7071 24.707Z"
        fill="#637381"
      />
    </svg>
  );
};

export default TrashIcon;
