import {
  Box,
  Link,
  Breadcrumbs as MUIBreadcrumbs,
  Typography,
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import { IBreadcrumbProps, LinkItemProps } from './Breadcrumbs.types';

const Breadcrumbs = ({
  links,
  activeLast = false,
  ...other
}: IBreadcrumbProps) => {
  const listDefault = links.map(link => (
    <LinkItem key={link.key} link={link} />
  ));

  const listActiveLast = links.map((link, idx) => (
    <div key={link.key}>
      {idx < links.length - 1 ? (
        <>{link.breadcrumb ? <LinkItem link={link} /> : null}</>
      ) : (
        <Typography
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'text.disabled',
            textOverflow: 'ellipsis',
          }}
        >
          {link.breadcrumb}
        </Typography>
      )}
    </div>
  ));

  return (
    <MUIBreadcrumbs
      separator={
        <Box
          component="span"
          sx={{
            width: 4,
            height: 4,
            borderRadius: '50%',
            bgcolor: 'text.disabled',
          }}
        />
      }
      {...other}
    >
      {activeLast ? listDefault : listActiveLast}
    </MUIBreadcrumbs>
  );
};

const LinkItem = ({ link }: LinkItemProps) => {
  const { match, key } = link;
  return (
    <Link
      key={key}
      variant="body2"
      component={RouterLink}
      to={match.pathname || '#'}
      sx={{
        lineHeight: 2,
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
        '& > div': { display: 'inherit' },
      }}
    >
      {link.breadcrumb}
    </Link>
  );
};

export { Breadcrumbs };
