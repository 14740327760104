/**
 * A dummy template for a Zustand store: eg. Global Store
 */
import { useCallback } from 'react';
import create from 'zustand';

/**
 * An interface defining the shape of GlobalStore object
 *
 * @typeParam sharedValue - string representing a dummy shared value
 * @typeParam setSharedValue - function representing a dummy "setter" for sharedValue (has access to current state returned by zustand)
 *
 */
interface GlobalStore {
  sharedValue: string | null;
  setSharedValue: (newValue: string) => void;
}

const useStore = create<GlobalStore>(
  (set): GlobalStore => ({
    sharedValue: null,
    setSharedValue: (newValue: string) =>
      set(() => ({ sharedValue: newValue })),
  })
);

const useGlobalSelector = () => useStore(useCallback(state => state, []));

/**
 * Export zustand own UseBoundStore object and our custom useGlobalSelectore for direct store acess
 *
 * @example
 * Direct state access from a page:
 * ```
 * import { useGlobalSelector } from 'stores';
 *
 * const { sharedValue } = useGlobalSelector();
 *
 * ```
 *
 * @example
 * Direct state manipulation from a page:
 * ```
 * import { useGlobalSelector } from 'stores';
 *
 * const { setSharedValue } = useGlobalSelector();
 *
 * setSharedValue('new string')
 *
 * ```
 *
 * @example
 * Zustand "standard" usage: reading the state
 * ```
 * import { useGlobalStore } from 'stores';
 *
 * const sharedValue = useGlobalStore(state => state.sharedValue);
 *
 * setGlobalState({ sharedValue: 'new string' });
 *
 * ```
 *
 * @example
 * Zustand "standard" usage: setting the state
 * ```
 * import { useGlobalStore } from 'stores';
 *
 * const { setState: setGlobalState } = useGlobalStore;
 *
 * setGlobalState({ sharedValue: 'new string' });
 *
 * ```
 */

// export { useGlobalSelector, useStore as useGlobalStore };
export { useGlobalSelector as useSampleSelector, useStore as useSampleStore };
