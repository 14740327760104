// form
import { FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { Props } from './Editor.types';
import Editor from 'components/editor';
//

// ----------------------------------------------------------------------

const RHFEditor = ({ name, ...other }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Editor
          id={name}
          value={field.value}
          onChange={field.onChange}
          error={!!error}
          helperText={
            <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
              {error?.message}
            </FormHelperText>
          }
          {...other}
        />
      )}
    />
  );
};

export { RHFEditor };
