// form
import { MenuItem, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { CustomSelectProps } from './Select.types';

const RHFSelect = ({
  name,
  rules,
  options = [],
  onChange,
  ...other
}: CustomSelectProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            select
            variant={'outlined'}
            {...field}
            fullWidth
            error={!!error}
            {...other}
            onChange={e => {
              field.onChange(e.target.value);
              onChange?.(e);
            }}
          >
            {options.map((el, idx) => (
              <MenuItem key={idx} value={el.id}>
                {el.name}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
    />
  );
};

export { RHFSelect };
