const ShoppingBagWhiteIcon = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6.50033H16C16 4.10616 14.21 2.16699 12 2.16699C9.79 2.16699 8 4.10616 8 6.50033H6C4.9 6.50033 4 7.47533 4 8.66699V21.667C4 22.8587 4.9 23.8337 6 23.8337H18C19.1 23.8337 20 22.8587 20 21.667V8.66699C20 7.47533 19.1 6.50033 18 6.50033ZM12 4.33366C13.1 4.33366 14 5.30866 14 6.50033H10C10 5.30866 10.9 4.33366 12 4.33366ZM18 21.667H6V8.66699H8V10.8337C8 11.4295 8.45 11.917 9 11.917C9.55 11.917 10 11.4295 10 10.8337V8.66699H14V10.8337C14 11.4295 14.45 11.917 15 11.917C15.55 11.917 16 11.4295 16 10.8337V8.66699H18V21.667Z"
        fill="white"
      />
    </svg>
  );
};

export default ShoppingBagWhiteIcon;
