const HotelWhiteIcon = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 8.00008C20 6.80841 19.1 5.83342 18 5.83342V2.58341C18 1.39175 17.1 0.416748 16 0.416748H4C2.9 0.416748 2 1.39175 2 2.58341V5.83342C0.9 5.83342 0 6.80841 0 8.00008V13.4167H1.33L2 15.5834H3L3.67 13.4167H16.34L17 15.5834H18L18.67 13.4167H20V8.00008ZM16 5.83342H11V2.58341H16V5.83342ZM4 2.58341H9V5.83342H4V2.58341ZM2 8.00008H18V11.2501H2V8.00008Z"
        fill="white"
      />
    </svg>
  );
};

export default HotelWhiteIcon;
