import { ReactNode } from 'react';
import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  b: (str: ReactNode) => <strong>{str}</strong>,
};

export const hotelsTranslations = (intl: IntlShape) => ({
  hotels: {
    title: intl.formatMessage({
      defaultMessage: 'Hotels',
      description: 'Hotels Page title',
    }),
    hotelList: {
      title: intl.formatMessage({
        defaultMessage: 'Hotels',
        description: 'Hotels Page title',
      }),
      dialog: {
        singleDeleteConfirmTitle: intl.formatMessage({
          defaultMessage: 'Confirm Delete of selected Hotel',
          description: 'Hotel List dialog singledeleteconfirmtitle',
        }),
        singleDeleteConfirmContent: intl.formatMessage({
          defaultMessage: 'Are you sure you want to delete the selected Hotel?',
          description: 'Hotel List dialog singledeleteconfirmcontent',
        }),
        multipleDeleteConfirmTitle: intl.formatMessage({
          defaultMessage: 'Confirm Delete of selected Hotels',
          description: 'Hotel List dialog multipledeleteconfirmtitle',
        }),
        multipleDeleteConfirmContent: intl.formatMessage({
          defaultMessage:
            'Are you sure you want to delete the selected Hotels?',
          description: 'Hotel List dialog multipledeleteconfirmcontent',
        }),
        singleDeletErrorTitle: intl.formatMessage({
          defaultMessage: 'Cannot delete Hotel',
          description: 'Hotel List dialog singledeletError title',
        }),
        singleDeletErrorContent: intl.formatMessage({
          defaultMessage:
            'An error occurred dring the elimination of the Hotel',
          description: 'Hotel List dialog singledeletError content',
        }),
        multipleDeletErrorTitle: intl.formatMessage({
          defaultMessage: 'Cannote delete Hotels',
          description: 'Hotel List dialog multipledeletError title',
        }),
        multipleDeletErrorContent: intl.formatMessage({
          defaultMessage:
            'An error occurred dring the elimination of the selected Hotels',
          description: 'Hotel List dialog multipledeletError title',
        }),
      },
      buttons: {
        addHotel: intl.formatMessage({
          defaultMessage: 'Add Hotel',
          description: 'Hotels Page Add hotel button',
        }),
      },
    },
    errors: {
      import: intl.formatMessage({
        defaultMessage: 'Something went wrong, please try again later',
        description: 'Hotels Page errors import',
      }),
      importExceed: intl.formatMessage({
        defaultMessage:
          'You are trying to import too many hotels, the maximum number allowed is',
        description: 'Hotels dialogs import error exceed',
      }),
    },
    dialogs: {
      import: {
        title: intl.formatMessage({
          defaultMessage: 'Massive import',
          description: 'Hotels dialogs import title',
        }),
        body: intl.formatMessage({
          defaultMessage: 'Select the action to take',
          description: 'Hotels dialogs import body',
        }),
        buttons: {
          save: intl.formatMessage({
            defaultMessage: 'Ok',
            description: 'Hotels dialogs import buttons save',
          }),
          cancel: intl.formatMessage({
            defaultMessage: 'Cancel',
            description: 'Hotels dialogs import buttons cancel',
          }),
        },
        options: {
          download: intl.formatMessage({
            defaultMessage: 'Download the template for insertion',
            description: 'Hotels dialogs import options download',
          }),
          upload: intl.formatMessage({
            defaultMessage: 'Upload data for insertion',
            description: 'Hotels dialogs import options upload',
          }),
        },
      },
    },
    hotelConfiguration: {
      title: intl.formatMessage({
        defaultMessage: 'Hotel Configuration',
        description: 'Hotel Configuration Page title',
      }),
      infoPanel: {
        fo: intl.formatMessage({
          defaultMessage:
            'Enter within each section and enter the required information. View the contract preview at any time. Once all the steps have been filled in, in the Contract preview section you will be able to view all the information entered. After reading it carefully you can choose to go ahead and generate the contract or go back and make changes. Once the contract has been generated you can consult it in the appropriate section, download it, sign it and upload it for the submit. ',
          description: 'Hotel Configuration page Front office info panel text',
        }),
      },
      errors: {
        generic: intl.formatMessage({
          defaultMessage: 'An error occurred generating the hotel',
          description: 'Hotel Configuration errors generic',
        }),
        maxPriceAlertDialog: {
          title: intl.formatMessage({
            defaultMessage: 'Alert',
            description: 'Hotel Configuration errors maxPriceAlertDialog title',
          }),
          body: intl.formatMessage({
            defaultMessage:
              'The maximum price of the rooms is not respected. Please double check the parameters that affect the maximum price ("CLUSTER" in the Proprietor section and "CATEGORY" in the Type Of Accomodation section) or re-enter the price values.',
            description: 'Hotel Configuration errors maxPriceAlertDialog body',
          }),
        },
        roomsBlockAlertDialog: {
          title: intl.formatMessage({
            defaultMessage: 'Alert',
            description:
              'Hotel Configuration errors roomsBlockAlertDialog title',
          }),
          body: intl.formatMessage({
            defaultMessage:
              'To be able to fill out this card you must first have filled out the Type of accommodation card.',
            description:
              'Hotel Configuration errors roomsBlockAlertDialog body',
          }),
        },
      },
      cards: {
        proprietor: intl.formatMessage({
          defaultMessage: 'Proprietor',
          description: 'Hotel Configuration card proprietor title',
        }),
        typeOfAccomodation: intl.formatMessage({
          defaultMessage: 'Type of accomodation',
          description: 'Hotel Configuration card typeOfAccomodation title',
        }),
        works: intl.formatMessage({
          defaultMessage: 'Works',
          description: 'Hotel Configuration card works title',
        }),
        badBreackfast: intl.formatMessage({
          defaultMessage: 'Bed & Breakfast',
          description: 'Hotel Configuration card badBreackfast title',
        }),
        roomsFormula: intl.formatMessage({
          defaultMessage: 'Rooms & Formula for Guest',
          description: 'Hotel Configuration card roomsFormula title',
        }),
        commercialAgreements: intl.formatMessage({
          defaultMessage: 'Commercial agreements',
          description: 'Hotel Configuration card commercialAgreements title',
        }),
        banquetsMeetingRooms: intl.formatMessage({
          defaultMessage: 'Banquets & meeting rooms',
          description: 'Hotel Configuration card typeOfAccomodation title',
        }),
        equipment: intl.formatMessage({
          defaultMessage: 'Equipment',
          description: 'Hotel Configuration card equipment title',
        }),
        garagesAndParking: intl.formatMessage({
          defaultMessage: 'Garages and Parking',
          description: 'Hotel Configuration card garagesAndParking title',
        }),
        contractPreview: intl.formatMessage({
          defaultMessage: 'Contract Preview',
          description: 'Hotel Configuration card contractPreview title',
        }),
        subtitileStart: intl.formatMessage({
          defaultMessage: 'Start filling in',
          description: 'Hotel Configuration card start sub title',
        }),
        subtitileReady: intl.formatMessage({
          defaultMessage: 'Ready to Fulfill',
          description: 'Hotel Configuration card ready sub title',
        }),
      },
      contractSectionTitle: intl.formatMessage({
        defaultMessage: 'Official Agreements',
        description: 'Hotel Configuration contract section title',
      }),
      contractSectionLabel: intl.formatMessage({
        defaultMessage:
          'Once the contract has been generated you can download it and it will always be available in this section.',
        description: 'Hotel Configuration contract section label',
      }),
    },
    import: {
      title: intl.formatMessage({
        defaultMessage: 'Import data for insertion',
        description: 'Import Page title',
      }),
      uploaderTitle: intl.formatMessage({
        defaultMessage: 'Select the file to import',
        description: 'Import Page uploader title',
      }),
      buttons: {
        cancel: intl.formatMessage({
          defaultMessage: 'Cancel',
          description: 'Import Page buttons cancel',
        }),
        import: intl.formatMessage({
          defaultMessage: 'Import',
          description: 'Import Page buttons import',
        }),
      },
    },
    importRecap: {
      title: intl.formatMessage({
        defaultMessage: 'Recap import data',
        description: 'Import Recap Page title',
      }),
      recapHeader: {
        title: intl.formatMessage({
          defaultMessage: 'The upload was successful',
          description: 'Import Recap Page header title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            'The import of the data for the insertion was successful',
          description: 'Import Recap Page header subtitle',
        }),
      },
      cards: {
        totalTitle: intl.formatMessage({
          defaultMessage: 'Total Records',
          description: 'Import Recap Page cards total title',
        }),
        successTitle: intl.formatMessage({
          defaultMessage: 'Successfully Imported Records',
          description: 'Import Recap Page cards success title',
        }),
        failedTitle: intl.formatMessage({
          defaultMessage: 'Not Imported Records',
          description: 'Import Recap Page cards failed title',
        }),
        download: intl.formatMessage({
          defaultMessage: 'Download',
          description: 'Import Recap Page cards download button',
        }),
      },
    },
    proprietor: {
      title: intl.formatMessage({
        defaultMessage: 'Proprietor',
        description: 'Proprietor Page title',
      }),
      fields: {
        nameHotel: intl.formatMessage({
          defaultMessage: 'Name of the Hotel',
          description: 'Proprietor form nameHotel field label',
        }),
        emailHotel: intl.formatMessage({
          defaultMessage: 'Email hotel',
          description: 'Proprietor form emailHotel field label',
        }),
        addressHotel: intl.formatMessage({
          defaultMessage: 'Address of Hotel',
          description: 'Proprietor form addressHotel field label',
        }),
        postCode: intl.formatMessage({
          defaultMessage: 'Postcode',
          description: 'Proprietor form postCode field label',
        }),
        nation: intl.formatMessage({
          defaultMessage: 'Nation',
          description: 'Proprietor form nation field label',
        }),
        province: intl.formatMessage({
          defaultMessage: 'Province',
          description: 'Proprietor form province field label',
        }),
        city: intl.formatMessage({
          defaultMessage: 'City',
          description: 'Proprietor form city field label',
        }),
        companyName: intl.formatMessage({
          defaultMessage: 'Company Name',
          description: 'Proprietor form companyName field label',
        }),
        cluster: intl.formatMessage({
          defaultMessage: 'Cluster',
          description: 'Proprietor form cluster field label',
        }),
        thelephoneNo: intl.formatMessage({
          defaultMessage: 'Telephone No.',
          description: 'Proprietor form thelephoneNo field label',
        }),

        faxHotel: intl.formatMessage({
          defaultMessage: 'Fax No.',
          description: 'Proprietor form faxHotel field label',
        }),
        legalRepresentative: intl.formatMessage({
          defaultMessage: 'Legal Rapresentative (CEO/Chairman)',
          description: 'Proprietor form legalRapresentative field label',
        }),
        contractContact: intl.formatMessage({
          defaultMessage: 'Contract Contact',
          description: 'Proprietor form contractContact field label',
        }),
        position: intl.formatMessage({
          defaultMessage: 'Position',
          description: 'Proprietor form position field label',
        }),
        registerdOffice: intl.formatMessage({
          defaultMessage: 'Register Office',
          description: 'Proprietor form registerdOffice field label',
        }),
        contactPostCode: intl.formatMessage({
          defaultMessage: 'Postcode',
          description: 'Proprietor form contactPostCode field label',
        }),
        contactNation: intl.formatMessage({
          defaultMessage: 'Nation',
          description: 'Proprietor form contactNation field label',
        }),
        contactProvince: intl.formatMessage({
          defaultMessage: 'Province',
          description: 'Proprietor form contactProvince field label',
        }),
        contactCity: intl.formatMessage({
          defaultMessage: 'City',
          description: 'Proprietor form contactCity field label',
        }),
        faxNo: intl.formatMessage({
          defaultMessage: 'Fax No.',
          description: 'Proprietor form faxNo field label',
        }),
        website: intl.formatMessage({
          defaultMessage: 'Website',
          description: 'Proprietor form website field label',
        }),
        pec: intl.formatMessage({
          defaultMessage: 'Pec',
          description: 'Proprietor form pec field label',
        }),
        managingDirector: intl.formatMessage({
          defaultMessage: 'Managing Director',
          description: 'Proprietor form managingDirector field label',
        }),
        salesManager: intl.formatMessage({
          defaultMessage: 'Sales Manager',
          description: 'Proprietor form salesManager field label',
        }),
        vatNo: intl.formatMessage({
          defaultMessage: 'Vat No.',
          description: 'Proprietor form vatNo field label',
        }),
        bankRef: intl.formatMessage({
          defaultMessage: 'Bank Ref. ',
          description: 'Proprietor form bankRef field label',
        }),
        accountNo: intl.formatMessage({
          defaultMessage: 'Account No.',
          description: 'Proprietor form accountNo field label',
        }),
        invoiceRecipientCode: intl.formatMessage({
          defaultMessage: 'E-invoice recipient code',
          description: 'Proprietor form invoiceRecipientCode field label',
        }),
        abi: intl.formatMessage({
          defaultMessage: 'ABI',
          description: 'Proprietor form abi field label',
        }),
        cab: intl.formatMessage({
          defaultMessage: 'CAB',
          description: 'Proprietor form cab field label',
        }),
        iban: intl.formatMessage({
          defaultMessage: 'IBAN',
          description: 'Proprietor form iban field label',
        }),
        uploadText: intl.formatMessage({
          defaultMessage: 'Select hotel images to upload',
          description: 'Proprietor form uploadText field label',
        }),
        uploadSubText: intl.formatMessage({
          defaultMessage: 'Drop files here or click',
          description: 'Proprietor form uploadSubText field label',
        }),
        showPreview: intl.formatMessage({
          defaultMessage: 'Show Preview',
          description: 'Proprietor form showPreview field label',
        }),
      },
      buttons: {
        reset: intl.formatMessage({
          defaultMessage: 'Cancel',
          description: 'Proprietor form reset button lable',
        }),
        save: intl.formatMessage({
          defaultMessage: 'Save & Close',
          description: 'Proprietor form save button lable',
        }),
      },
    },
    filters: {
      searchBar: {
        placeholder: intl.formatMessage({
          defaultMessage: 'Search Name, Email, Address...',
          description: 'Hotel Filters searchbar placeholder',
        }),
      },
      elementsFound: intl.formatMessage({
        defaultMessage: 'Elements found',
        description: 'Hotel Filters Buttons Elements found',
      }),
      drawerTitle: intl.formatMessage({
        defaultMessage: 'Filters',
        description: 'Hotel Filters Buttons Drawer Title',
      }),
      categories: {
        stars: intl.formatMessage({
          defaultMessage: 'Stars',
          description: 'Hotel Filters Drawer Categories Stars',
        }),
        superior: intl.formatMessage({
          defaultMessage: 'Superior',
          description: 'Hotel Filters Drawer Categories Superior',
        }),
      },
      status: {
        lead: intl.formatMessage({
          defaultMessage: 'Lead',
          description: 'Hotel Filters Drawer States Lead',
        }),
        generated: intl.formatMessage({
          defaultMessage: 'Generated',
          description: 'Hotel Filters Drawer States Generated',
        }),
        submitted: intl.formatMessage({
          defaultMessage: 'Submitted',
          description: 'Hotel Filters Drawer States Submitted',
        }),
        confirmed: intl.formatMessage({
          defaultMessage: 'Confirmed',
          description: 'Hotel Filters Drawer States Confirmed',
        }),
        rejected: intl.formatMessage({
          defaultMessage: 'Rejected',
          description: 'Hotel Filters Drawer States Rejected',
        }),
      },
      buttons: {
        filter: intl.formatMessage({
          defaultMessage: 'Filter',
          description: 'Hotel Filters Buttons Filter label',
        }),
        clearAll: intl.formatMessage({
          defaultMessage: 'Clear All',
          description: 'Hotel Filters Buttons ClearAll label',
        }),
        tooltip: intl.formatMessage({
          defaultMessage: 'Filter Hotels list',
          description: 'Hotel Filters Buttons Tooltip label',
        }),
      },
      fields: {
        types: intl.formatMessage({
          defaultMessage: 'Type',
          description: 'Hotel Filters Fields Types label',
        }),
        clusters: intl.formatMessage({
          defaultMessage: 'Cluster',
          description: 'Hotel Filters Fields Clusters label',
        }),
        categories: intl.formatMessage({
          defaultMessage: 'Category',
          description: 'Hotel Filters Fields Categories label',
        }),
        fulfillments: intl.formatMessage({
          defaultMessage: 'Fulfillment',
          description: 'Hotel Filters Fields Fulfillments label',
        }),
        states: intl.formatMessage({
          defaultMessage: 'Status',
          description: 'Hotel Filters Fields States label',
        }),
        city: intl.formatMessage({
          defaultMessage: 'City',
          description: 'Hotel Filters Fields City label',
        }),
      },
    },
    works: {
      title: intl.formatMessage({
        defaultMessage: 'Works',
        description: 'Works Page title',
      }),
      fields: {
        yearOfConstruction: intl.formatMessage({
          defaultMessage: 'Year of construction',
          description: 'Works Page year of construction field label',
        }),
        lastRenewalExpansion: intl.formatMessage({
          defaultMessage: 'Last renewal/expansion',
          description: 'Works Page last renewal expansion field label',
        }),
        rooms: intl.formatMessage({
          defaultMessage: 'Rooms',
          description: 'Works Page rooms checkbox label',
        }),
        meetingRooms: intl.formatMessage({
          defaultMessage: 'Meeting rooms',
          description: 'Works Page meeting rooms checkbox label',
        }),
        parkingSpaces: intl.formatMessage({
          defaultMessage: 'Parking spaces',
          description: 'Works Page parking spaces checkbox label',
        }),
        roomsNumber: intl.formatMessage({
          defaultMessage: 'Number',
          description: 'Works Page rooms number field label',
        }),
        roomsTypology: intl.formatMessage({
          defaultMessage: 'Typology',
          description: 'Works Page rooms typology field label',
        }),
        meetingRoomsNumber: intl.formatMessage({
          defaultMessage: 'Number',
          description: 'Works Page meeting rooms number field label',
        }),
        meetingRoomsCapacity: intl.formatMessage({
          defaultMessage: 'Capacity',
          description: 'Works Page meeting rooms capacity field label',
        }),
        parkingSpacesNumber: intl.formatMessage({
          defaultMessage: 'Number',
          description: 'Works Page parking spaces number field label',
        }),
        otherScheduledRenewal: intl.formatMessage({
          defaultMessage: 'Specify additional planned modernization/expansion',
          description: 'Works Page other scheduled renewal field label',
        }),
      },
      subtitles: {
        scheduledRenovationsExpansions: intl.formatMessage({
          defaultMessage: 'Scheduled Renovations Expansions',
          description: 'Works Page Scheduled renovations expansions subtitle',
        }),
        rooms: intl.formatMessage({
          defaultMessage: 'Specify the number of rooms and their typology',
          description: 'Works Page rooms checkbox description',
        }),
        meetingRooms: intl.formatMessage({
          defaultMessage:
            'Specify the number of meeting rooms and their capacity (pax)',
          description: 'Works Page meeting rooms checkbox description',
        }),
        parkingSpaces: intl.formatMessage({
          defaultMessage: 'Scheduled Renovations Expansions',
          description: 'Works Page parking spaces checkbox description',
        }),
      },
      disclaimer: intl.formatMessage({
        defaultMessage:
          'The Proprietor acknowledges and accepts - given the exceptional nature of the Olympic and Paralympic Games - to carry out, at its own expense, minor works and improvements, to ensure compliance with the standards of comfort reasonably expected by the Clients, compatibly with the Accommodation’s characteristics. For example, WI-FI network speed, implementation of appropriate technology to broadcast on TV the structure of the Games. Before the Games Period, the Accommodation will allow inspections and visits by TGC and/or the Fondazione (and/or other third party designated by Fondazione as per Clause 3 of the Contract) to take place, as per articles 25 and 43 of the Contract',
        description: 'Works Page parking spaces checkbox description',
      }),
    },
    bedAndBr: {
      title: intl.formatMessage({
        defaultMessage: 'Bed and Breakfast and Half Board',
        description: 'B&B Page title',
      }),
      sectionTitle: intl.formatMessage({
        defaultMessage: 'Room and Breakfast',
        description: 'B&B Page section title',
      }),
      sectionSubtitle: intl.formatMessage({
        defaultMessage:
          'Room and Breakfast includes overnight stay and breakfast. Specify below the type of breakfast offered by the structure included in the price of the room.',
        description: 'B&B Page section subtitle',
      }),
      fileds: {
        errors: {
          required: intl.formatMessage({
            defaultMessage: 'This field is required',
            description: 'B&B Page fields other error',
          }),
        },
        common: {
          other: intl.formatMessage({
            defaultMessage: 'Other (specify)',
            description: 'B&B Page fields other',
          }),
          coffee: intl.formatMessage({
            defaultMessage:
              'Coffee and coffee products of all kinds served at the table',
            description: 'B&B Page fields continental section check coffee',
          }),
          water: intl.formatMessage({
            defaultMessage:
              'Water, milk, juices, drinks, hot and cold infusions',
            description: 'B&B Page fields continental section check water',
          }),
        },
        continentalSection: {
          radio: intl.formatMessage({
            defaultMessage: 'Continental',
            description: 'B&B Page fields continental section radio',
          }),
          check: {
            cakes: intl.formatMessage({
              defaultMessage:
                'Selection of homemade brioches, cakes and biscuits and bread',
              description: 'B&B Page fields continental section check cakes',
            }),
            jams: intl.formatMessage({
              defaultMessage: 'Selection of spreads and jams',
              description: 'B&B Page fields continental section check jams',
            }),
            buffet: intl.formatMessage({
              defaultMessage: 'Buffet',
              description: 'B&B Page fields continental section check buffet',
            }),
          },
        },
        englishSection: {
          radio: intl.formatMessage({
            defaultMessage: 'English Breakfast',
            description: 'B&B Page fields english section radio',
          }),
          check: {
            buffet: intl.formatMessage({
              defaultMessage:
                'Large hot and cold buffet (eggs in different sizes, selection of cheeses and cold cuts, fresh fruit, yoghurt, croissants and cakes)',
              description: 'B&B Page fields english section check buffet',
            }),
            fish: intl.formatMessage({
              defaultMessage:
                'Freshly cooked fish, meat or vegetable dish of your choice on request',
              description: 'B&B Page fields english section check fish',
            }),
          },
        },
      },
    },
    accomodation: {
      title: intl.formatMessage({
        defaultMessage: 'Type of accomodation',
        description: 'Accomodation Page title',
      }),
      category: intl.formatMessage({
        defaultMessage: 'Category',
        description: 'Accomodation category title',
      }),
      other: intl.formatMessage({
        defaultMessage: 'Other (specify)',
        description: 'Accomodation other field',
      }),
    },
    banquet: {
      title: intl.formatMessage({
        defaultMessage: 'Banquet and Meeting Room',
        description: 'Banquet Page title',
      }),
      greyText: {
        title: intl.formatMessage({
          defaultMessage: 'N.B: Attach floor plan of banquet and meeting rooms',
          description: 'Banquet Page grey text title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            'Without prejudice to the maximum daily cost indicated in the Table below, the Proprietor undertakes to ensure that the provision of the rooms used for banquets, meetings and events shall be at a price that does not exceed the average of the prices normally charged by the Accommodation during the same period in the year 2024.',
          description: 'Banquet Page grey text subtitle',
        }),
      },
      subtitle: intl.formatMessage({
        defaultMessage: 'Number of banquet halls and metting room',
        description: 'Banquet Page subtitle',
      }),
      yes: intl.formatMessage({
        defaultMessage: 'Yes',
        description: 'Banquet Page yes label',
      }),
      no: intl.formatMessage({
        defaultMessage: 'No',
        description: 'Banquet Page no label',
      }),
      fields: {
        banquetLabel: intl.formatMessage({
          defaultMessage: 'Do you have banquet hall?',
          description: 'Banquet Page fields banquetLabel',
        }),
        banquetPlaceholder: intl.formatMessage({
          defaultMessage: 'Number of banquet halls',
          description: 'Banquet Page fields banquetPlaceholder',
        }),
        meetingLabel: intl.formatMessage({
          defaultMessage: 'Do you have meeting room?',
          description: 'Banquet Page fields meetingLabel',
        }),
        meetingPlaceholder: intl.formatMessage({
          defaultMessage: 'Number of meeting room ',
          description: 'Banquet Page fields meetingPlaceholder',
        }),
      },
      meetingRoomCard: {
        title: intl.formatMessage({
          defaultMessage: 'Meeting Room',
          description: 'Banquet Page meeting room card title',
        }),
        add: intl.formatMessage({
          defaultMessage: 'Add',
          description: 'Banquet Page meeting room card add',
        }),
        name: intl.formatMessage({
          defaultMessage: 'Name of meeting room',
          description: 'Banquet Page meeting room card name',
        }),
        dimensions: intl.formatMessage({
          defaultMessage: 'Dimensions (meters x height)',
          description: 'Banquet Page meeting room card dimensions',
        }),
        tables: intl.formatMessage({
          defaultMessage: 'No. Tables',
          description: 'Banquet Page meeting room card tables',
        }),
        chairs: intl.formatMessage({
          defaultMessage: 'No. of chairs for tables',
          description: 'Banquet Page meeting room card chairs',
        }),
        disabled: intl.formatMessage({
          defaultMessage: 'Disabled access',
          description: 'Banquet Page meeting room card disabled',
        }),
        light: intl.formatMessage({
          defaultMessage: 'Natural light',
          description: 'Banquet Page meeting room card light',
        }),
        cost: intl.formatMessage({
          defaultMessage: 'Cost (2026)',
          description: 'Banquet Page meeting room card cost',
        }),
        free: intl.formatMessage({
          defaultMessage: 'Free',
          description: 'Banquet Page meeting room card free',
        }),
        dayCost: intl.formatMessage({
          defaultMessage: 'Full day cost (€)',
          description: 'Banquet Page meeting room card dayCost',
        }),
        halfDayCost: intl.formatMessage({
          defaultMessage: 'Cost per half day (€)',
          description: 'Banquet Page meeting room card halfDayCost',
        }),
      },
      banquetCard: {
        title: intl.formatMessage({
          defaultMessage: 'Banquet Hall',
          description: 'Banquet Page banquet card title',
        }),
        add: intl.formatMessage({
          defaultMessage: 'Add',
          description: 'Banquet Page banquet card add',
        }),
        name: intl.formatMessage({
          defaultMessage: 'Name of Banquet hall',
          description: 'Banquet Page banquet card name',
        }),
        dimensions: intl.formatMessage({
          defaultMessage: 'Dimensions (meters x height)',
          description: 'Banquet Page banquet card dimensions',
        }),
        dayCost: intl.formatMessage({
          defaultMessage: 'Full day cost (€)',
          description: 'Banquet Page banquet card dayCost',
        }),
        halfDayCost: intl.formatMessage({
          defaultMessage: 'Cost per half day (€)',
          description: 'Banquet Page banquet card halfDayCost',
        }),
        capacity: intl.formatMessage({
          defaultMessage: 'Capacity (pax)',
          description: 'Banquet Page banquet card capacity',
        }),
        disabled: intl.formatMessage({
          defaultMessage: 'Disabled access',
          description: 'Banquet Page banquet card disabled',
        }),
        light: intl.formatMessage({
          defaultMessage: 'Natural light',
          description: 'Banquet Page banquet card light',
        }),
        coffee: intl.formatMessage({
          defaultMessage: 'Coffee break',
          description: 'Banquet Page banquet card coffee',
        }),
        buffet: intl.formatMessage({
          defaultMessage: 'Buffet',
          description: 'Banquet Page banquet card buffet',
        }),
        lunch: intl.formatMessage({
          defaultMessage: 'Lunch',
          description: 'Banquet Page banquet card lunch',
        }),
      },
    },
    equipment: {
      title: intl.formatMessage({
        defaultMessage: 'Equipment',
        description: 'Equipment Page title',
      }),
      other: intl.formatMessage({
        defaultMessage: 'Other (specify)',
        description: 'Equipment other field',
      }),
    },
    garagesParking: {
      title: intl.formatMessage({
        defaultMessage: 'Garages and Parking',
        description: 'Garages Parking Page title',
      }),
      fieldLabels: {
        hasGarage: intl.formatMessage({
          defaultMessage: 'Do you have a garage?',
          description: 'Garages Parking Has Garage radios label',
        }),
        hasOpenAirCarPark: intl.formatMessage({
          defaultMessage: 'Do you have parking space?',
          description: 'Garages Parking Has Opening Car Parks radios label',
        }),
        hasBusParking: intl.formatMessage({
          defaultMessage: 'Do you have bus parking space?',
          description: 'Garages Parking Has Bus Parking radios label',
        }),
        maxNumberOfBusParking: intl.formatMessage({
          defaultMessage: 'No. of parking spaces',
          description: 'Garages Parking max bus parking field label',
        }),
        numberOfBusParkingReserved: intl.formatMessage({
          defaultMessage: 'Assigned to XXX and/or the Fondazione',
          description: 'Garages Parking reserved bus parking field label',
        }),
        hasPublicParkingNearby: intl.formatMessage({
          defaultMessage: 'Do you have Public parking nearly?',
          description: 'Garages Parking has public parking nearby field label',
        }),
      },
      fields: {
        numberOfParkingSpaceInGarage: intl.formatMessage({
          defaultMessage: 'No. of available parking',
          description: 'Garages Parking no. available garage space field label',
        }),
        numberOfParkingSpaceInOpenAirCarPark: intl.formatMessage({
          defaultMessage: 'No. of available parking',
          description:
            'Garages Parking no. available open air space field label',
        }),
        hasDisableAccess: intl.formatMessage({
          defaultMessage: 'Disabled access to garages',
          description: 'Garages Parking has disabled access checkbox label',
        }),
        maxNumberOfBusParking: intl.formatMessage({
          defaultMessage: 'No. of bus parking spaces',
          description: 'Garages Parking has disabled access checkbox label',
        }),
        numberOfBusParkingReserved: intl.formatMessage({
          defaultMessage: 'Assigned to XXX and/or the Fondazione',
          description: 'Garages Parking has disabled access checkbox label',
        }),
        hasBusAccess: intl.formatMessage({
          defaultMessage: 'Bus access/staging',
          description: 'Garages Parking has bus access checkbox label',
        }),
        hasEletricChargeStation: intl.formatMessage({
          defaultMessage: 'Electric car charging stations',
          description:
            'Garages Parking has electric charge station checkbox label',
        }),
        isPublicParkingIndoor: intl.formatMessage({
          defaultMessage: 'indoor',
          description:
            'Garages Parking page indoor parking type checkbox label',
        }),
        isPublicParkingFree: intl.formatMessage({
          defaultMessage: 'free',
          description: 'Garages Parking page parking free  type checkbox label',
        }),
        isPublicParkingOutdoor: intl.formatMessage({
          defaultMessage: 'outdoor',
          description:
            'Garages Parking page outdoor parking type checkbox label',
        }),
        isPublicParkingWithFee: intl.formatMessage({
          defaultMessage: 'for a fee',
          description:
            'Garages Parking page with a fee parking type checkbox label',
        }),
        publicParkingDistance: intl.formatMessage({
          defaultMessage: 'Distance in Km',
          description:
            'Garages Parking page public parking distance field label',
        }),
      },
      paragraphs: {
        hasGarage: intl.formatMessage({
          defaultMessage:
            'No. of available parking spaces in garages for use by Designated Customers during the Games Period:',
          description:
            'Garages Parking Page no. available parking spaces garage text',
        }),
        hasOpenAirCarPark: intl.formatMessage({
          defaultMessage:
            'No. of available parking spaces in the open-air car park for use by Designated Customers during the Games Period:',
          description:
            'Garages Parking Page no. available parking spaces open air text',
        }),
        publicParkingNearby: intl.formatMessage({
          defaultMessage: 'If yes, please indicate whether the parking is: ',
          description: 'Garages Parking Page public parking nearby helper text',
        }),
        publicParkingDistance: intl.formatMessage({
          defaultMessage:
            'Indicate approximate distance from the accommodation and address ',
          description:
            'Garages Parking Page public parking distance helper text',
        }),
      },
    },
    agreements: {
      title: intl.formatMessage({
        defaultMessage: 'Commercial Agreements with Suppliers',
        description: 'Commercial agreements page title',
      }),
      fieldLabels: {
        category: intl.formatMessage({
          defaultMessage: 'Category',
          description: 'Commercial agreements category column label',
        }),
        company: intl.formatMessage({
          defaultMessage: 'Company',
          description: 'Commercial agreements company column label',
        }),
        isInProgress: intl.formatMessage({
          defaultMessage: 'In progress',
          description: 'Commercial agreements in progress column label',
        }),
        isPlanned: intl.formatMessage({
          defaultMessage: 'Planned',
          description: 'Commercial agreements planned column label',
        }),
      },
      fields: {
        category: intl.formatMessage({
          defaultMessage: 'Category',
          description: 'Commercial agreements category field label',
        }),
        company: intl.formatMessage({
          defaultMessage: 'Company',
          description: 'Commercial agreements company field label',
        }),
        isInProgress: intl.formatMessage({
          defaultMessage: 'In progress',
          description: 'Commercial agreements in progress checkbox label',
        }),
        isPlanned: intl.formatMessage({
          defaultMessage: 'Planned',
          description: 'Commercial agreements planned checkbox label',
        }),
      },
    },
    rooms: {
      title: intl.formatMessage({
        defaultMessage: 'Rooms and formula for guest',
        description: 'Rooms Page title',
      }),

      sections: {
        period: {
          title: intl.formatMessage({
            defaultMessage: 'Rooms and formula for guest',
            description: 'Rooms Page sections period title',
          }),
          fieldLabels: {
            addPeriods: intl.formatMessage({
              defaultMessage: 'Add additional periods',
              description: 'Rooms Page sections period add periods label',
            }),
          },
          fields: {
            extraPeriodStart: intl.formatMessage({
              defaultMessage: 'Extra period start date',
              description: 'Rooms Page sections period start date field',
            }),
            extraPeriodEnd: intl.formatMessage({
              defaultMessage: 'Extra period end date',
              description: 'Rooms Page sections period end date field',
            }),
          },
          paragraph: {
            availability: intl.formatMessage(
              {
                defaultMessage:
                  'IMPORTANT: Room availability must be guaranteed for the entire Olympic (<b>February 6, 2026 - February 22, 2026</b>) and Paralympic (<b>March 6, 2026 - March 15, 2026</b>) periods and for the 14 days prior to the event and the 2 days following the event.  The hotel will also be able to give its availability in other weeks before and after these periods.',
                description:
                  'Rooms Page sections period availability paragraph',
              },
              { ...htmlParserOptions }
            ),
          },
          buttons: {
            addPeriod: intl.formatMessage({
              defaultMessage: 'Add additional period',
              description:
                'Rooms Page sections period add additional period button',
            }),
          },
          errors: {
            overlappedPeriods: intl.formatMessage({
              defaultMessage:
                'Olympic, Paralympic and additional periods cannot overlap',
              description:
                'Rooms Page sections period overlapped periods error',
            }),
            inconsistentPeriod: intl.formatMessage({
              defaultMessage: 'Start date cannot be greater than end date',
              description:
                'Rooms Page sections period inconsistent period dates error',
            }),
          },
        },
        availability: {
          infoPanel: intl.formatMessage({
            defaultMessage:
              'Carefully fill in the table below indicating the total number of rooms you have for each type. In the next steps of the wizard you will then need to indicate how many standard rooms and how many rooms for the disabled you are making available to the Cortina Foundation during the periods indicated. For each room you can enter images that will be shown to stakeholders with the proposal.',
            description: 'Rooms Page sections availability title',
          }),
          fields: {
            guests: intl.formatMessage({
              defaultMessage: 'N° Max guests',
              description: 'Rooms Page sections availability fields guests',
            }),
            rooms: intl.formatMessage({
              defaultMessage: 'N° Rooms available for type',
              description: 'Rooms Page sections availability fields rooms',
            }),
            upload: intl.formatMessage({
              defaultMessage: 'Upload an images',
              description: 'Rooms Page sections availability fields upload',
            }),
            preview: intl.formatMessage({
              defaultMessage: 'Show Preview',
              description: 'Rooms Page sections availability fields preview',
            }),
          },
          dialogs: {
            availability: {
              body: intl.formatMessage({
                defaultMessage:
                  'You are entering a value that conflicts with the number of rooms indicated in steps 3/4. To avoid creating misalignments, first modify those values',
                description:
                  'Rooms Page sections availability availability error modal text',
              }),
              confirmButton: intl.formatMessage({
                defaultMessage: 'Go to step 3',
                description:
                  'Rooms Page sections availability availability error modal confirm button',
              }),
            },
          },
        },
        reserved: {
          disabled: {
            title: intl.formatMessage({
              defaultMessage: 'Rooms for the disabled',
              description: 'Rooms Page sections reserved disabled title',
            }),
          },
          standard: {
            title: intl.formatMessage({
              defaultMessage: 'Standard Rooms',
              description: 'Rooms Page sections reserved standard title',
            }),
          },
        },
        prices: {
          title: intl.formatMessage({
            defaultMessage: 'Standard Rooms',
            description: 'Rooms Page sections reserved standard title',
          }),
          infoPanel: intl.formatMessage({
            defaultMessage:
              'Enter the prices for each room type. The price applied applies to all rooms of that type, regardless of whether it is standard or disabled. When entering prices, please take into account the rules on minimum guaranteed prices indicated by the Cortina Foundation for your area.',
            description: 'Rooms Page sections prices greyBox',
          }),
          fields: {
            price: intl.formatMessage({
              defaultMessage: 'Price (EUR)',
              description: 'Rooms Page sections prices price field label',
            }),
            guests: intl.formatMessage({
              defaultMessage: 'Guests',
              description: 'Rooms Page sections prices guests field label',
            }),
          },
          buttons: {
            set: intl.formatMessage({
              defaultMessage: 'Set every day',
              description: 'Rooms Page sections prices buttons set every day',
            }),
            reset: intl.formatMessage({
              defaultMessage: 'Reset every day',
              description: 'Rooms Page sections prices buttons reset every day',
            }),
            save: intl.formatMessage({
              defaultMessage: 'Save',
              description: 'Rooms Page sections prices buttons save',
            }),
          },
        },
        common: {
          reserved: {
            buttons: {
              set: intl.formatMessage({
                defaultMessage: 'Set every day',
                description:
                  'Rooms Page sections reserved standard buttons set',
              }),
              save: intl.formatMessage({
                defaultMessage: 'Save',
                description:
                  'Rooms Page sections reserved standard buttons save',
              }),
            },
            infoPanel: {
              disabled: intl.formatMessage({
                defaultMessage:
                  'Before starting to fill in the table below, check that all room types indicated in the previous step are present. If you have forgotten any information, go back by clicking on Next. If all room types are present, enter the value corresponding to the number of disabled rooms you make available to the Cortina Foundation during that period. You can click on the Set all days button to replicate the value on all days. After entering the value for each room, click on Save. After entering and saving the values for all room types, click Next.',
                description: 'Rooms Page sections reserved standard greyBox',
              }),
              standard: intl.formatMessage({
                defaultMessage:
                  'Before starting to fill in the table below, check that all room types indicated in the previous step are present. If you have forgotten any information, go back by clicking on Next. If all room types are present, enter the value corresponding to the number of standard rooms you make available to the Cortina Foundation in that period. You can click on the Set every day button to replicate that value on all days. After entering the value for each room click on Save. When you have entered and saved the values for all room types click on Next.',
                description: 'Rooms Page sections reserved standard greyBox',
              }),
            },
            dialogs: {
              warning: {
                title: intl.formatMessage({
                  defaultMessage: 'Warning',
                  description:
                    'Rooms Page sections reserved standard dialogs warning title',
                }),
                text: intl.formatMessage(
                  {
                    defaultMessage:
                      'Selecting another type of room, <b>you will lose all unsaved changes</b>. Are you sure you want to proceed?',
                    description:
                      'Rooms Page sections reserved standard dialogs warning title',
                  },
                  { ...htmlParserOptions }
                ),
              },
              alert: {
                title: intl.formatMessage({
                  defaultMessage: 'Alert',
                  description:
                    'Rooms Page sections reserved/prices standard dialogs alert title',
                }),
                text: intl.formatMessage({
                  defaultMessage:
                    'The following days have incorrect data, please fix theme before proceeding:',
                  description:
                    'Rooms Page sections reserved/prices standard dialogs alert text',
                }),
              },
            },
          },
          roomTypes: {
            single: intl.formatMessage({
              defaultMessage: 'Single Room',
              description: 'Rooms Page sections common room types single',
            }),
            double: intl.formatMessage({
              defaultMessage: 'Double Room / DUS',
              description: 'Rooms Page sections common room types double',
            }),
            twin: intl.formatMessage({
              defaultMessage: 'Twin Room / DUS',
              description: 'Rooms Page sections common room types twin',
            }),
            triple: intl.formatMessage({
              defaultMessage: 'Triple Room',
              description: 'Rooms Page sections common room types triple',
            }),
            quadruple: intl.formatMessage({
              defaultMessage: 'Quadruple',
              description: 'Rooms Page sections common room types quadruple',
            }),
            suite: intl.formatMessage({
              defaultMessage: 'Suite',
              description: 'Rooms Page sections common room types suite',
            }),
          },
        },
      },
    },
    contract: {
      title: intl.formatMessage({
        defaultMessage: 'Contract preview',
        description: 'Contract preview page title',
      }),
    },
    communicationEmail: {
      title: intl.formatMessage({
        defaultMessage: 'Send Communication Email',
        description: 'Communication Email title',
      }),
      subTitle: intl.formatMessage({
        defaultMessage: 'Template of Communication Email',
        description: 'Communication Email subtitle',
      }),
      fields: {
        recepient: intl.formatMessage({
          defaultMessage: 'Recepient',
          description: 'Communication Email fields recepient',
        }),
        object: {
          label: intl.formatMessage({
            defaultMessage: 'Object',
            description: 'Communication Email fields object label',
          }),
          placeholder: intl.formatMessage({
            defaultMessage: 'Object title here',
            description: 'Communication Email fields object placeholder',
          }),
        },
        body: intl.formatMessage({
          defaultMessage: 'Write something awesome...',
          description: 'Communication Email fields body',
        }),
      },
    },
  },
});
