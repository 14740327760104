import { matchPath } from 'react-router-dom';

export function isExternalLink(path: string) {
  return path.includes('http');
}

export function getActive(path: string, pathname: string) {
  return path ? !!matchPath({ path: path, end: false }, pathname) : false;
}

export const makePath = (...pathSlices: string[]): string => {
  const path = pathSlices.reduce(
    (constructedPath, slice) => `${constructedPath}${slice}`,
    ''
  );
  return path;
};

export const interpolatePathData = (
  path: string,
  data: { [key: string]: string | number | undefined }
): string => {
  const interpolatedPath = Object.entries(data).reduce(
    (constructedPath, [key, value]) =>
      constructedPath.replace(`:${key}`, String(value)),
    path
  );
  return interpolatedPath;
};
