import { IntlShape } from 'react-intl';

export const usersTranslations = (intl: IntlShape) => ({
  UserManagementList: {
    title: intl.formatMessage({
      defaultMessage: 'General',
      description: 'General Page title',
    }),
    UserManagementList: {
      title: intl.formatMessage({
        defaultMessage: 'User Management',
        description: 'User Management Page title',
      }),
    },
    dialog: {
      create: {
        title: intl.formatMessage({
          defaultMessage: 'Add user',
          description: 'User Management dialog create title',
        }),
        error: intl.formatMessage({
          defaultMessage: 'An error occurred during the creation of the user',
          description: 'User Management dialog create error',
        }),
      },
      update: {
        title: intl.formatMessage({
          defaultMessage: 'Edit user',
          description: 'User Management dialog update title',
        }),
        error: intl.formatMessage({
          defaultMessage: 'An error occurred during the edit of the user',
          description: 'User Management dialog update error',
        }),
      },
      singleDeleteConfirmTitle: intl.formatMessage({
        defaultMessage: 'Confirm Delete of selected user',
        description: 'User Management dialog singledeleteconfirmtitle',
      }),
      singleDeleteConfirmContent: intl.formatMessage({
        defaultMessage: 'Are you sure you want to delete the selected user?',
        description: 'User Management dialog singledeleteconfirmcontent',
      }),
      multipleDeleteConfirmTitle: intl.formatMessage({
        defaultMessage: 'Confirm Delete of selected users',
        description: 'User Management dialog multipledeleteconfirmtitle',
      }),
      multipleDeleteConfirmContent: intl.formatMessage({
        defaultMessage: 'Are you sure you want to delete the selected users?',
        description: 'User Management dialog multipledeleteconfirmcontent',
      }),
      singleDeletErrorTitle: intl.formatMessage({
        defaultMessage: 'Cannot delete user',
        description: 'User Management dialog singledeletError title',
      }),
      singleDeletErrorContent: intl.formatMessage({
        defaultMessage: 'An error occurred dring the elimination of the user',
        description: 'User Management dialog singledeletError content',
      }),
      multipleDeletErrorTitle: intl.formatMessage({
        defaultMessage: 'Cannote delete users',
        description: 'User Management dialog multipledeletError title',
      }),
      multipleDeletErrorContent: intl.formatMessage({
        defaultMessage:
          'An error occurred dring the elimination of the selected users',
        description: 'User Management dialog multipledeletError title',
      }),
    },
    form: {
      name: intl.formatMessage({
        defaultMessage: 'Name',
        description: 'User Management filters name label',
      }),
      surname: intl.formatMessage({
        defaultMessage: 'Surname',
        description: 'User Management filters surname label',
      }),
      email: intl.formatMessage({
        defaultMessage: 'Email',
        description: 'User Management filters email label',
      }),
      confirmEmail: intl.formatMessage({
        defaultMessage: 'Confirm Email',
        description: 'User Management filters email label',
      }),
      role: intl.formatMessage({
        defaultMessage: 'Role',
        description: 'User Management filters role label',
      }),
      permissions: intl.formatMessage({
        defaultMessage: 'Privileges',
        description: 'User Management filters permissions label',
      }),
      allRoles: intl.formatMessage({
        defaultMessage: 'All',
        description: 'User Management filters role label all option',
      }),
      backofficeOperator: intl.formatMessage({
        defaultMessage: 'Backoffice Operator',
        description:
          'User Management filters role label backofficeOperator option',
      }),
      contactManager: intl.formatMessage({
        defaultMessage: 'Contact Manager',
        description: 'User Management filters role label contactManager option',
      }),
      loginEmail: intl.formatMessage({
        defaultMessage: 'Login Email',
        description: 'User Management filters login email label',
      }),
      loginPhone: intl.formatMessage({
        defaultMessage: 'Login Phone Number',
        description: 'User Management filters loginPhone label',
      }),
      loginUsername: intl.formatMessage({
        defaultMessage: 'Login Username',
        description: 'User Management filters loginUsername label',
      }),
      contactEmail: intl.formatMessage({
        defaultMessage: 'Contact Email',
        description: 'User Management filters contact email label',
      }),
      contactPhone: intl.formatMessage({
        defaultMessage: 'Contact Phone Number',
        description: 'User Management filters contact phone number label',
      }),
      password: intl.formatMessage({
        defaultMessage: 'Password',
        description: 'User Management filters password label',
      }),
    },
    tables: {
      columns: {
        name: intl.formatMessage({
          defaultMessage: 'Name',
          description: 'User Management Page name column',
        }),
        surname: intl.formatMessage({
          defaultMessage: 'Surname',
          description: 'User Management Page surname column',
        }),
        email: intl.formatMessage({
          defaultMessage: 'Email',
          description: 'User Management Page email column',
        }),
        role: intl.formatMessage({
          defaultMessage: 'Role',
          description: 'User Management Page role column',
        }),
        privileges: intl.formatMessage({
          defaultMessage: 'Privileges',
          description: 'User Management Page privileges column',
        }),
        loginEmail: intl.formatMessage({
          defaultMessage: 'Login Email',
          description: 'User Management Page Login email column',
        }),
        loginPhoneNumber: intl.formatMessage({
          defaultMessage: 'Login Phone number',
          description: 'User Management Page Login phone number column',
        }),
        loginUsername: intl.formatMessage({
          defaultMessage: 'Login username',
          description: 'User Management Page Login username column',
        }),
        contactEmail: intl.formatMessage({
          defaultMessage: 'Contact email',
          description: 'User Management Page Contact email column',
        }),
        contactPhoneNumber: intl.formatMessage({
          defaultMessage: 'Contact phone number',
          description: 'User Management Page Contact Phone number column',
        }),
      },
    },
    userTypes: {
      ADMIN: intl.formatMessage({
        defaultMessage: 'Admin',
        description: 'User Type Admin',
      }),
      BACKOFFICE_OPERATOR: intl.formatMessage({
        defaultMessage: 'Backoffice operator',
        description: 'User Type Backoffice operator',
      }),
      MANAGER: intl.formatMessage({
        defaultMessage: 'Contact Manager',
        description: 'User Type Manager',
      }),
      HOTEL: intl.formatMessage({
        defaultMessage: 'Hotel',
        description: 'User Type Hotel',
      }),
      STAKEHOLDER: intl.formatMessage({
        defaultMessage: 'Stakeholder',
        description: 'User Type Stakeholder',
      }),
    },
    userPermissions: {
      initials: {
        WRITE: intl.formatMessage({
          defaultMessage: 'Edit',
          description: 'Users write permission initial',
        }),
        READ: intl.formatMessage({
          defaultMessage: 'Read',
          description: 'User read permission initial',
        }),
      },
    },
  },
});
