import { useFetch } from 'use-http';

import {
  MY_AREA,
  MY_AREA_CONTACT_INFO,
  MY_AREA_LOGIN_EMAIL,
  MY_AREA_LOGIN_PHONE,
  MY_AREA_LOGIN_USERNAME,
} from 'constants/api';
import { useGlobalSelector } from 'stores';
import { IHttpHelperProps, IUpdateContactInfoProps } from 'types/httpClient';
import { IUser } from 'types/user';
import { logApiError } from 'utils/api';

const useMyArea = () => {
  const { get, put } = useFetch();
  const { showLoader, hideLoader } = useGlobalSelector();
  const fetchMyArea = async ({
    useLoader,
    setData,
  }: IHttpHelperProps<IUser> = {}): Promise<IUser | void> => {
    let data: IUser;
    try {
      useLoader && showLoader();
      data = await get(MY_AREA);
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err);
      throw err;
    } finally {
      useLoader && hideLoader();
    }
  };

  const updateContactInfo = async ({
    useLoader,
    data,
  }: IUpdateContactInfoProps<IUser>): Promise<IUser | void | boolean> => {
    try {
      useLoader && showLoader();
      const resData = await put(MY_AREA_CONTACT_INFO, data);
      return resData;
    } catch (err) {
      logApiError(err);
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };
  const updateLoginEmail = async ({
    useLoader,
    data,
  }: IUpdateContactInfoProps<IUser>): Promise<IUser | void | boolean> => {
    try {
      useLoader && showLoader();
      const resData = await put(MY_AREA_LOGIN_EMAIL, data);
      return resData;
    } catch (err) {
      logApiError(err);
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };
  const updateLoginPhone = async ({
    useLoader,
    data,
  }: IUpdateContactInfoProps<IUser>): Promise<IUser | void | boolean> => {
    try {
      useLoader && showLoader();
      const resData = await put(MY_AREA_LOGIN_PHONE, data);
      return resData;
    } catch (err) {
      logApiError(err);
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };
  const updateLoginUsername = async ({
    useLoader,
    data,
  }: IUpdateContactInfoProps<IUser>): Promise<IUser | void | boolean> => {
    try {
      useLoader && showLoader();
      const resData = await put(MY_AREA_LOGIN_USERNAME, data);
      return resData;
    } catch (err) {
      logApiError(err);
      return false;
    } finally {
      useLoader && hideLoader();
    }
  };

  return {
    fetchMyArea,
    updateContactInfo,
    updateLoginEmail,
    updateLoginPhone,
    updateLoginUsername,
  };
};

export { useMyArea };
