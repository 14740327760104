const GaragesAndParkingCardIcon = () => {
  return (
    <svg
      width="79"
      height="61"
      viewBox="0 0 79 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M32.5671 5.4082H34.6929C34.7994 5.45561 34.906 5.51487 35.0185 5.53858C39.039 6.386 41.7391 9.14753 42.4022 13.1535C42.5266 13.8943 42.5088 14.6647 42.5562 15.4884C42.8759 15.3462 43.172 15.2276 43.4562 15.0913C47.9859 12.9461 52.4978 10.7476 57.0572 8.6616C60.4263 7.12083 64.9382 7.66602 67.4725 11.5061C69.6693 14.8306 69.6574 18.4573 67.2771 21.853C66.4837 22.9848 65.5185 23.9982 64.6303 25.0767C65.2461 24.8456 65.8679 24.5849 66.5074 24.3775C72.1088 22.57 76.3543 24.9049 78.3142 29.782C79.5872 32.9406 78.5688 35.8325 76.5082 38.2681C75.0753 39.9629 73.299 41.3733 71.6114 42.8489C66.5192 47.2994 61.427 51.7617 56.2696 56.141C51.7162 60.0107 45.4576 58.1677 43.1601 54.0195C43.0476 53.818 42.8996 53.6402 42.7575 53.4328C42.4437 53.5632 42.1713 53.6699 41.9049 53.7825C37.1738 55.7677 32.4487 57.7707 27.7118 59.7381C22.1222 62.0552 15.976 58.0907 15.6859 52.0343C15.597 50.1439 16.1063 48.4431 17.2135 46.6357C15.5852 47.4179 14.2115 48.1409 12.7845 48.7453C9.741 50.0431 6.78633 49.8772 4.05666 47.9335C2.03754 46.4994 0.693433 44.5675 0.125 42.1259V39.8207C0.687512 37.7881 1.49871 35.9095 2.97901 34.3273C10.9608 25.7878 18.9188 17.2306 26.8532 8.64382C28.0078 7.39343 29.3105 6.45711 30.8915 5.88821C31.4362 5.69265 32.0106 5.56821 32.5671 5.4082Z"
        fill="#CBE7F6"
      />
      <path
        opacity="0.24"
        d="M63.1209 4.52914C62.5408 4.52914 61.9606 4.30821 61.5189 3.86664C60.6356 2.98336 60.6356 1.54602 61.5189 0.662579C62.4022 -0.22086 63.8397 -0.22086 64.723 0.662579C65.6063 1.54602 65.6063 2.98321 64.723 3.86664C64.2814 4.30821 63.7013 4.52914 63.1209 4.52914ZM63.1211 1.5618C62.9409 1.5618 62.7609 1.63023 62.6239 1.76742C62.3498 2.04149 62.3498 2.48758 62.6239 2.7618C62.898 3.03586 63.3441 3.03602 63.6183 2.7618C63.8924 2.48774 63.8924 2.04164 63.6183 1.76742C63.4811 1.63023 63.3011 1.5618 63.1211 1.5618Z"
        fill="#1C74A2"
      />
      <path
        opacity="0.48"
        d="M67.0498 12.1687C66.4696 12.1689 65.8895 11.948 65.4478 11.5062C65.0198 11.0783 64.7842 10.5094 64.7842 9.9042C64.7842 9.29905 65.0198 8.73014 65.4478 8.30217C66.3309 7.41889 67.7682 7.41873 68.6517 8.30217C69.0797 8.73014 69.3153 9.29905 69.3153 9.9042C69.3153 10.5095 69.0796 11.0784 68.6515 11.5062C68.2103 11.9478 67.63 12.1687 67.0498 12.1687ZM67.0498 9.20139C66.8698 9.20139 66.6897 9.26983 66.5526 9.40702C66.4197 9.53983 66.3467 9.71639 66.3467 9.9042C66.3467 10.092 66.4198 10.2686 66.5526 10.4014C66.8268 10.6756 67.2729 10.6756 67.547 10.4014C67.68 10.2686 67.7529 10.092 67.7529 9.9042C67.7529 9.71639 67.6798 9.53998 67.547 9.40702C67.41 9.26983 67.23 9.20139 67.0498 9.20139Z"
        fill="#1C74A2"
      />
      <path
        opacity="0.24"
        d="M15.3397 28.652C14.7595 28.652 14.1792 28.4311 13.7377 27.9895C12.8544 27.1061 12.8544 25.6687 13.7377 24.7854C14.6209 23.9023 16.0584 23.9022 16.9417 24.7854C17.825 25.6689 17.825 27.1061 16.9417 27.9895C16.5002 28.4311 15.9198 28.652 15.3397 28.652ZM15.3397 25.6847C15.1597 25.6847 14.9795 25.7531 14.8425 25.8903C14.5683 26.1644 14.5683 26.6104 14.8425 26.8847C15.1166 27.1589 15.5628 27.1587 15.8369 26.8847C16.1111 26.6106 16.1111 26.1645 15.8369 25.8903C15.6998 25.7531 15.5198 25.6847 15.3397 25.6847Z"
        fill="#1C74A2"
      />
      <path
        opacity="0.48"
        d="M14.333 35.6737C14.133 35.6737 13.9331 35.5974 13.7805 35.4449L12.8486 34.5131L11.9167 35.4449C11.6116 35.7499 11.117 35.7499 10.8119 35.4449C10.5067 35.1398 10.5067 34.6451 10.8119 34.3401L12.2962 32.8557C12.6014 32.5507 13.0959 32.5507 13.4011 32.8557L14.8855 34.3401C15.1906 34.6452 15.1906 35.1399 14.8855 35.4449C14.7328 35.5973 14.533 35.6737 14.333 35.6737Z"
        fill="#1C74A2"
      />
      <path
        opacity="0.48"
        d="M65.5654 58.2987C65.3654 58.2987 65.1655 58.2224 65.0129 58.0699L64.081 57.138L63.1491 58.0699C62.844 58.3749 62.3495 58.3749 62.0443 58.0699C61.7391 57.7648 61.7391 57.2701 62.0443 56.9651L63.5287 55.4807C63.8338 55.1757 64.3284 55.1757 64.6335 55.4807L66.1179 56.9651C66.423 57.2702 66.423 57.7649 66.1179 58.0699C65.9652 58.2223 65.7654 58.2987 65.5654 58.2987Z"
        fill="#1C74A2"
      />
      <g clipPath="url(#clip0_2057_38695)">
        <path
          d="M67.1246 31.6042C66.8961 32.0538 66.5169 32.1891 66.0258 32.1795C64.825 32.165 63.629 32.1746 62.3697 32.1746V32.6194C62.3697 37.8694 62.3697 43.1243 62.3697 48.3743C62.3697 49.2493 62.1315 49.4862 61.2418 49.4862C52.4954 49.4862 43.7538 49.4862 35.0074 49.4862C34.0983 49.4862 33.8697 49.2542 33.8697 48.3502C33.8697 43.1243 33.8697 37.8936 33.8697 32.6677V32.1746H33.4565C32.382 32.1746 31.3027 32.1746 30.2282 32.1746C30.1164 32.1746 30.0046 32.1746 29.8928 32.165C29.5281 32.1263 29.2607 31.9474 29.1586 31.5897C29.0517 31.2271 29.1927 30.9371 29.4892 30.7195C29.7955 30.4923 30.1164 30.2796 30.4324 30.0621C36.0916 26.1463 41.7556 22.2305 47.4196 18.3147C48.0225 17.899 48.2218 17.899 48.8198 18.3147C54.7172 22.3949 60.6146 26.4702 66.5023 30.5552C66.7357 30.7195 66.9156 30.9612 67.1246 31.1643V31.609V31.6042ZM40.1998 47.9054V47.4365C40.1998 43.7721 40.1998 40.1077 40.1998 36.4433C40.1998 35.578 40.4624 35.3169 41.3278 35.3169C45.8541 35.3169 50.3805 35.3169 54.9069 35.3169C55.7917 35.3169 56.0445 35.5731 56.0445 36.4675C56.0445 40.1319 56.0445 43.7963 56.0445 47.4607V47.9006H60.7945V47.4172C60.7945 42.1913 60.7945 36.9654 60.7945 31.7347C60.7945 30.8355 61.0376 30.5938 61.9322 30.5938C62.5059 30.5938 63.0796 30.5938 63.77 30.5938C63.6144 30.473 63.5512 30.4246 63.488 30.3763C58.4463 26.8859 53.3997 23.4004 48.3628 19.9052C48.1489 19.7554 48.0225 19.8182 47.8426 19.9391C42.8301 23.4101 37.8175 26.8763 32.805 30.3424C32.7224 30.4005 32.6494 30.4681 32.5522 30.5455C32.6154 30.5697 32.6397 30.5842 32.664 30.5842C33.2474 30.5842 33.826 30.5842 34.4094 30.589C35.163 30.589 35.445 30.8694 35.4498 31.6042C35.4498 31.7009 35.4498 31.8024 35.4498 31.8991C35.4498 37.0766 35.4498 42.2541 35.4498 47.4317V47.9006H40.1998V47.9054ZM54.4353 36.9267H41.8188V47.8909H54.4304V36.9267H54.4353Z"
          fill="#85C7EA"
        />
        <path
          d="M48.1053 30.5888C45.8445 30.5888 43.5789 30.5888 41.3182 30.5888C41.1723 30.5888 41.0216 30.5888 40.8757 30.5695C40.5111 30.5163 40.2583 30.2408 40.2242 29.8733C40.1902 29.5204 40.4041 29.182 40.7542 29.0854C40.9292 29.037 41.1188 29.0273 41.3036 29.0273C45.8542 29.0273 50.4 29.0273 54.9507 29.0273C55.1355 29.0273 55.3251 29.037 55.5001 29.0854C55.8501 29.1869 56.0641 29.5253 56.03 29.8733C55.996 30.2359 55.7383 30.5163 55.3737 30.5695C55.2278 30.5888 55.0771 30.5888 54.9313 30.5888C52.6559 30.5888 50.3806 30.5888 48.1101 30.5888H48.1053Z"
          fill="#85C7EA"
        />
        <path
          d="M48.1058 46.3434C46.9098 46.3434 45.7089 46.3434 44.5129 46.3434C44.4011 46.3434 44.2892 46.3434 44.1774 46.3337C43.6912 46.2806 43.3655 45.9518 43.3752 45.5313C43.3849 45.1203 43.6961 44.8206 44.1726 44.7626C44.2698 44.7529 44.3719 44.7529 44.4691 44.7529C46.9 44.7529 49.3358 44.7529 51.7667 44.7529C51.9369 44.7529 52.1119 44.7674 52.2821 44.8061C52.6273 44.8835 52.8752 45.217 52.8655 45.5603C52.8558 45.8938 52.6175 46.208 52.2869 46.2854C52.1216 46.3241 51.9466 46.3337 51.7716 46.3386C50.5513 46.3386 49.3261 46.3386 48.1058 46.3386V46.3434Z"
          fill="#85C7EA"
        />
        <path
          d="M48.1252 40.0346C46.8125 40.0346 45.5047 40.0442 44.192 40.0346C43.7107 40.0297 43.3704 39.6768 43.3801 39.2514C43.3849 38.8357 43.7107 38.5166 44.1726 38.4779C44.2844 38.4683 44.3962 38.4779 44.508 38.4779C46.7104 38.4779 48.9128 38.4779 51.1104 38.4779C51.3826 38.4779 51.6549 38.4779 51.9272 38.4779C52.5009 38.4876 52.8606 38.7873 52.8704 39.2562C52.8752 39.7203 52.5106 40.0442 51.9466 40.0442C50.6728 40.0491 49.399 40.0442 48.1252 40.0442V40.0346Z"
          fill="#85C7EA"
        />
        <path
          d="M48.1248 43.1913C46.851 43.1913 45.5772 43.1913 44.3034 43.1913C43.6422 43.1913 43.2484 42.7321 43.4234 42.1809C43.5206 41.8715 43.7346 41.683 44.0603 41.6443C44.1964 41.625 44.3326 41.625 44.4687 41.625C46.9045 41.625 49.3402 41.625 51.776 41.625C52.4858 41.625 52.8602 41.8909 52.8651 42.3985C52.8748 42.9158 52.4858 43.1961 51.7566 43.1961C50.546 43.1961 49.3305 43.1961 48.1199 43.1961L48.1248 43.1913Z"
          fill="#85C7EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_2057_38695">
          <rect
            width="38"
            height="31.4857"
            fill="white"
            transform="translate(29.125 18)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GaragesAndParkingCardIcon;
