import { IntlShape } from 'react-intl';

export const generalTranslations = (intl: IntlShape) => ({
  general: {
    title: intl.formatMessage({
      defaultMessage: 'General',
      description: 'General Page title',
    }),
    UserManagementList: {
      title: intl.formatMessage({
        defaultMessage: 'User Management',
        description: 'User Management Page title',
      }),
    },
    buttons: {
      cancel: intl.formatMessage({
        defaultMessage: 'Cancel',
        description: 'general buttons cancel',
      }),
      close: intl.formatMessage({
        defaultMessage: 'Close',
        description: 'general buttons close',
      }),
      saveAndClose: intl.formatMessage({
        defaultMessage: 'Save & Close',
        description: 'general buttons save and close',
      }),
      import: intl.formatMessage({
        defaultMessage: 'Import',
        description: 'general buttons import',
      }),
      export: intl.formatMessage({
        defaultMessage: 'Export',
        description: 'general buttons export',
      }),
      confirm: intl.formatMessage({
        defaultMessage: 'Confirm',
        description: 'general buttons confirm',
      }),
      back: intl.formatMessage({
        defaultMessage: 'Back',
        description: 'general buttons back',
      }),
      next: intl.formatMessage({
        defaultMessage: 'Next',
        description: 'general buttons next',
      }),
      ok: intl.formatMessage({
        defaultMessage: 'Ok',
        description: 'general buttons ok',
      }),
      send: intl.formatMessage({
        defaultMessage: 'Send',
        description: 'general buttons send',
      }),
      edit: intl.formatMessage({
        defaultMessage: 'Edit',
        description: 'general buttons edit',
      }),
      read: intl.formatMessage({
        defaultMessage: 'Read',
        description: 'general buttons read',
      }),
      change: intl.formatMessage({
        defaultMessage: 'Change',
        description: 'general buttons change',
      }),
    },
    fields: {
      booleanRadios: {
        yes: intl.formatMessage({
          defaultMessage: 'Yes',
          description: 'General boolean radio buttons yes label',
        }),
        no: intl.formatMessage({
          defaultMessage: 'No',
          description: 'General boolean radio buttons no label',
        }),
      },
    },
    errors: {
      generic: intl.formatMessage({
        defaultMessage: 'An error occurred, please try again later',
        description: 'general errors',
      }),
      requiredField: intl.formatMessage({
        defaultMessage: 'Required field',
        description: 'general required field error',
      }),
      mismatch: intl.formatMessage({
        defaultMessage: 'do not match',
        description: 'Generic mismatch error message',
      }),
    },
    dialogs: {
      titles: {
        alert: intl.formatMessage({
          defaultMessage: 'Alert',
          description: 'General dialogs alert title',
        }),
        warning: intl.formatMessage({
          defaultMessage: 'Warning',
          description: 'General dialogs warning title',
        }),
        email: intl.formatMessage({
          defaultMessage: 'Send Email',
          description: 'General dialogs email title',
        }),
        sendCredentialEmail: intl.formatMessage({
          defaultMessage: 'Send Credential Email',
          description: 'General dialogs credential email title',
        }),
      },
      radioOptions: {
        credential: intl.formatMessage({
          defaultMessage: 'Credential Email',
          description: 'General dialogs radio option credential email',
        }),
        communication: intl.formatMessage({
          defaultMessage: 'Communication Email',
          description: 'General dialogs radio option communication email',
        }),
      },
      body: {
        emailSendConfirm: intl.formatMessage({
          defaultMessage:
            'Send the selected user(s) the email with the credentials to log in',
          description: 'General dialogs body email send confirm',
        }),
      },
      responses: {
        emailCredentialSuccess: intl.formatMessage({
          defaultMessage: 'Successful operation',
          description: 'General dialogs responses email credential success',
        }),
        emailCredentialError: intl.formatMessage({
          defaultMessage: 'Failed operation',
          description: 'General dialogs responses email credential failed',
        }),
      },
    },
    snackbar: {
      success: intl.formatMessage({
        defaultMessage: 'Succesful Operation',
        description: 'General snackbar success message',
      }),
      dataSaved: intl.formatMessage({
        defaultMessage: 'Data succesfully saved',
        description: 'General snackbar data save message',
      }),
      failed: intl.formatMessage({
        defaultMessage: 'Failed Operation',
        description: 'General snackbar failed message',
      }),
      genericError: intl.formatMessage({
        defaultMessage: 'Something went wrong',
        description: 'General snackbar generic error message',
      }),
    },
  },
});
