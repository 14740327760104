import { Button } from '@mui/material';
import { FormButtonContainer } from './CardActions.styles';

const CardActions = ({
  cancelText,
  saveText,
  onCancel,
  onSave,
  disableSave = false,
  customMt = '80px',
}: {
  cancelText?: string;
  saveText?: string;
  onCancel?: () => void;
  onSave?: () => void;
  disableSave?: boolean;
  customMt?: string;
}) => {
  return (
    <FormButtonContainer>
      {cancelText && onCancel && (
        <Button
          type="reset"
          variant="outlined"
          color="secondary"
          size="large"
          sx={{ mt: customMt, marginRight: '10px', width: '180px' }}
          onClick={() => onCancel()}
        >
          {cancelText}
        </Button>
      )}
      {saveText && (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          onClick={() => onSave?.()}
          sx={{ marginTop: customMt, width: '200px' }}
          disabled={disableSave}
        >
          {saveText}
        </Button>
      )}
    </FormButtonContainer>
  );
};
export { CardActions };
