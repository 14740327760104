import { Button } from '@mui/material';
import { IRightTextButtonProps } from './RightTextButton.types';

export const RightTextButton = ({
  sx,
  label,
  onClick,
  startIcon,
}: IRightTextButtonProps) => {
  return (
    <Button
      variant="contained"
      startIcon={startIcon}
      sx={{
        width: '250px',
        height: '48px',
        padding: '25px',
        marginLeft: '35px',
        justifyContent: 'space-between',
        ...sx,
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};
