import { Box } from '@mui/material';
import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

import { IPageProps } from './Page.types';

const Page = forwardRef<HTMLDivElement, IPageProps>(
  ({ children, title = '', meta, ...other }, ref) => (
    <>
      <Helmet>
        <title>{`${title} | Milano Cortina 2026`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  )
);

export { Page };
