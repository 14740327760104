const ChartColumnIcon = ({ fillColor }: { fillColor?: string }) => {
  return (
    <svg
      width="58"
      height="35"
      viewBox="0 0 58 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.466797 19C0.466797 17.8954 1.36223 17 2.4668 17H3.20316C4.30773 17 5.20316 17.8954 5.20316 19L5.20316 35H0.466797L0.466797 19Z"
        fill={fillColor || '#1C74A2'}
      />
      <path
        d="M9.20312 2C9.20312 0.89543 10.0986 0 11.2031 0L11.9395 0C13.0441 0 13.9395 0.895431 13.9395 2L13.9395 35H9.20312L9.20312 2Z"
        fill={fillColor || '#1C74A2'}
      />
      <path
        d="M17.9395 15C17.9395 13.8954 18.8349 13 19.9395 13H20.6758C21.7804 13 22.6758 13.8954 22.6758 15V35H17.9395V15Z"
        fill={fillColor || '#1C74A2'}
      />
      <path
        d="M26.6758 15C26.6758 13.8954 27.5712 13 28.6758 13H29.4121C30.5167 13 31.4121 13.8954 31.4121 15V35H26.6758V15Z"
        fill={fillColor || '#1C74A2'}
      />
      <path
        d="M35.4121 6C35.4121 4.89543 36.3075 4 37.4121 4H38.1485C39.253 4 40.1485 4.89543 40.1485 6V35H35.4121V6Z"
        fill={fillColor || '#1C74A2'}
      />
      <path
        d="M44.1484 17C44.1484 15.8954 45.0439 15 46.1484 15H46.8848C47.9894 15 48.8848 15.8954 48.8848 17V35H44.1484V17Z"
        fill={fillColor || '#1C74A2'}
      />
      <path
        d="M52.8848 15C52.8848 13.8954 53.7802 13 54.8848 13H55.6211C56.7257 13 57.6211 13.8954 57.6211 15V35H52.8848V15Z"
        fill={fillColor || '#1C74A2'}
      />
    </svg>
  );
};
export default ChartColumnIcon;
