import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import * as PATHS from 'constants/paths';
import { ICustomi18n } from 'types/intl';

export const getCustomBreadcrumbs = (
  i18n: ICustomi18n
): BreadcrumbsRoute<string>[] => [
  /**
   * Return null breadcrumb for the specific 'hotels/:id' path
   * (:id is considered as a Wild Card)
   * With this logic, every path 'hotels/:something' will be not show
   * THEY MUST BE FORCED
   */
  { path: PATHS.HOTEL_ID, breadcrumb: null },
  /**
   * Force Breadcrumbs where 'hotels/:id' could be confused with the path itself
   * (e.g.: hotels/import)
   */
  {
    path: PATHS.HOTEL_IMPORT,
    breadcrumb: i18n.hotels.import.title,
  },
  {
    path: PATHS.HOTEL_IMPORT_RECAP,
    breadcrumb: i18n.hotels.importRecap.title,
  },
  {
    path: PATHS.HOTEL_COMMUNICATION_EMAIL,
    breadcrumb: i18n.hotels.communicationEmail.title,
  },
  /**
   * Force Breadcrumbs where the slug is not the title desired
   * (e.g.: hotel/bed-and-breakfast ==> 'Bed and Breakfast and Half Board')
   */
  {
    path: PATHS.HOTEL_CONFIGURATION,
    breadcrumb: i18n.hotels.hotelConfiguration.title,
  },
  {
    path: PATHS.HOTEL_CONFIGURATION_FO,
    breadcrumb: i18n.hotels.hotelConfiguration.title,
  },
  {
    path: PATHS.HOTEL_CONFIGURATION_ID,
    breadcrumb: i18n.hotels.hotelConfiguration.title,
  },
  {
    path: PATHS.HOTEL_BANDB_ID,
    breadcrumb: i18n.hotels.bedAndBr.title,
  },
  {
    path: PATHS.HOTEL_BANDB_FO,
    breadcrumb: i18n.hotels.bedAndBr.title,
  },
  {
    path: PATHS.HOTEL_GARAGES_PARKING_ID,
    breadcrumb: i18n.hotels.garagesParking.title,
  },
  {
    path: PATHS.HOTEL_GARAGES_PARKING_FO,
    breadcrumb: i18n.hotels.garagesParking.title,
  },
  {
    path: PATHS.HOTEL_HALL_ID,
    breadcrumb: i18n.hotels.banquet.title,
  },
  {
    path: PATHS.HOTEL_HALL_FO,
    breadcrumb: i18n.hotels.banquet.title,
  },
  {
    path: PATHS.HOTEL_COMMERCIAL_AGREEMENTS_ID,
    breadcrumb: i18n.hotels.agreements.title,
  },
  {
    path: PATHS.HOTEL_COMMERCIAL_AGREEMENTS_FO,
    breadcrumb: i18n.hotels.agreements.title,
  },
  {
    path: PATHS.HOTEL_ROOMS_ID,
    breadcrumb: i18n.hotels.rooms.title,
  },
  {
    path: PATHS.HOTEL_ROOMS_FO,
    breadcrumb: i18n.hotels.rooms.title,
  },
];

export const excludePaths = [PATHS.HOME];
