import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')({
  display: 'flex',
  height: '100vh',
});

export const LeftCol = styled('div')({
  flex: '0 0 50%',
});

export const RightCol = styled(Paper)({
  borderRadius: 0,
  flex: '0 0 50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ContentWrapper = styled('div')(({ theme }) => ({
  width: 'min(500px, calc(100% - 180px));',
  minHeight: '100vh',
  margin: 'auto',
  paddingBlock: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));
