import { LoadingScreen } from 'components';
import { useGlobalSelector } from 'stores';

const LoadingProvider = () => {
  const { loading } = useGlobalSelector();

  return <LoadingScreen seeThrough loading={loading} />;
};

export { LoadingProvider };
