import { Auth } from 'aws-amplify';
import { useCallback } from 'react';
import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { useGlobalStore } from './global';
import {
  AuthStoreDefaultValues,
  ChallengedUser,
  IAuthRole,
  IAuthStore,
  ICognitoData,
  IGetSessionProps,
} from 'types/stores';
import { UserPermissions, UserType } from 'types/user';

export const AUTH_STORE_STORAGE_PREFIX = 'mico26-';
export const SESSION_TOKEN_STORAGE_KEY = `${AUTH_STORE_STORAGE_PREFIX}sessionToken`;

const DEFAULT_VALUES: AuthStoreDefaultValues = {
  isAuthenticated: false,
  cognitoData: null,
  user: null,
  challengedUser: null,
  sessionToken: null,
  role: {
    name: null,
    isAdmin: false,
    isBackofficeOperator: false,
    isHotel: false,
    isManager: false,
    isStakeholder: false,
    canEdit: false,
  },
};

const resetDefaults = () => useStore.setState({ ...DEFAULT_VALUES });

// TODO: Better describe cognito types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildCognitoData = (cognitoUser: any) => {
  const { attributes } = cognitoUser;
  const builtCognitoData: ICognitoData = {
    attributes,
    group:
      cognitoUser.signInUserSession?.idToken.payload['cognito:groups']?.[0],
    hasCompleteData:
      !!attributes.preferred_username && !!attributes.phone_number,
  };

  return builtCognitoData;
};

const handleLogin = async (
  username: string,
  password: string,
  rememberUser = false
): Promise<void> => {
  try {
    useGlobalStore.setState({ loading: true });
    const cognitoUser = await Auth.signIn(username.trim(), password);

    if (cognitoUser.challengeName) {
      useStore.setState({ challengedUser: cognitoUser });
    } else {
      const cognitoData = buildCognitoData(cognitoUser);
      let sessionToken: string | null = null;
      if (!rememberUser) {
        sessionToken = cognitoUser.signInUserSession?.idToken
          .jwtToken as string;
        localStorage.setItem(SESSION_TOKEN_STORAGE_KEY, sessionToken);
      }

      useStore.setState({ cognitoData, sessionToken });
    }
  } catch (error) {
    throw error;
  } finally {
    useGlobalStore.setState({ loading: false });
  }
};

const handleLogout = async (): Promise<void> => {
  await Auth.signOut();
  localStorage.removeItem(SESSION_TOKEN_STORAGE_KEY);
  resetDefaults();
};

const getSession = async (
  { bypassCache }: IGetSessionProps = { bypassCache: false }
): Promise<void> => {
  try {
    await Auth.currentSession();
    const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache });
    const cognitoData = buildCognitoData(cognitoUser);
    const sessionToken = localStorage.getItem(SESSION_TOKEN_STORAGE_KEY);
    useStore.setState({ cognitoData, sessionToken });
  } catch (error) {
    handleLogout();
  }
};

const getRefreshedToken = async (): Promise<string | null> => {
  try {
    const cognitoUserSession = await Auth.currentSession();
    const refreshedToken = cognitoUserSession.getIdToken().getJwtToken();
    return refreshedToken;
  } catch (error) {
    return null;
  }
};

const setNewPassword = async (
  user: ChallengedUser,
  password: string
): Promise<void> => {
  try {
    useGlobalStore.setState({ loading: true });
    await Auth.completeNewPassword(user, password);
  } catch (error) {
    console.error(error);
  } finally {
    useGlobalStore.setState({ loading: false });
  }
};

const setAdditionalData = async (
  username: string,
  phone: string
): Promise<void> => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(currentUser, {
      preferred_username: username,
      phone_number: phone,
    });
  } catch (error) {
    throw error;
  }
};

const handleForgotPassword = async (username: string): Promise<void> => {
  try {
    useGlobalStore.setState({ loading: true });
    await Auth.forgotPassword(username);
  } catch (error) {
    throw error;
  } finally {
    useGlobalStore.setState({ loading: false });
  }
};

const handleChangePassword = async (
  oldPassword: string,
  newPassword: string
): Promise<void | string | boolean> => {
  try {
    useGlobalStore.setState({ loading: true });
    const user = await Auth.currentAuthenticatedUser();
    const res = await Auth.changePassword(user, oldPassword, newPassword);
    if (res) {
      return res;
    }
  } catch (error) {
    return false;
  } finally {
    useGlobalStore.setState({ loading: false });
  }
};

const handleForgotPasswordSubmit = async (
  username: string,
  code: string,
  password: string
): Promise<void> => {
  try {
    useGlobalStore.setState({ loading: true });
    await Auth.forgotPasswordSubmit(username, code, password);
  } catch (error) {
    console.error(error);
  } finally {
    useGlobalStore.setState({ loading: false });
  }
};

const handleRoles = (cognitoData: ICognitoData) => {
  const { group, attributes } = cognitoData;
  const role: IAuthRole | null = {
    name: group as keyof typeof UserType,
    isAdmin: group === UserType[UserType.ADMIN],
    isBackofficeOperator: group === UserType[UserType.BACKOFFICE_OPERATOR],
    isHotel: group === UserType[UserType.HOTEL],
    isStakeholder: group === UserType[UserType.STAKEHOLDER],
    isManager: group === UserType[UserType.MANAGER],
    canEdit:
      attributes['custom:permissions'] ===
      UserPermissions[UserPermissions.WRITE],
  };
  useStore.setState({ role });
};

const useStore = create(
  subscribeWithSelector<IAuthStore>(
    (): IAuthStore => ({
      ...DEFAULT_VALUES,
      handleLogin,
      handleLogout,
      getSession,
      getRefreshedToken,
      setNewPassword,
      setAdditionalData,
      handleForgotPassword,
      handleChangePassword,
      handleForgotPasswordSubmit,
      reset: resetDefaults,
    })
  )
);

useStore.subscribe(
  state => state.cognitoData,
  cognitoData => {
    useStore.setState({ isAuthenticated: !!cognitoData });
    cognitoData && handleRoles(cognitoData);
  }
);

const useAuthSelector = () => useStore(useCallback(state => state, []));

export { useAuthSelector, useStore as useAuthStore };
