const AssignmentWhiteIcon = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 17.3337H14V19.5003H7V17.3337ZM7 13.0003H17V15.167H7V13.0003ZM7 8.66699H17V10.8337H7V8.66699ZM19 4.33366H14.82C14.4 3.07699 13.3 2.16699 12 2.16699C10.7 2.16699 9.6 3.07699 9.18 4.33366H5C4.86 4.33366 4.73 4.34449 4.6 4.37699C4.21 4.46366 3.86 4.68033 3.59 4.97283C3.41 5.16783 3.26 5.40616 3.16 5.66616C3.06 5.91533 3 6.19699 3 6.50033V21.667C3 21.9595 3.06 22.252 3.16 22.512C3.26 22.772 3.41 22.9995 3.59 23.2053C3.86 23.4978 4.21 23.7145 4.6 23.8012C4.73 23.8228 4.86 23.8337 5 23.8337H19C20.1 23.8337 21 22.8587 21 21.667V6.50033C21 5.30866 20.1 4.33366 19 4.33366ZM12 4.06283C12.41 4.06283 12.75 4.43116 12.75 4.87533C12.75 5.31949 12.41 5.68783 12 5.68783C11.59 5.68783 11.25 5.31949 11.25 4.87533C11.25 4.43116 11.59 4.06283 12 4.06283ZM19 21.667H5V6.50033H19V21.667Z"
        fill="white"
      />
    </svg>
  );
};

export default AssignmentWhiteIcon;
