const AddIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="#637381"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8333 6.16658H7.83325V1.16659C7.83325 0.706348 7.46016 0.333252 6.99992 0.333252C6.53968 0.333252 6.16658 0.706348 6.16658 1.16659V6.16658H1.16659C0.706348 6.16658 0.333252 6.53968 0.333252 6.99992C0.333252 7.46016 0.706348 7.83325 1.16659 7.83325H6.16658V12.8333C6.16658 13.2935 6.53968 13.6666 6.99992 13.6666C7.46016 13.6666 7.83325 13.2935 7.83325 12.8333V7.83325H12.8333C13.2935 7.83325 13.6666 7.46016 13.6666 6.99992C13.6666 6.53968 13.2935 6.16658 12.8333 6.16658Z"
        fill="#637381"
      />
    </svg>
  );
};

export default AddIcon;
