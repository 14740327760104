import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Controller, useFormContext } from 'react-hook-form';
import { IRHFRadiosProps } from './Radio.types';

const RHFRadios = ({
  name,
  formGroupStyle,
  rules,
  options,
  defaultValue = '',
  direction = 'row',
}: IRHFRadiosProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <RadioGroup
          defaultValue={defaultValue}
          style={formGroupStyle}
          value={field.value}
          onChange={e => field.onChange(e.target.value)}
          row={direction === 'row' ? true : false}
        >
          {options.map((o, idx) => (
            <FormControlLabel
              value={o.value}
              control={<Radio />}
              label={o.label}
              key={idx}
            />
          ))}
        </RadioGroup>
      )}
    />
  );
};
export { RHFRadios };
