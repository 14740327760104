import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Container(theme: Theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('sm')]: {
            paddingInline: theme.spacing(6),
          },
        },
      },
    },
  };
}
