import { Typography } from '@mui/material';
import { IPaperSectionTitleProps } from './PaperSectionTitle.types';

export const PaperSectionTitle = ({ label }: IPaperSectionTitleProps) => {
  return (
    <>
      <Typography
        variant="h6"
        sx={{ padding: '25px 0px 25px 0px', marginLeft: '35px' }}
      >
        {label}
      </Typography>
    </>
  );
};
