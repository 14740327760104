const ClearAllIcon = ({ fillColor }: { fillColor?: string }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01172 9C7.46172 9 7.01172 8.55 7.01172 8C7.01172 7.45 7.46172 7 8.01172 7H20.0117C20.5617 7 21.0117 7.45 21.0117 8C21.0117 8.55 20.5617 9 20.0117 9H8.01172ZM6.01172 13H18.0117C18.5617 13 19.0117 12.55 19.0117 12C19.0117 11.45 18.5617 11 18.0117 11H6.01172C5.46172 11 5.01172 11.45 5.01172 12C5.01172 12.55 5.46172 13 6.01172 13ZM4.01172 17H16.0117C16.5617 17 17.0117 16.55 17.0117 16C17.0117 15.45 16.5617 15 16.0117 15H4.01172C3.46172 15 3.01172 15.45 3.01172 16C3.01172 16.55 3.46172 17 4.01172 17Z"
        fill={fillColor || '#212B36'}
      />
    </svg>
  );
};

export default ClearAllIcon;
