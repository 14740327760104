const EditKPIIcon = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.7" cx="22.5" cy="22.5" r="22.5" fill="white" />
      <path
        d="M27.2482 15C27.6352 15.0122 28.0043 15.1082 28.2846 15.377C28.8529 15.9209 29.427 16.4635 29.9451 17.0533C30.6525 17.8582 30.6711 18.5046 29.9702 19.3259C29.3968 19.9968 28.7418 20.5988 28.1118 21.2201C25.5396 23.7594 22.9524 26.285 20.396 28.84C19.819 29.4169 19.1848 29.7788 18.3806 29.9064C17.4891 30.0483 16.6068 30.2454 15.7203 30.4181C15.2473 30.5099 14.9233 30.1945 15.0158 29.7279C15.2222 28.6859 15.43 27.6438 15.6407 26.6032C15.726 26.1825 15.9131 25.8091 16.2155 25.5016C19.4987 22.1669 22.7825 18.8336 26.0671 15.5002C26.3903 15.1727 26.7888 15.0136 27.2482 15ZM19.4055 28.2981C22.1275 25.5819 24.8487 22.8664 27.5485 20.1723C26.7938 19.4198 26.0234 18.6515 25.2745 17.904C22.5668 20.6052 19.8448 23.3215 17.1264 26.0341C17.8804 26.7831 18.648 27.5449 19.4055 28.2981ZM28.3999 19.3567C28.6558 19.1066 28.9403 18.8601 29.1854 18.5791C29.4119 18.3197 29.4184 18.0595 29.2198 17.8553C28.6916 17.3106 28.1548 16.7738 27.6094 16.2463C27.4102 16.0535 27.1006 16.0492 26.9006 16.2356C26.6147 16.5015 26.3502 16.7903 26.0915 17.0533C26.8634 17.8238 27.6331 18.5928 28.3992 19.3575L28.3999 19.3567ZM18.2502 28.7991C17.7048 28.2537 17.183 27.7312 16.6405 27.188C16.5115 27.8431 16.3832 28.4974 16.2456 29.1969C16.9501 29.0571 17.5959 28.9296 18.2502 28.7991Z"
        fill="#1C74A2"
      />
    </svg>
  );
};

export default EditKPIIcon;
