import { IntlShape } from 'react-intl';

export const homeTranslations = (intl: IntlShape) => ({
  homeBO: {
    title: intl.formatMessage({
      defaultMessage: 'Home',
      description: 'Home Page BO title',
    }),
    general: {
      title: intl.formatMessage({
        defaultMessage: 'General',
        description: 'Home Page BO general section title',
      }),
      btnUserManager: intl.formatMessage({
        defaultMessage: 'User management',
        description: 'Home Page BO general section btn UserManager label',
      }),
    },
    hotels: {
      title: intl.formatMessage({
        defaultMessage: 'Hotel',
        description: 'Home Page BO hotel section title',
      }),
      btnHotels: intl.formatMessage({
        defaultMessage: 'Hotels',
        description: 'Home Page BO hotel section btnHoltes label',
      }),
      btnBooking: intl.formatMessage({
        defaultMessage: 'Booking',
        description: 'Home Page BO hotel section btnBooking label',
      }),
      btnPurchases: intl.formatMessage({
        defaultMessage: 'Purchases',
        description: 'Home Page BO hotel section btnPurchases label',
      }),
      btnAgreement: intl.formatMessage({
        defaultMessage: 'Agreement',
        description: 'Home Page BO hotel section btnAgreement label',
      }),
      btnCancellations: intl.formatMessage({
        defaultMessage: 'Cancellations',
        description: 'Home Page BO hotel section btnCancellations label',
      }),
      btnAgreementTemplate: intl.formatMessage({
        defaultMessage: 'Agreement Template',
        description: 'Home Page BO hotel section btnAgreementTemplate label',
      }),
    },
    stakeholder: {
      title: intl.formatMessage({
        defaultMessage: 'Stakeholder',
        description: 'Home Page BO stakeholder section title',
      }),
      btnStakeholder: intl.formatMessage({
        defaultMessage: 'Stakeholder',
        description: 'Home Page BO stakeholder section btn stakeholder label',
      }),
    },
  },
  homeFO: {
    login: {
      title: intl.formatMessage({
        defaultMessage: 'Home',
        description: 'Home Page FO title',
      }),
    },
    dashboard: {
      title: intl.formatMessage({
        defaultMessage: 'Accommodation Management System',
        description: 'Home Page FO Dashboard title',
      }),
      olympicDates: intl.formatMessage({
        defaultMessage: 'Olympic 6-22th February 2026',
        description: 'Home Page FO Dashboard subtitle olympic dates',
      }),
      paralympicDates: intl.formatMessage({
        defaultMessage: 'Paralympic 6-15th March 2026',
        description: 'Home Page FO Dashboard subtitle paralympic dates',
      }),
      welcome: intl.formatMessage({
        defaultMessage: 'welcome hotel',
        description: 'Home Page FO Dashboard welcome',
      }),
      card: {
        title: intl.formatMessage({
          defaultMessage: 'Hotel Configuration',
          description: 'Home Page FO Dashboard card title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            'Enter within each section and enter the required information.',
          description: 'Home Page FO Dashboard card subtitle',
        }),
        start: intl.formatMessage({
          defaultMessage: 'Start',
          description: 'Home Page FO Dashboard card start',
        }),
      },
    },
  },
});
