import { IntlShape } from 'react-intl';

export const authTranslations = (intl: IntlShape) => ({
  auth: {
    login: {
      title: intl.formatMessage({
        defaultMessage: 'Sign In',
        description: 'Login Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Enter your details below',
        description: 'Login Page subtitle',
      }),
      buttons: {
        resetPassword: intl.formatMessage({
          defaultMessage: 'Reset password',
          description: 'Login Page reset password button',
        }),
        signIn: intl.formatMessage({
          defaultMessage: 'Sign In',
          description: 'Login Page sign in button',
        }),
      },
      fields: {
        email: intl.formatMessage({
          defaultMessage: 'Email address',
          description: 'Login Page email address field label',
        }),
        loginKey: intl.formatMessage({
          defaultMessage: 'Username or Email or Phone Number',
          description: 'Login Page combined login key field label',
        }),
        password: intl.formatMessage({
          defaultMessage: 'Password',
          description: 'Login Page password field label',
        }),
        rememberMe: intl.formatMessage({
          defaultMessage: 'Remember me',
          description: 'Login Page remember me checkbox label',
        }),
      },
      errors: {
        email: intl.formatMessage({
          defaultMessage: 'Please enter a valid email address',
          description: 'Email field error message',
        }),
        loginKey: intl.formatMessage({
          defaultMessage:
            'You must enter at least one between Username, Email address or Phone number',
          description: 'Login Page combined login key field error message',
        }),
        password: intl.formatMessage({
          defaultMessage: 'Password is required',
          description: 'Login page password field error message',
        }),
        required: intl.formatMessage({
          defaultMessage: 'Required field',
          description: 'Generic required field error message',
        }),
      },
      snackbars: {
        errors: {
          invalidUsernameOrPassword: intl.formatMessage({
            defaultMessage: 'Invalid username or password, please try again',
            description:
              'Login page invalid username or password snackbar error',
          }),
        },
      },
    },
    enterData: {
      title: intl.formatMessage({
        defaultMessage: 'Enter your data',
        description: 'Enter Data Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'In order to access, in addition to the email, you can enter a user name or your phone number',
        description: 'Enter Data Page subtitle',
      }),
      fields: {
        username: intl.formatMessage({
          defaultMessage: 'Username',
          description: 'Enter Data Page username field label',
        }),
        phone: intl.formatMessage({
          defaultMessage: 'Phone number',
          description: 'Enter Data Page phone number field label',
        }),
      },
      buttons: {
        save: intl.formatMessage({
          defaultMessage: 'Save',
          description: 'Enter Data Page save button',
        }),
      },
      errors: {
        username: intl.formatMessage({
          defaultMessage: 'Username is required',
          description: 'Enter Data username field error message',
        }),
        phone: intl.formatMessage({
          defaultMessage: 'Phone number is required',
          description: 'Enter Data phone number field error message',
        }),
        invalidPhone: intl.formatMessage({
          defaultMessage: 'Insert a valid phone number including country code',
          description: 'Enter Data invalid phone number error message',
        }),
      },
      snackbars: {
        success: {
          dataEnteredCorrectly: intl.formatMessage({
            defaultMessage: 'Username and phone succesfully saved',
            description: 'Enter data page data succesfully entered snackbar',
          }),
        },
        errors: {
          alreadyTakenUsernameOrPhone: intl.formatMessage({
            defaultMessage: 'Username or phone number already taken',
            description: 'Enter data page already taken data error snackbar',
          }),
        },
      },
    },
    newPassword: {
      title: intl.formatMessage({
        defaultMessage: 'Enter new password',
        description: 'New Password Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Enter your new password',
        description: 'New Password Page subtitle',
      }),
      fields: {
        password: intl.formatMessage({
          defaultMessage: 'New password',
          description: 'New Password Page new password field label',
        }),
        confirmPassword: intl.formatMessage({
          defaultMessage: 'Confirm new password',
          description: 'New Password Page confirm new password field label',
        }),
      },
      buttons: {
        save: intl.formatMessage({
          defaultMessage: 'Save',
          description: 'New Password Page save button',
        }),
      },
      errors: {
        password: intl.formatMessage({
          defaultMessage: 'New password is required',
          description: 'New Password field error message',
        }),
        confirmPassword: intl.formatMessage({
          defaultMessage: 'Confirm new password is required',
          description: 'Confirm New Password field error message',
        }),
        passwordMismatch: intl.formatMessage({
          defaultMessage: 'Passwords do not match',
          description: 'New password - password mismatch error message',
        }),
      },
      snackbars: {
        success: {
          passwordChanged: intl.formatMessage({
            defaultMessage: 'Password succesfully changed, please log in again',
            description: 'New password page success snackbar',
          }),
        },
      },
    },
    resetPassword: {
      title: intl.formatMessage({
        defaultMessage: 'Reset password',
        description: 'Reset password Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'To receive your password enter your email address',
        description: 'Reset password Page subtitle',
      }),
      fields: {
        email: intl.formatMessage({
          defaultMessage: 'Insert the email',
          description: 'Reset password Page email address field label',
        }),
      },
      buttons: {
        sendNewPassword: intl.formatMessage({
          defaultMessage: 'Send new password',
          description: 'Reset passwword Page send new password button',
        }),
      },
      errors: {
        emailRequired: intl.formatMessage({
          defaultMessage: 'Email field is required',
          description:
            'Reset passwword Page email field required error message',
        }),
        emailNotValid: intl.formatMessage({
          defaultMessage: 'Please enter a valid email address',
          description: 'Reset passwword Page Invalid Email field error message',
        }),
        passwordFormatNotValid: intl.formatMessage({
          defaultMessage:
            'Password must  be at least 8 characters long and contain a number, a lowercase letter, an uppercase letter and a special character',
          description: 'Reset password Page Invalid Email format error message',
        }),
      },
      snackbars: {
        success: {
          emailSent: intl.formatMessage({
            defaultMessage: 'An email with reset info was sent to your inbox',
            description: 'Reset password page email sent snackbar message',
          }),
        },
        errors: {
          usernameNotFound: intl.formatMessage({
            defaultMessage: 'Email address not found, please try again',
            description: 'Reset password page email not found snackbar error',
          }),
        },
      },
    },
  },
});
