import { Box, Button, Stack } from '@mui/material';
import { useDropzone } from 'react-dropzone';

import BlockContent from '../BlockContent/BlockContent';
import MultiFilePreview from '../MultiFilePreview/MultiFilePreview';
import RejectionFiles from '../RejectionFiles/RejectionFiles';
import { DropZoneStyle } from './UploadMultiFile.styles';
import { UploadMultiFileProps } from 'types/file';

export default function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  helperText,
  title,
  sx,
  hideRemoveAll = false,
  isMultiple = true,
  ...other
}: UploadMultiFileProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    ...other,
    multiple: isMultiple,
  });

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContent title={title} />
      </DropZoneStyle>

      {fileRejections.length > 0 && (
        <RejectionFiles fileRejections={fileRejections} />
      )}

      <MultiFilePreview
        files={files}
        showPreview={showPreview}
        onRemove={onRemove}
      />

      {files.length > 0 && !hideRemoveAll && (
        <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
          <Button color="inherit" size="small" onClick={onRemoveAll}>
            Remove all
          </Button>
        </Stack>
      )}

      {helperText && helperText}
    </Box>
  );
}
