const ImportWhiteIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 9.49992V11.9999H1.99992V9.49992H0.333252V11.9999C0.333252 12.9166 1.08325 13.6666 1.99992 13.6666H11.9999C12.9166 13.6666 13.6666 12.9166 13.6666 11.9999V9.49992H11.9999ZM2.83325 4.49992L4.00825 5.67492L6.16658 3.52492V10.3333H7.83325V3.52492L9.99158 5.67492L11.1666 4.49992L6.99992 0.333252L2.83325 4.49992Z"
        fill="black"
      />
    </svg>
  );
};

export default ImportWhiteIcon;
