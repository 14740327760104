import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { ModalBody } from './Modal.styles';
import { ModalProps } from './Modal.types';
import useTranslations from 'hooks/useTranslations';
import { ICustomi18n } from 'types/intl';

const Modal = ({
  open,
  children,
  title,
  disableCloseOnConfirm = false,
  colorVariant,
  showCancel,
  confirmText = 'ok',
  cancelText,
  handleConfirm,
  handleClose,
}: ModalProps) => {
  const i18n: ICustomi18n = useTranslations();

  const doConfirm = () => {
    handleConfirm?.();
    if (!disableCloseOnConfirm) handleClose();
  };

  const getConfirmText = () => i18n.general.buttons[confirmText] || confirmText;

  return (
    <Dialog
      open={!!open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <ModalBody>{children}</ModalBody>

      <DialogActions>
        <Button
          variant="contained"
          color={colorVariant || 'primary'}
          onClick={doConfirm}
        >
          {getConfirmText()}
        </Button>

        {(showCancel || cancelText) && (
          <Button onClick={handleClose} variant="outlined" color="secondary">
            {cancelText || i18n.general.buttons.cancel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export { Modal };
