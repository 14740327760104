const UserSettingsWhiteIcon = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.08496 18.9587V18.2545C4.08496 17.8862 4.24496 17.5395 4.49496 17.377C6.18496 16.2828 8.11496 15.7087 10.085 15.7087C10.115 15.7087 10.135 15.7087 10.165 15.7195C10.265 14.9612 10.465 14.2353 10.755 13.5745C10.535 13.5528 10.315 13.542 10.085 13.542C7.66496 13.542 5.40496 14.2678 3.47496 15.5137C2.59496 16.077 2.08496 17.1387 2.08496 18.2545V21.1253H11.345C10.925 20.4753 10.595 19.7387 10.375 18.9587H4.08496Z"
        fill="white"
      />
      <path
        d="M10.085 12.4587C12.295 12.4587 14.085 10.5195 14.085 8.12533C14.085 5.73116 12.295 3.79199 10.085 3.79199C7.87496 3.79199 6.08496 5.73116 6.08496 8.12533C6.08496 10.5195 7.87496 12.4587 10.085 12.4587ZM10.085 5.95866C11.185 5.95866 12.085 6.93366 12.085 8.12533C12.085 9.31699 11.185 10.292 10.085 10.292C8.98496 10.292 8.08496 9.31699 8.08496 8.12533C8.08496 6.93366 8.98496 5.95866 10.085 5.95866Z"
        fill="white"
      />
      <path
        d="M20.835 16.792C20.835 16.5537 20.805 16.337 20.775 16.1095L21.915 15.0153L20.915 13.1412L19.465 13.672C19.145 13.3795 18.785 13.152 18.385 12.9895L18.085 11.3753H16.085L15.785 12.9895C15.385 13.152 15.025 13.3795 14.705 13.672L13.255 13.1412L12.255 15.0153L13.395 16.1095C13.365 16.337 13.335 16.5537 13.335 16.792C13.335 17.0303 13.365 17.247 13.395 17.4745L12.255 18.5687L13.255 20.4428L14.705 19.912C15.025 20.2045 15.385 20.432 15.785 20.5945L16.085 22.2087H18.085L18.385 20.5945C18.785 20.432 19.145 20.2045 19.465 19.912L20.915 20.4428L21.915 18.5687L20.775 17.4745C20.805 17.247 20.835 17.0303 20.835 16.792ZM17.085 18.9587C15.985 18.9587 15.085 17.9837 15.085 16.792C15.085 15.6003 15.985 14.6253 17.085 14.6253C18.185 14.6253 19.085 15.6003 19.085 16.792C19.085 17.9837 18.185 18.9587 17.085 18.9587Z"
        fill="white"
      />
    </svg>
  );
};

export default UserSettingsWhiteIcon;
