import { IntlShape } from 'react-intl';

export const contractTranslations = (intl: IntlShape) => ({
  contract: {
    buttons: {
      download: intl.formatMessage({
        defaultMessage: 'Download',
        description: 'Contract preview page download button',
      }),
      generateAndClose: intl.formatMessage({
        defaultMessage: 'Generate & Close',
        description: 'Contract preview page generate & close button',
      }),
      source: intl.formatMessage({
        defaultMessage: 'Source File',
        description: 'Contract hotel configuration page source file button',
      }),
      upload: intl.formatMessage({
        defaultMessage: 'Upload File',
        description: 'Contract hotel configuration page upload file button',
      }),
      submit: intl.formatMessage({
        defaultMessage: 'Submit',
        description: 'Contract hotel configuration page submit button',
      }),
      confirm: intl.formatMessage({
        defaultMessage: 'Confirm',
        description: 'Contract hotel configuration page confirm button',
      }),
      cancel: intl.formatMessage({
        defaultMessage: 'Cancel',
        description: 'Contract hotel configuration page cancel button',
      }),
      reject: intl.formatMessage({
        defaultMessage: 'Reset Contract',
        description: 'Contract hotel configuration page reject button',
      }),
      addenda: intl.formatMessage({
        defaultMessage: 'Add Addenda',
        description: 'Contract hotel configuration page add addenda button',
      }),
    },
    dialogs: {
      notUploaded: {
        title: intl.formatMessage({
          defaultMessage: 'Alert',
          description:
            'Contract hotel configuration page dialog notUploaded title',
        }),
        body: intl.formatMessage({
          defaultMessage:
            'In order to generate a new contract you must first update at least one card',
          description:
            'Contract hotel configuration page dialog notUploaded body',
        }),
      },
      notCompleted: {
        title: intl.formatMessage({
          defaultMessage: 'Alert',
          description:
            'Contract hotel configuration page dialog notCompleted title',
        }),
        body: intl.formatMessage({
          defaultMessage:
            'In order to generate a contract you must first complete all the cards',
          description:
            'Contract hotel configuration page dialog notCompleted body',
        }),
      },
      confirmContract: {
        title: intl.formatMessage({
          defaultMessage: 'Confirmation Alert',
          description:
            'Contract hotel configuration page dialog confirm contract title',
        }),
        body: intl.formatMessage({
          defaultMessage:
            'Do you confirm to proceed with the confirmation of the signed agreement?',
          description:
            'Contract hotel configuration page dialog confirm contract body',
        }),
      },
      confirmAddenda: {
        title: intl.formatMessage({
          defaultMessage: 'Confirmation Alert',
          description:
            'Contract hotel configuration page dialog confirm addenda title',
        }),
        body: intl.formatMessage({
          defaultMessage:
            'Do you confirm to proceed with the confirmation of the uploaded addenda?',
          description:
            'Contract hotel configuration page dialog confirm addenda body',
        }),
      },
      confirmReject: {
        title: intl.formatMessage({
          defaultMessage: 'Confirmation Alert',
          description:
            'Contract hotel configuration page dialog confirm reject title',
        }),
        body: intl.formatMessage({
          defaultMessage:
            'Do you confirm to proceed with the rejection of the agreement?',
          description:
            'Contract hotel configuration page dialog confirm reject body',
        }),
      },
      confirmGenerate: {
        title: intl.formatMessage({
          defaultMessage: 'Confirmation Alert',
          description:
            'Contract hotel configuration page dialog confirm Generate title',
        }),
        body: intl.formatMessage({
          defaultMessage:
            'Read the generated contract carefully. At the generation you will not be able to modify your data. Remember you can always find the generated version in the box below.',
          description:
            'Contract hotel configuration page dialog confirm Generate body',
        }),
      },
      confirmSubmission: {
        title: intl.formatMessage({
          defaultMessage: 'Submission Alert',
          description:
            'Contract hotel configuration page dialog confirm submission title',
        }),
        body: intl.formatMessage({
          defaultMessage:
            'Do you confirm to proceed submission of the the signed agreement?',
          description:
            'Contract hotel configuration page dialog confirm submission body',
        }),
      },
    },
    uploadTextes: {
      signed: intl.formatMessage({
        defaultMessage: 'Upload the signed version of contract',
        description:
          'Contract hotel configuration page upload text signed version',
      }),
      addenda: intl.formatMessage({
        defaultMessage: 'Upload Addenda',
        description: 'Contract hotel configuration page upload addenda ',
      }),
    },
    officialAgreements: {
      id: intl.formatMessage({
        defaultMessage: 'Id',
        description: 'Contract hotel configuration page official Agreements id',
      }),
      status: intl.formatMessage({
        defaultMessage: 'Agreement status',
        description:
          'Contract hotel configuration page official Agreements status',
      }),
    },
    loadingText: intl.formatMessage({
      defaultMessage: 'Loading contract preview...',
      description: 'Contract preview page loading Text',
    }),
    generatingText: intl.formatMessage({
      defaultMessage: 'Generating contract...',
      description: 'Contract preview page generating Text',
    }),
    cover: intl.formatMessage({
      defaultMessage: 'Accomodation Terms and Conditions',
      description: 'Contract cover page title',
    }),
    tableOfContents: {
      title: intl.formatMessage({
        defaultMessage: 'Table of contents',
        description: 'Contract table of content page title',
      }),
      sections: {
        PROPRIETOR: intl.formatMessage({
          defaultMessage: 'Proprietor',
          description: 'Contract table of content section proprietor',
        }),
        ACCOMODATION_TYPE: intl.formatMessage({
          defaultMessage: 'Type of Accomodation and Category',
          description: 'Contract table of content section accomodation',
        }),
        WORK: intl.formatMessage({
          defaultMessage: 'Works',
          description: 'Contract table of content section works',
        }),
        BREAKFAST: intl.formatMessage({
          defaultMessage: 'Bed and Breakfast and Half Board formulas',
          description: 'Contract table of content section bed and breakfast',
        }),
        ROOM: intl.formatMessage({
          defaultMessage:
            'Rooms and formula for guests - MAXIMUM GUARANTEED PRICE',
          description: 'Contract table of content section rooms',
        }),
        COMMERCIAL_AGREEMENT: intl.formatMessage({
          defaultMessage: 'Commercial Agreements with Suppliers',
          description:
            'Contract table of content section commercial agreements',
        }),
        BANQUET_AND_MEETINGROOM: intl.formatMessage({
          defaultMessage: 'Banquet and Meeting Rooms',
          description: 'Contract table of content section banquets',
        }),
        EQUIPMENT: intl.formatMessage({
          defaultMessage: 'Equipment',
          description: 'Contract table of content section equipment',
        }),
        GARAGE_AND_PARKING: intl.formatMessage({
          defaultMessage: 'Garages and Parking',
          description: 'Contract table of content section garages',
        }),
      },
    },
    proprietor: {
      introTextOne: intl.formatMessage({
        defaultMessage:
          'The Proprietor acknowledges that the information on services contained in this Annex is up-to-date and accurate. Please identify in Clause 1, the Contact Person for the Contract, who, on behalf of the Proprietor, is designated as responsible for the Contract.',
        description: 'Contract proprietor intro text one',
      }),
      introTextTwo: intl.formatMessage({
        defaultMessage:
          'The information requested in the following clauses must be submitted online, by connecting to the website XXXX.XX and following the guided instructions for completion. Once you have finished entering your details, the form will automatically be produced and you can print it, sign it (together with the Contract) and send the signed copy of the Contract and this Annex to TGC, as the Party designated by the Foundation to manage the contract. A Distribution Commission of € ______ excluding VAT shall be applied by TGC to the Proprietor, as per Clause 7.2 of the Contract. The Proprietor acknowledges and accepts the application of such Distribution Commission signing this Annex B.',
        description: 'Contract proprietor intro text two',
      }),
    },
    accomodation_type: {
      type: intl.formatMessage({
        defaultMessage: 'Type of accomodation',
        description: 'Contract accomodation type',
      }),
      category: intl.formatMessage({
        defaultMessage: 'Category',
        description: 'Contract accomodation type category',
      }),
    },
    works: {
      yearOfConstruction: intl.formatMessage({
        defaultMessage: 'Year of construction',
        description: 'Contract works year of construction',
      }),
      lastRenewal: intl.formatMessage({
        defaultMessage: 'Last renewal/expansion',
        description: 'Contract works last renewal / expansion',
      }),
      scheduledRenovations: intl.formatMessage({
        defaultMessage: 'Scheduled Renovations/Expansion: ',
        description: 'Contract works schedule renovations',
      }),
      rooms: intl.formatMessage({
        defaultMessage: 'Rooms',
        description: 'Contract works rooms',
      }),
      meetingRooms: intl.formatMessage({
        defaultMessage: 'Meeting rooms',
        description: 'Contract works meeting rooms',
      }),
      parkingSpaces: intl.formatMessage({
        defaultMessage: 'Parking spaces',
        description: 'Contract works parking spaces',
      }),
      number: intl.formatMessage({
        defaultMessage: 'Number',
        description: 'Contract works number',
      }),
      type: intl.formatMessage({
        defaultMessage: 'Type',
        description: 'Contract works type',
      }),
      capacity: intl.formatMessage({
        defaultMessage: 'Capacity',
        description: 'Contract works capacity',
      }),
      furtherUgrapdes: intl.formatMessage({
        defaultMessage: 'Further upgrades/expansions planned',
        description: 'Contract works further upgrades / expansions planned',
      }),
      outroTextOne: intl.formatMessage({
        defaultMessage:
          'The Proprietor acknowledges and accepts - given the exceptional nature of the Olympic and Paralympic Games - to carry out, at its own expense, minor works and improvements, to ensure compliance with the standards of comfort reasonably expected by the Clients, compatibly with the Accommodation’s characteristics. For example, WI-FI network speed, implementation of appropriate technology to broadcast on TV the structure of the Games.',
        description: 'Contract works intro text one',
      }),
      outroTextTwo: intl.formatMessage({
        defaultMessage:
          'Before the Games Period, the Accommodation will allow inspections and visits by TGC and/or theFondazione (and/or other third party designated by Fondazione as per Clause 3 of the Contract) to take place, as per articles 25 and 43 of the Contract. ',
        description: 'Contract works intro text two',
      }),
    },
    breakfast: {
      introTextOne: intl.formatMessage({
        defaultMessage:
          'Room and Breakfast includes overnight stay and breakfast. Specify below the type of breakfast offered by the structure included in the price of the room.',
        description: 'Contract breakfast intro text one',
      }),
      continental: intl.formatMessage({
        defaultMessage: 'Continental breakfast',
        description: 'Contract continental breakfast',
      }),
      english: intl.formatMessage({
        defaultMessage: 'English breakfast',
        description: 'Contract breakfast english breakfast',
      }),
    },
    commercial: {
      columns: {
        category: intl.formatMessage({
          defaultMessage: 'Category',
          description: 'Contract commercial agreements category column',
        }),
        company: intl.formatMessage({
          defaultMessage: 'Company',
          description: 'Contract commercial agreements cpmany column',
        }),
        inProgress: intl.formatMessage({
          defaultMessage: 'In progress',
          description: 'Contract commercial agreements in progress column',
        }),
        planned: intl.formatMessage({
          defaultMessage: 'Category',
          description: 'Contract commercial agreements planned column',
        }),
      },
    },
    banquet: {
      introTextOne: intl.formatMessage({
        defaultMessage: 'N.B: ttach floor plan of banquet and meeting rooms',
        description: 'Contract banquet intro text one',
      }),
      introTextTwo: intl.formatMessage({
        defaultMessage:
          'Without prejudice to the maximum daily cost indicated in the Table below, the Proprietor undertakes to ensure that the provision of the rooms used for banquets, meetings and events shall be at a price that does not exceed the average of the prices normally charged by the Accommodation during the same period in the year 2024.',
        description: 'Contract banquet intro text two',
      }),
      banquetTitle: intl.formatMessage({
        defaultMessage: 'Banquet Rooms',
        description: 'Contract banquet banquet rooms table title',
      }),
      meetingTitle: intl.formatMessage({
        defaultMessage: 'Meeting Rooms',
        description: 'Contract banquet meeting rooms table title',
      }),
      columns: {
        banquet: {
          name: intl.formatMessage({
            defaultMessage: 'Name',
            description: 'Contract banquet name column',
          }),
          rooms: intl.formatMessage({
            defaultMessage: 'Rooms (SQM and height)',
            description: 'Contract banquet rooms column',
          }),
          naturalLight: intl.formatMessage({
            defaultMessage: 'Natural Light',
            description: 'Contract banquet natural light column',
          }),
          accessibleAccess: intl.formatMessage({
            defaultMessage: 'Accessible access',
            description: 'Contract banquet accessible access column',
          }),
          coffeeBreak: intl.formatMessage({
            defaultMessage: 'Coffee break',
            description: 'Contract banquet coffee break column',
          }),
          lunch: intl.formatMessage({
            defaultMessage: 'Lunch (seated)',
            description: 'Contract banquet lunch column',
          }),
          buffet: intl.formatMessage({
            defaultMessage: 'Buffet',
            description: 'Contract banquet buffet column',
          }),
          capacity: intl.formatMessage({
            defaultMessage: 'Capacity (pax)',
            description: 'Contract banquet capacity column',
          }),
          dayCost: intl.formatMessage({
            defaultMessage: 'Day Cost (2026)',
            description: 'Contract banquet day cost column',
          }),
          halfDayCost: intl.formatMessage({
            defaultMessage: 'Half day Cost (2026)',
            description: 'Contract banquet half day cost column',
          }),
        },
        meeting: {
          name: intl.formatMessage({
            defaultMessage: 'Name',
            description: 'Contract meeting rooms name column',
          }),
          rooms: intl.formatMessage({
            defaultMessage: 'Rooms (SQM and height)',
            description: 'Contract meeting rooms rooms column',
          }),
          naturalLight: intl.formatMessage({
            defaultMessage: 'Natural Light',
            description: 'Contract meeting rooms natural light column',
          }),
          accessibleAccess: intl.formatMessage({
            defaultMessage: 'Accessible access',
            description: 'Contract meeting rooms accessible access column',
          }),
          chairs: intl.formatMessage({
            defaultMessage: 'Tables (no. of chair)',
            description: 'Contract meeting rooms chairs column',
          }),
          reserved: intl.formatMessage({
            defaultMessage: 'Reserved for MICO2026',
            description: 'Contract meeting rooms reserved chairs column',
          }),
          freeOfCharge: intl.formatMessage({
            defaultMessage: 'Free of Charge',
            description: 'Contract meeting rooms capacity column',
          }),
          dayCost: intl.formatMessage({
            defaultMessage: 'Day Cost (2026)',
            description: 'Contract meeting rooms day cost column',
          }),
          halfDayCost: intl.formatMessage({
            defaultMessage: 'Half day Cost (2026)',
            description: 'Contract meeting rooms half day cost column',
          }),
        },
      },
    },
    garages: {
      introTextOne: intl.formatMessage({
        defaultMessage:
          'During the period of the Games, XXX and the Fondazione shall require a percentage of parking spaces in proportion to the number of rooms booked by the Foundation, consistent with the parking spaces available at the Accommodation. Before the Games Period, the Accommodation will allow inspections and visits by XXX and/or the Fondazione to take place.',
        description: 'Contract garages intro text one',
      }),
      introTextTwo: intl.formatMessage({
        defaultMessage:
          'As set out in the Contract, the proprietor undertakes to ensure that the parking spaces in the relevant Accommodation Facilities shall be provided free of charge or if at a price, the price  does not exceed the average of the prices normally charged by the Accommodation during the same period in the year 2024.',
        description: 'Contract garages intro text two',
      }),
      numberGaragesParkingSpaces: intl.formatMessage({
        defaultMessage:
          'No. of available parking spaces in garages for use by Designated Customers during the Games Period:',
        description: 'Contract garages number of garages parking spaces',
      }),
      numberOpenAirParkingSpaces: intl.formatMessage({
        defaultMessage:
          'No. of available parking spaces in the open-air car park for use by Designated Customers during the Games Period:',
        description: 'Contract garages number of open air parking spaces',
      }),
      busAccess: intl.formatMessage({
        defaultMessage: 'Bus access/staging',
        description: 'Contract garages bus access staging',
      }),
      electricChargingStation: intl.formatMessage({
        defaultMessage: 'Electric car charging stations',
        description: 'Contract garages electric car charging station',
      }),
      disabledAccess: intl.formatMessage({
        defaultMessage: 'Disabled access to garages',
        description: 'Contract garages disabled access',
      }),
      busParking: intl.formatMessage({
        defaultMessage: 'Bus parking',
        description: 'Contract garages bus parking',
      }),
      numberBusParkingspaces: intl.formatMessage({
        defaultMessage: 'No. of bus parking spaces',
        description: 'Contract garages number of bus parking spaces',
      }),
      numberBusParkingAssignedFondazione: intl.formatMessage({
        defaultMessage: 'Assigned to XXX and/or the Fondazione:',
        description:
          'Contract garages number of bus parking spaces assigned to Fondazione',
      }),
      publicParkingNearby: intl.formatMessage({
        defaultMessage: 'Do you have public parking nearby?',
        description: 'Contract garages public parking nearby',
      }),
      publicParkingType: intl.formatMessage({
        defaultMessage: 'Public parking type',
        description: 'Contract garages public parking type',
      }),
      indoor: intl.formatMessage({
        defaultMessage: 'indoor',
        description: 'Contract garages public parking type',
      }),
      free: intl.formatMessage({
        defaultMessage: 'free',
        description: 'Contract garages public parking type',
      }),
      forAFee: intl.formatMessage({
        defaultMessage: 'for a fee',
        description: 'Contract garages public parking type',
      }),
      outdoor: intl.formatMessage({
        defaultMessage: 'outdoor',
        description: 'Contract garages public parking type',
      }),
      publicParkingDistance: intl.formatMessage({
        defaultMessage:
          'Public parking approximate distance from the accommodation and address',
        description: 'Contract garages public parking distance',
      }),
    },
    rooms: {
      columns: {
        standard: intl.formatMessage({
          defaultMessage: 'Standard',
          description: 'Contract rooms standard column',
        }),
        disabled: intl.formatMessage({
          defaultMessage: 'Disabled',
          description: 'Contract rooms disabled column',
        }),
        price: intl.formatMessage({
          defaultMessage: '€/Guest',
          description: 'Contract rooms price column',
        }),
      },
    },
    closing: {
      outroText: intl.formatMessage({
        defaultMessage:
          'Proprietor Signature, as a sign of accepting all the above provisions, with particular regard also to the economic conditions and to the application of the Distribution Commission as indicated above.',
        description: 'Contract closing page text',
      }),
    },
  },
});
