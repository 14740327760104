// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

import { ICustomBoxProps } from './Iconify.types';

const Iconify = ({ icon, sx, ...other }: ICustomBoxProps) => {
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
};

export { Iconify };
