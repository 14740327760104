import { IntlShape } from 'react-intl';

export const myAreaTranslations = (intl: IntlShape) => ({
  myarea: {
    page: {
      title: intl.formatMessage({
        defaultMessage: 'My Area',
        description: 'My Area Page title',
      }),
      contactInfo: intl.formatMessage({
        defaultMessage: 'Contact Info',
        description: 'My Area contact info title',
      }),
      loginMode: intl.formatMessage({
        defaultMessage: 'Login Mode',
        description: 'My Area login mode title',
      }),
    },
    form: {
      emailSuccess: intl.formatMessage({
        defaultMessage:
          'Thank you! You will receive an email to the new address. Click on the link for confirmation.',
        description: 'My Area form  email update success',
      }),
      oldEmail: intl.formatMessage({
        defaultMessage: 'Old email',
        description: 'My Area form old email label',
      }),
      newEmail: intl.formatMessage({
        defaultMessage: 'New email',
        description: 'My Area form new email label',
      }),
      confirmEmail: intl.formatMessage({
        defaultMessage: 'Confirm email',
        description: 'My Area form confirm email label',
      }),
      currentPassword: intl.formatMessage({
        defaultMessage: 'Current password',
        description: 'My Area form old password label',
      }),
      newPassword: intl.formatMessage({
        defaultMessage: 'New password',
        description: 'My Area form new password label',
      }),
      confirmPassword: intl.formatMessage({
        defaultMessage: 'Confirm password',
        description: 'My Area form confirm password label',
      }),
    },
  },
});
