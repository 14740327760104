import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import { useCallback, useEffect, useState } from 'react';
import { IntlShape, MessageDescriptor, useIntl } from 'react-intl';

export default function useCustomIntl(): IntlShape {
  const intl = useIntl();
  const [isTranslation, setIsTranslation] = useState<boolean | null>(null);

  const listener = function (event: KeyboardEvent) {
    if (
      process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' &&
      event.ctrlKey &&
      event.altKey &&
      event.key === '1'
    ) {
      setIsTranslation(value => !value);
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', listener);

    return () => document.removeEventListener('keyup', listener);
  });

  const customFormatMessage = useCallback(
    (
      descriptor: MessageDescriptor,
      parserOptions:
        | Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
        | undefined
    ) => {
      const output = intl.formatMessage(descriptor, parserOptions);
      // TODO: Handle output in case of html content (React component)
      if (!isTranslation) return output;
      else return `${output} <${descriptor.id}>`;
    },
    [intl, isTranslation]
  );

  return {
    ...intl,
    formatMessage: customFormatMessage,
  };
}
