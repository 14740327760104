import { makePath } from 'utils/navigation';

// ROOTs
export const ROOT = '/';
export const AUTH_ROOT = makePath(ROOT, 'auth');
export const HOME = makePath(ROOT, 'home');
export const HOTEL_ROOT = makePath(ROOT, 'hotels');
export const USERMANAGMENT_ROOT = makePath(ROOT, 'user-Management');
export const STAKEHOLDER_ROOT = makePath(ROOT, 'stakeholder');
export const MY_AREA_ROOT = makePath(ROOT, 'my-area');

// AUTH Routes
export const LOGIN = makePath(AUTH_ROOT, '/login');
export const ENTER_DATA = makePath(AUTH_ROOT, '/enter-data');
export const NEW_PASSWORD = makePath(AUTH_ROOT, '/new-password');
export const RESET_PASSWORD = makePath(AUTH_ROOT, '/reset-password');
export const PERMISSION_DENIED = makePath(AUTH_ROOT, '/permission-denied');

// HOTEL Routes
export const HOTEL_ID = makePath(HOTEL_ROOT, '/:id');
export const HOTEL_CONFIGURATION = makePath(HOTEL_ROOT, '/configuration');
export const HOTEL_PROPRIETOR = makePath(HOTEL_CONFIGURATION, '/proprietor');
export const HOTEL_CONFIGURATION_ID = makePath(HOTEL_ID, '/configuration');
export const HOTEL_PROPRIETOR_ID = makePath(
  HOTEL_CONFIGURATION_ID,
  '/proprietor'
);
export const HOTEL_IMPORT = makePath(HOTEL_ROOT, '/import');
export const HOTEL_COMMUNICATION_EMAIL = makePath(HOTEL_ROOT, '/email');
export const HOTEL_IMPORT_RECAP = makePath(HOTEL_ROOT, '/import-recap');
export const HOTEL_WORKS_ID = makePath(HOTEL_CONFIGURATION_ID, '/works');
export const HOTEL_BANDB_ID = makePath(
  HOTEL_CONFIGURATION_ID,
  '/bed-and-breakfast'
);
export const HOTEL_ACCOMODATION_ID = makePath(
  HOTEL_CONFIGURATION_ID,
  '/type-of-accomodation'
);
export const HOTEL_EQUIPMENT_ID = makePath(
  HOTEL_CONFIGURATION_ID,
  '/equipment'
);
export const HOTEL_GARAGES_PARKING_ID = makePath(
  HOTEL_CONFIGURATION_ID,
  '/garages-parking'
);
export const HOTEL_HALL_ID = makePath(
  HOTEL_CONFIGURATION_ID,
  '/banquet-meeting-room'
);
export const HOTEL_COMMERCIAL_AGREEMENTS_ID = makePath(
  HOTEL_CONFIGURATION_ID,
  '/commercial-agreements'
);
export const HOTEL_ROOMS_ID = makePath(HOTEL_CONFIGURATION_ID, '/rooms');
export const HOTEL_CONTRACT_ID = makePath(
  HOTEL_CONFIGURATION_ID,
  '/contract-preview'
);
// FRONTOFFICE HOTEL
export const HOTEL_CONFIGURATION_FO = makePath(ROOT, 'configuration');
export const HOTEL_PROPRIETOR_FO = makePath(
  HOTEL_CONFIGURATION_FO,
  '/proprietor'
);
export const HOTEL_ACCOMODATION_FO = makePath(
  HOTEL_CONFIGURATION_FO,
  '/type-of-accomodation'
);
export const HOTEL_WORKS_FO = makePath(HOTEL_CONFIGURATION_FO, '/works');
export const HOTEL_BANDB_FO = makePath(
  HOTEL_CONFIGURATION_FO,
  '/bed-and-breakfast'
);
export const HOTEL_EQUIPMENT_FO = makePath(
  HOTEL_CONFIGURATION_FO,
  '/equipment'
);
export const HOTEL_GARAGES_PARKING_FO = makePath(
  HOTEL_CONFIGURATION_FO,
  '/garages-parking'
);
export const HOTEL_HALL_FO = makePath(
  HOTEL_CONFIGURATION_FO,
  '/banquet-meeting-room'
);
export const HOTEL_COMMERCIAL_AGREEMENTS_FO = makePath(
  HOTEL_CONFIGURATION_FO,
  '/commercial-agreements'
);
export const HOTEL_ROOMS_FO = makePath(HOTEL_CONFIGURATION_FO, '/rooms');
export const HOTEL_CONTRACT_FO = makePath(
  HOTEL_CONFIGURATION_FO,
  '/contract-preview'
);
