import { useMemo } from 'react';
import {
  CachePolicies,
  Provider as FetchProvider,
  IncomingOptions,
} from 'use-http';

import { useAuthSelector } from 'stores';
import { IHttpProviderProps, IInterceptorReq } from 'types/httpClient';

const HttpProvider = ({
  children,
  baseUrl,
  customOptions,
}: IHttpProviderProps) => {
  const { sessionToken, getRefreshedToken, handleLogout } = useAuthSelector();

  const globalOptions: IncomingOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
    onError: ({ error }) => {
      throw error;
    },
    interceptors: {
      request: async ({ options }: IInterceptorReq): Promise<RequestInit> => {
        let currentToken: string | null = null;
        if (sessionToken) currentToken = sessionToken;
        else currentToken = await getRefreshedToken();

        options.headers = [['Content-Type', 'application/json']];

        if (currentToken)
          options.headers = [
            ...options.headers,
            ['Authorization', `Bearer ${currentToken}`],
          ];

        return options;
      },
      response: async ({ response }) => {
        if (!response.ok) {
          const statusCode = response.status;
          if (statusCode === 401) handleLogout();

          const errorData = response.data;
          const customError = {
            statusCode,
            message: errorData.message || statusCode.toString(),
            ...(errorData.errorType && { errorType: errorData.errorType }),
          };

          throw customError;
        }
        return response;
      },
    },
  };

  const options: IncomingOptions = useMemo(
    () => ({
      ...globalOptions,
      ...customOptions,
    }),
    [customOptions, sessionToken]
  );

  return (
    <FetchProvider
      url={baseUrl || process.env.REACT_APP_HOST}
      options={options}
    >
      {children}
    </FetchProvider>
  );
};

export { HttpProvider };
