import { Container, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from './StandardLayout.styles';
import { AccountPopover } from './sections/AccountPopover/AccountPopover';
import { Logo } from 'components';
import * as PATHS from 'constants/paths';
import { useAuthSelector } from 'stores';
const StandardLayout = () => {
  const theme = useTheme();
  const { role } = useAuthSelector();
  const { pathname } = useLocation();
  return (
    <>
      <Header>
        <Logo link={PATHS.ROOT} logoSize="small" sx={{ height: 76 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </Header>
      <Container
        sx={{
          paddingTop:
            pathname === PATHS.HOME && role && role.isHotel ? '90px' : '115px',
          paddingInline:
            pathname === PATHS.HOME && role && role.isHotel
              ? '0px !important'
              : theme.spacing(6),
          marginBottom: '30px',
          [theme.breakpoints.up('lg')]: {
            maxWidth:
              pathname === PATHS.HOME && role && role.isHotel
                ? '100%'
                : '1390px',
          },
        }}
      >
        <Outlet />
      </Container>
    </>
  );
};

export { StandardLayout };
