import { Box, Grow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SnackbarProvider as NotiStackProvider, SnackbarKey } from 'notistack';

import { ReactNode, useRef } from 'react';
import { SnackbarStyles } from './SnackbarProvider.styles';
import { SnackbarIconProps } from './SnackbarProvider.types';
import { Iconify } from 'components';

import { IconButtonAnimate } from 'components/animations';

const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notistackRef = useRef<any>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <SnackbarStyles />

      <NotiStackProvider
        ref={notistackRef}
        maxSnack={4}
        autoHideDuration={3000}
        TransitionComponent={Grow}
        variant="default" // Set default variant
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon={'eva:info-fill'} color="success" />,
          success: (
            <SnackbarIcon
              icon={'eva:checkmark-circle-2-fill'}
              color="success"
            />
          ),
          warning: (
            <SnackbarIcon icon={'eva:alert-triangle-fill'} color="warning" />
          ),
          error: <SnackbarIcon icon={'eva:alert-circle-fill'} color="error" />,
        }}
        // With close as default
        action={key => (
          <IconButtonAnimate
            size="small"
            onClick={onClose(key)}
            sx={{ color: theme.palette.common.white }}
          >
            <Iconify icon={'eva:close-fill'} />
          </IconButtonAnimate>
        )}
      >
        {children}
      </NotiStackProvider>
    </>
  );
};

const SnackbarIcon = ({ icon }: SnackbarIconProps) => (
  <Box
    component="span"
    sx={{
      mr: 1.5,
      width: 40,
      height: 40,
      display: 'flex',
      borderRadius: '100px',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme => theme.palette.common.white,
    }}
  >
    <Iconify icon={icon} width={25} height={25} />
  </Box>
);

export { SnackbarProvider };
