import { FormProvider as Form } from 'react-hook-form';
import { CustomFormProviderProps } from './FormProvider.types';

const FormProvider = ({
  children,
  onSubmit,
  methods,
}: CustomFormProviderProps) => (
  <Form {...methods}>
    <form onSubmit={onSubmit} noValidate>
      {children}
    </form>
  </Form>
);

export { FormProvider };
