import { AccountIcon } from 'assets';
import { Avatar } from 'components';
import { IAvatarProps } from 'components/Avatar/Avatar.types';
import { useAuthSelector } from 'stores';
import { getInitials } from 'utils/createAvatar';

const MyAvatar = ({ ...other }: IAvatarProps) => {
  const { user } = useAuthSelector();

  const avatarData =
    user && user.name && user.surname ? (
      getInitials(`${user.name} ${user.surname}`)
    ) : (
      <AccountIcon fill={'white'} />
    );

  return (
    <Avatar
      // src={user?.photoURL}
      color="primary"
      {...other}
    >
      {avatarData}
    </Avatar>
  );
};

export { MyAvatar };
