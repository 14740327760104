import { Paper } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridColumns,
  //GridFilterModel,
  GridOverlay,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { useState } from 'react';

interface ICustomTableProps {
  tableData: object[];
  columns: GridColumns;
  checkboxSelection?: boolean;
  customHeader?: JSX.Element;
  handlePageChange: (n: number) => void;
  handlePageSizeChange: (n: number) => void;
  handleSortModelChange: (a: GridSortModel) => void;
  handleSelectionModelChange: (ms: GridSelectionModel) => void;
}

export const Table = ({
  tableData,
  columns,
  checkboxSelection = false,
  handlePageChange,
  handlePageSizeChange,
  handleSortModelChange,
  handleSelectionModelChange,
  customHeader,
  ...rest
}: ICustomTableProps & Partial<DataGridProps>) => {
  const [pageSize, setPageSize] = useState<number>(25);

  const customNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>No Results found</div>
      </GridOverlay>
    );
  };

  return (
    <Paper
      elevation={3}
      sx={{ minHeight: '500px', width: '100%', marginBottom: '50px' }}
    >
      {customHeader && customHeader}
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={pageSize}
        // loading={tableData.length <= 0}
        rowsPerPageOptions={[25, 50, 100]}
        checkboxSelection={checkboxSelection}
        paginationMode={'server'}
        sortingMode={'server'}
        filterMode={'server'}
        autoHeight
        components={{
          NoRowsOverlay: customNoRowsOverlay,
          NoResultsOverlay: customNoRowsOverlay,
        }}
        onPageChange={(page: number) => {
          handlePageChange(page);
        }}
        onPageSizeChange={(pageSize: number) => {
          handlePageSizeChange(pageSize);
          setPageSize(pageSize);
        }}
        // onFilterModelChange={(
        //   model: GridFilterModel,
        //   details: GridCallbackDetails
        // ) => {
        //   console.log(model, details);
        // }}
        onSelectionModelChange={(selectionModel: GridSelectionModel) => {
          handleSelectionModelChange(selectionModel);
        }}
        onSortModelChange={(model: GridSortModel) => {
          console.log('C.LOG ~ file: Table.tsx ~ line 89 ~ model', model);
          handleSortModelChange(model);
        }}
        // hideFooterSelectedRowCount
        // hideFooterPagination
        //hideFooter
        {...rest}
      />
    </Paper>
  );
};
