const FilterKPIIcon = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.6" cx="22.5" cy="22.5" r="22.5" fill="white" />
      <g clipPath="url(#clip0_1725_47151)">
        <path
          d="M32.2264 22.8057C31.9852 21.8422 31.1864 21.2276 30.1581 21.227C26.7202 21.2246 23.2828 21.2258 19.8448 21.2264C18.1512 21.2264 16.4581 21.217 14.7645 21.2329C13.9815 21.2405 13.386 21.6239 13.0143 22.3137C12.6415 23.0053 12.6642 23.7121 13.0755 24.3808C13.4822 25.0419 14.0945 25.3718 14.8653 25.3571C15.1438 25.3518 15.3319 25.4405 15.5254 25.6366C17.1165 27.2546 18.7169 28.8632 20.3185 30.47C20.4309 30.5828 20.4764 30.6937 20.4752 30.8534C20.4688 32.0323 20.4833 33.2117 20.4653 34.3906C20.4589 34.7963 20.5812 35.0851 20.9547 35.2507H21.3037C21.3188 35.2366 21.3316 35.2184 21.3491 35.2096C22.2714 34.7464 23.1925 34.2802 24.1182 33.824C24.4433 33.6638 24.5884 33.4225 24.582 33.0561C24.5692 32.3311 24.5843 31.606 24.5738 30.881C24.5715 30.7049 24.621 30.5828 24.744 30.4589C26.3048 28.8937 27.8661 27.3285 29.4124 25.7487C29.6728 25.4822 29.9245 25.353 30.3102 25.3507C31.5972 25.343 32.5421 24.0638 32.2275 22.8062L32.2264 22.8057ZM27.7566 25.5608C26.3642 26.9646 24.9741 28.37 23.5764 29.7679C23.3597 29.9845 23.2589 30.2205 23.2647 30.5282C23.2769 31.2162 23.2647 31.9049 23.2717 32.5929C23.2735 32.7309 23.228 32.7996 23.1062 32.8583C22.6751 33.0655 22.2498 33.2845 21.7785 33.5217V32.0986C21.7785 31.5638 21.7715 31.0295 21.7814 30.4947C21.7866 30.2117 21.6928 29.9904 21.4936 29.7908C20.0918 28.3865 18.6959 26.9763 17.2988 25.5673C17.2446 25.5127 17.1939 25.4552 17.12 25.3747H27.9262C27.858 25.4493 27.8096 25.5068 27.7566 25.5603V25.5608ZM30.3078 24.0409C30.2216 24.0491 30.1337 24.045 30.0463 24.045C27.5387 24.045 25.0312 24.045 22.5236 24.045C19.98 24.045 17.4357 24.045 14.8921 24.045C14.4877 24.045 14.2093 23.8554 14.1044 23.5148C13.9611 23.0493 14.2879 22.5738 14.7715 22.5462C14.8222 22.5432 14.8734 22.5438 14.9241 22.5438C19.9899 22.5438 25.0562 22.5438 30.122 22.5438C30.5735 22.5438 30.8503 22.7323 30.9505 23.1039C31.0687 23.5442 30.76 23.9968 30.3078 24.0403V24.0409Z"
          fill="#1C74A2"
        />
        <path
          d="M29.5166 12.75C28.0111 12.7541 26.776 14.0046 26.7754 15.5252C26.7754 17.0574 28.0123 18.3021 29.5346 18.3009C31.0541 18.2997 32.2927 17.0475 32.2886 15.5175C32.2851 13.9911 31.0372 12.7459 29.5166 12.75ZM29.5387 16.9811C28.7324 16.9852 28.0833 16.3347 28.0851 15.5234C28.0868 14.7244 28.7318 14.0715 29.5235 14.0686C30.3235 14.0651 30.9824 14.7255 30.9806 15.5299C30.9789 16.3265 30.3328 16.977 29.5393 16.9811H29.5387Z"
          fill="#1C74A2"
        />
        <path
          d="M22.4623 14.1607C20.9417 14.1918 19.7427 15.4705 19.773 17.0292C19.8022 18.5139 21.0757 19.7451 22.5538 19.7163C24.1059 19.6864 25.3025 18.43 25.2757 16.8601C25.2501 15.3548 23.9736 14.1302 22.4623 14.1607V14.1607ZM22.5276 18.3965C21.7242 18.3989 21.0781 17.7443 21.081 16.9312C21.0839 16.1316 21.7294 15.4805 22.5212 15.4787C23.3217 15.477 23.9707 16.1357 23.9678 16.947C23.9649 17.7484 23.3234 18.3942 22.527 18.3965H22.5276Z"
          fill="#1C74A2"
        />
        <path
          d="M15.5818 12.7503C14.0548 12.7274 12.787 13.948 12.7602 15.4679C12.7328 17.0108 13.9557 18.2812 15.4862 18.3012C17.0068 18.3212 18.2472 17.0977 18.2728 15.5536C18.2979 14.0525 17.0703 12.7738 15.5818 12.7503ZM15.5113 16.9808C14.7172 16.9773 14.0711 16.328 14.0688 15.5307C14.0658 14.727 14.7242 14.0654 15.5241 14.0683C16.3159 14.0713 16.9602 14.7217 16.9637 15.5219C16.9672 16.3321 16.3182 16.9844 15.5113 16.9808V16.9808Z"
          fill="#1C74A2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1725_47151">
          <rect
            width="19.5395"
            height="22.5"
            fill="white"
            transform="translate(12.75 12.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilterKPIIcon;
