import { useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';

import {
  ContentWrapper,
  LeftCol,
  RightCol,
  Wrapper,
} from './DoubleColLayout.styles';

import { getLayoutConfig } from './utils';
import { Image, Logo, Scrollbar } from 'components';

const DoubleColLayout = () => {
  const { pathname, search } = useLocation();
  const { backgroundPosition, backgroundSrc } = getLayoutConfig(
    pathname,
    search
  );

  return (
    <Wrapper>
      <LeftCol>
        <Image
          src={backgroundSrc}
          sx={{
            height: '100%',
            objectPosition: backgroundPosition,
            '& img': { objectPosition: backgroundPosition },
          }}
        />
      </LeftCol>
      <Scrollbar>
        <RightCol>
          <ContentWrapper>
            <Logo sx={{ width: '80%', marginBottom: '26px' }} />
            <Outlet />
          </ContentWrapper>
        </RightCol>
      </Scrollbar>
    </Wrapper>
  );
};

export { DoubleColLayout };
