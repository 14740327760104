// form
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { CustomTextFieldProps } from './TextField.types';

const RHFTextField = ({
  name,
  rules,
  defaultValue,
  onChange,
  ...other
}: CustomTextFieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          {...other}
          onChange={e => {
            field.onChange(e.target.value);
            onChange?.(e);
          }}
        />
      )}
    />
  );
};

export { RHFTextField, TextField };
