import ThemeProvider from './theme';
import { ScrollToTop } from 'components';
import { ProgressBarStyle } from 'components/ProgressBar/ProgressBar.styles';
import { MotionLazyContainer } from 'components/animations/';
import ThemeSettings from 'components/settings';
import { AmplifyProvider, LoadingProvider, SnackbarProvider } from 'providers';
import Router from 'routes';
import './theme/global.css';

export default function App() {
  return (
    <AmplifyProvider>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <SnackbarProvider>
              <ProgressBarStyle />
              <ScrollToTop />
              <LoadingProvider />
              <Router />
            </SnackbarProvider>
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </AmplifyProvider>
  );
}
