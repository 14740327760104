const AddWhiteIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 15C11 15.55 11.45 16 12 16H28C28.55 16 29 15.55 29 15C29 14.45 28.55 14 28 14H12C11.45 14 11 14.45 11 15ZM19 26H21C21.55 26 22 25.55 22 25C22 24.45 21.55 24 21 24H19C18.45 24 18 24.45 18 25C18 25.55 18.45 26 19 26ZM25 21H15C14.45 21 14 20.55 14 20C14 19.45 14.45 19 15 19H25C25.55 19 26 19.45 26 20C26 20.55 25.55 21 25 21Z"
        fill="#637381"
      />
    </svg>
  );
};

export default AddWhiteIcon;
