const AutoDeleteWhiteIcon = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 2.16634H11.5L10.5 1.08301H5.5L4.5 2.16634H1V4.33301H15V2.16634Z"
        fill="white"
      />
      <path
        d="M16 9.74967C15.3 9.74967 14.63 9.85801 14 10.0638V5.41634H2V18.4163C2 19.608 2.9 20.583 4 20.583H9.68C10.8 23.1397 13.21 24.9163 16 24.9163C19.87 24.9163 23 21.5255 23 17.333C23 13.1405 19.87 9.74967 16 9.74967ZM9 17.333C9 17.7013 9.03 18.0588 9.08 18.4163H4V7.58301H12V11.1147C10.19 12.4905 9 14.7547 9 17.333ZM16 22.7497C13.24 22.7497 11 20.323 11 17.333C11 14.343 13.24 11.9163 16 11.9163C18.76 11.9163 21 14.343 21 17.333C21 20.323 18.76 22.7497 16 22.7497Z"
        fill="white"
      />
      <path
        d="M16.5 12.9997H15V18.4163L18.6 20.6913L19.4 19.3913L16.5 17.5497V12.9997Z"
        fill="white"
      />
    </svg>
  );
};

export default AutoDeleteWhiteIcon;
