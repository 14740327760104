const MagicWandIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6259 4.625L14.3309 3.08L15.8759 2.375L14.3309 1.67L13.6259 0.125L12.9209 1.67L11.3759 2.375L12.9209 3.08L13.6259 4.625Z"
        fill="white"
      />
      <path
        d="M11.9084 6.215L9.7859 4.0925C9.6359 3.95 9.4484 3.875 9.2534 3.875C9.0584 3.875 8.8709 3.95 8.7209 4.0925L0.343398 12.47C0.0508984 12.7625 0.0508984 13.235 0.343398 13.5275L2.4659 15.65C2.6159 15.8 2.8034 15.875 2.9984 15.875C3.1934 15.875 3.3809 15.8 3.5309 15.6575L11.9084 7.28C12.2009 6.9875 12.2009 6.5075 11.9084 6.215ZM9.2534 5.69L10.3109 6.7475L9.4334 7.625L8.3759 6.5675L9.2534 5.69ZM2.9984 14.0675L1.9409 13.01L7.3184 7.625L8.3759 8.6825L2.9984 14.0675Z"
        fill="white"
      />
    </svg>
  );
};

export default MagicWandIcon;
