import { Box } from '@mui/material';

import { RootStyle, SimpleBarStyle } from './Scrollbar.styles';
import { ICustomScrollbarProps } from './Scrollbar.types';

const Scrollbar = ({ children, sx, ...other }: ICustomScrollbarProps) => {
  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} {...other}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
};

export { Scrollbar };
