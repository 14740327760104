import { Amplify } from 'aws-amplify';
import { ReactNode, useEffect } from 'react';
import { useCallback } from 'react';
import { awsConfig } from 'aws-exports';

import { useMyArea } from 'hooks/api/useMyArea';
import { useAuthSelector, useAuthStore } from 'stores';

const AmplifyProvider = ({ children }: { children: ReactNode }) => {
  const { getSession } = useAuthSelector();
  const { fetchMyArea } = useMyArea();

  useEffect(() => {
    Amplify.configure(awsConfig);
    sessionInit();
    fetchMyArea().then(user => user && useAuthStore.setState({ user }));
  }, []);

  const sessionInit = useCallback(async () => {
    await getSession();
  }, [getSession]);

  return <>{children}</>;
};

export { AmplifyProvider };
