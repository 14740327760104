import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useFetch from 'use-http';
import { Option, RHFAutocompleteProps } from './Autocomplete.types';
import useDebounce from 'hooks/useDebounce';

//TODO: Autocomplete maybe too much static, try to think if could be usefull create a more dynamic Autocomplete
//      We need time 🕒
const RHFAutocomplete = ({
  name,
  rules,
  label,
  placeholder,
  charactersCount = 3,
  endpoint,
  inputList = [],
}: RHFAutocompleteProps) => {
  const { control } = useFormContext();
  const { get, loading } = useFetch();
  const [options, setOptions] = useState<Option[]>([]);
  const [characters, setCharacters] = useState('');
  const debouncedText = useDebounce(characters, 500);

  useEffect(() => {
    if (debouncedText && debouncedText.length >= charactersCount && endpoint) {
      get(`${endpoint}=${characters}`).then(res => {
        const data = res.length
          ? res.map((o: { id: number; name: string }) => ({
              value: o.id,
              label: o.name,
            }))
          : [];
        setOptions(data);
      });
    }
    if (
      debouncedText &&
      debouncedText !== '' &&
      debouncedText.length >= charactersCount &&
      inputList.length
    ) {
      setOptions(inputList);
    } else {
      setOptions([]);
    }
  }, [debouncedText]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          id={name}
          fullWidth
          loadingText={options.length === 0 ? 'Loading...' : ''}
          options={options}
          getOptionLabel={(option: Option) => option.label}
          onChange={(_event, value) => field.onChange(value)}
          onInputChange={(_event, value) => setCharacters(value)}
          renderInput={params => (
            <TextField
              {...field}
              {...params}
              fullWidth
              error={!!error}
              helperText={error?.message}
              autoComplete={'off'}
              label={label}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export { RHFAutocomplete };
