import { styled } from '@mui/material/styles';

export const Header = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  width: '100%',
  minHeight: 90,
  position: 'fixed',
  backgroundColor: theme.palette.common.white,
  padding: '9px 40px 7px 51px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 2,
}));
