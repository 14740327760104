const GroupWhiteIcon = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 14.0833C5.1 14.0833 6 13.1083 6 11.9167C6 10.725 5.1 9.75 4 9.75C2.9 9.75 2 10.725 2 11.9167C2 13.1083 2.9 14.0833 4 14.0833ZM5.13 15.275C4.76 15.21 4.39 15.1667 4 15.1667C3.01 15.1667 2.07 15.3942 1.22 15.795C0.48 16.1417 0 16.9217 0 17.7992V19.5H4.5V17.7558C4.5 16.8567 4.73 16.0117 5.13 15.275ZM20 14.0833C21.1 14.0833 22 13.1083 22 11.9167C22 10.725 21.1 9.75 20 9.75C18.9 9.75 18 10.725 18 11.9167C18 13.1083 18.9 14.0833 20 14.0833ZM24 17.7992C24 16.9217 23.52 16.1417 22.78 15.795C21.93 15.3942 20.99 15.1667 20 15.1667C19.61 15.1667 19.24 15.21 18.87 15.275C19.27 16.0117 19.5 16.8567 19.5 17.7558V19.5H24V17.7992ZM16.24 14.7875C15.07 14.2242 13.63 13.8125 12 13.8125C10.37 13.8125 8.93 14.235 7.76 14.7875C6.68 15.3075 6 16.4775 6 17.7558V19.5H18V17.7558C18 16.4775 17.32 15.3075 16.24 14.7875ZM8.07 17.3333C8.16 17.0842 8.2 16.9108 8.98 16.5858C9.95 16.1742 10.97 15.9792 12 15.9792C13.03 15.9792 14.05 16.1742 15.02 16.5858C15.79 16.9108 15.83 17.0842 15.93 17.3333H8.07ZM12 8.66667C12.55 8.66667 13 9.15417 13 9.75C13 10.3458 12.55 10.8333 12 10.8333C11.45 10.8333 11 10.3458 11 9.75C11 9.15417 11.45 8.66667 12 8.66667ZM12 6.5C10.34 6.5 9 7.95167 9 9.75C9 11.5483 10.34 13 12 13C13.66 13 15 11.5483 15 9.75C15 7.95167 13.66 6.5 12 6.5Z"
        fill="white"
      />
    </svg>
  );
};

export default GroupWhiteIcon;
