import { IntlShape } from 'react-intl';

import { authTranslations } from './auth';
import { contractTranslations } from './contract';
import { generalTranslations } from './general';
import { homeTranslations } from './home';
import { hotelsTranslations } from './hotels';
import { myAreaTranslations } from './myarea';
import { usersTranslations } from './users';

export const translations = (intl: IntlShape) => ({
  ...authTranslations(intl),
  ...homeTranslations(intl),
  ...hotelsTranslations(intl),
  ...generalTranslations(intl),
  ...usersTranslations(intl),
  ...myAreaTranslations(intl),
  ...contractTranslations(intl),
});
