const EditCalendarWhiteIcon = () => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.93359 10.2917H17.9336V12.4583H19.9336V5.95833C19.9336 4.76667 19.0336 3.79167 17.9336 3.79167H16.9336V1.625H14.9336V3.79167H6.93359V1.625H4.93359V3.79167H3.93359C2.82359 3.79167 1.94359 4.76667 1.94359 5.95833L1.93359 21.125C1.93359 22.3167 2.82359 23.2917 3.93359 23.2917H10.9336V21.125H3.93359V10.2917ZM3.93359 5.95833H17.9336V8.125H3.93359V5.95833ZM21.7736 17.095L21.0636 17.8642L18.9436 15.5675L19.6536 14.7983C20.0436 14.3758 20.6736 14.3758 21.0636 14.7983L21.7736 15.5675C22.1636 15.99 22.1636 16.6725 21.7736 17.095ZM18.2336 16.3367L20.3536 18.6333L15.0536 24.375H12.9336V22.0783L18.2336 16.3367Z"
        fill="white"
      />
    </svg>
  );
};

export default EditCalendarWhiteIcon;
