import { Navigate, useLocation } from 'react-router-dom';

import { AuthGuardProps } from './AuthGuard.types';
import * as PATHS from 'constants/paths';
import { useAuthSelector, useGlobalSelector, useGlobalStore } from 'stores';

const AuthGuard = ({ children, roles }: AuthGuardProps) => {
  const { isAuthenticated, cognitoData } = useAuthSelector();

  const { requestedLocation } = useGlobalSelector();
  const { pathname } = useLocation();

  const needsAdditionalData = cognitoData && !cognitoData.hasCompleteData;
  if (needsAdditionalData && pathname !== PATHS.ENTER_DATA) {
    return <Navigate to={PATHS.ENTER_DATA} />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation)
      useGlobalStore.setState({ requestedLocation: pathname });

    return <Navigate to={PATHS.LOGIN} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    useGlobalStore.setState({ requestedLocation: null });
    return <Navigate to={requestedLocation} />;
  }

  if (
    cognitoData &&
    cognitoData.group &&
    roles?.length &&
    !roles.includes(cognitoData.group)
  ) {
    return <Navigate to={PATHS.PERMISSION_DENIED} />;
  }

  return <>{children}</>;
};

export { AuthGuard };
