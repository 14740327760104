import { Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ILogoProps } from './Logo.types';
import { Logo as LogoAsset, Logo as LogoPinAsset } from 'assets';

const Logo = ({ link, iconOnly, logoSize = 'big', sx }: ILogoProps) => {
  const logo = (
    <Box sx={{ ...sx }}>
      {iconOnly ? (
        <LogoPinAsset sizeMappingKey={logoSize} />
      ) : (
        <LogoAsset sizeMappingKey={logoSize} />
      )}
    </Box>
  );

  if (!link) {
    return <>{logo}</>;
  }

  return <RouterLink to={link}>{logo}</RouterLink>;
};

export { Logo };
