export interface IUser {
  id: number;
  email: string;
  name: string;
  surname: string;
  companyName: string;
  role: number;
  status: number;
  username: string;
  phone: string;
  contactEmail: string;
  contactPhone: string;
  hotelId?: number;
  permissions: UserPermissions;
}

export enum UserType {
  ADMIN,
  BACKOFFICE_OPERATOR,
  MANAGER,
  HOTEL,
  STAKEHOLDER,
}

export enum UserPermissions {
  WRITE,
  READ,
}
