import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { ProgressBar } from 'components';
import * as PATHS from 'constants/paths';
import { AuthGuard, GuestGuard } from 'guards';
import { DoubleColLayout, LogoOnlyLayout, StandardLayout } from 'layouts';
import { UserType } from 'types/user';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<ProgressBar />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATHS.HOME} replace />,
    },
    {
      path: PATHS.MY_AREA_ROOT,
      element: (
        <AuthGuard>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <MyArea />,
          index: true,
        },
      ],
    },
    {
      path: PATHS.HOME,
      element: (
        <AuthGuard>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Home />,
          index: true,
        },
      ],
    },
    {
      path: PATHS.HOTEL_ROOT,
      element: (
        <AuthGuard
          roles={[
            UserType[UserType.ADMIN],
            UserType[UserType.BACKOFFICE_OPERATOR],
            UserType[UserType.MANAGER],
          ]}
        >
          <StandardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <HotelList />, index: true },
        {
          path: PATHS.HOTEL_CONFIGURATION,
          element: <HotelConfiguration />,
        },
        {
          path: PATHS.HOTEL_PROPRIETOR,
          element: <Proprietor />,
        },
        {
          path: PATHS.HOTEL_CONFIGURATION_ID,
          element: <HotelConfiguration />,
        },
        {
          path: PATHS.HOTEL_PROPRIETOR_ID,
          element: <Proprietor />,
        },
        {
          path: PATHS.HOTEL_IMPORT,
          element: <Import />,
        },
        {
          path: PATHS.HOTEL_COMMUNICATION_EMAIL,
          element: <CommunicationEmail />,
        },
        {
          path: PATHS.HOTEL_IMPORT_RECAP,
          element: <ImportRecap />,
        },
        {
          path: PATHS.HOTEL_WORKS_ID,
          element: <Works />,
        },
        {
          path: PATHS.HOTEL_BANDB_ID,
          element: <BedAndBreakfast />,
        },
        {
          path: PATHS.HOTEL_ACCOMODATION_ID,
          element: <Accomodation />,
        },
        {
          path: PATHS.HOTEL_EQUIPMENT_ID,
          element: <Equipments />,
        },
        {
          path: PATHS.HOTEL_GARAGES_PARKING_ID,
          element: <GaragesParking />,
        },
        { path: PATHS.HOTEL_HALL_ID, element: <Halls /> },
        {
          path: PATHS.HOTEL_COMMERCIAL_AGREEMENTS_ID,
          element: <CommercialAgreements />,
        },
        {
          path: PATHS.HOTEL_ROOMS_ID,
          element: <Rooms />,
        },
        {
          path: PATHS.HOTEL_CONTRACT_ID,
          element: <Contract />,
        },
      ],
    },
    {
      path: PATHS.USERMANAGMENT_ROOT,
      element: (
        <AuthGuard>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [{ element: <UserManagementList />, index: true }],
    },
    /**
     * FRONT OFFICE ROUTING
     */
    {
      path: PATHS.HOTEL_CONFIGURATION_FO,
      element: (
        <AuthGuard roles={[UserType[UserType.HOTEL]]}>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <HotelConfiguration />,
          index: true,
        },
        {
          path: PATHS.HOTEL_PROPRIETOR_FO,
          element: <Proprietor />,
        },
        {
          path: PATHS.HOTEL_ACCOMODATION_FO,
          element: <Accomodation />,
        },
        {
          path: PATHS.HOTEL_WORKS_FO,
          element: <Works />,
        },
        {
          path: PATHS.HOTEL_BANDB_FO,
          element: <BedAndBreakfast />,
        },
        {
          path: PATHS.HOTEL_EQUIPMENT_FO,
          element: <Equipments />,
        },
        {
          path: PATHS.HOTEL_GARAGES_PARKING_FO,
          element: <GaragesParking />,
        },
        {
          path: PATHS.HOTEL_HALL_FO,
          element: <Halls />,
        },
        {
          path: PATHS.HOTEL_COMMERCIAL_AGREEMENTS_FO,
          element: <CommercialAgreements />,
        },
        {
          path: PATHS.HOTEL_ROOMS_FO,
          element: <Rooms />,
        },
        {
          path: PATHS.HOTEL_CONTRACT_FO,
          element: <Contract />,
        },
      ],
    },
    {
      path: '/auth',
      element: <DoubleColLayout />,
      children: [
        { element: <Navigate to="/auth/login" replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'enter-data',
          element: (
            <AuthGuard>
              <EnterData />
            </AuthGuard>
          ),
        },
        { path: 'new-password', element: <NewPassword /> },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: PATHS.PERMISSION_DENIED,
      element: (
        <AuthGuard>
          <StandardLayout />
        </AuthGuard>
      ),
      children: [{ element: <PermissionDenied />, index: true }],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Auth
const Login = Loadable(lazy(() => import('../pages/Auth/Login')));
const EnterData = Loadable(lazy(() => import('../pages/Auth/EnterData')));
const NewPassword = Loadable(lazy(() => import('../pages/Auth/NewPassword')));
const ResetPassword = Loadable(
  lazy(() => import('../pages/Auth/ResetPassword'))
);
const PermissionDenied = Loadable(
  lazy(() => import('pages/Auth/PermissionDenied'))
);
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// Home
const Home = Loadable(lazy(() => import('../pages/Home')));
// MYArea
const MyArea = Loadable(lazy(() => import('../pages/MyArea')));

// Hotel
const Works = Loadable(
  lazy(() => import('pages/Hotel/HotelConfiguration/Works'))
);
const BedAndBreakfast = Loadable(
  lazy(() => import('pages/Hotel/HotelConfiguration/BedAndBreakfast'))
);
const Halls = Loadable(
  lazy(() => import('pages/Hotel/HotelConfiguration/Halls'))
);
const Accomodation = Loadable(
  lazy(() => import('pages/Hotel/HotelConfiguration/Accomodation'))
);
const Equipments = Loadable(
  lazy(() => import('pages/Hotel/HotelConfiguration/Equipment'))
);
const CommercialAgreements = Loadable(
  lazy(() => import('pages/Hotel/HotelConfiguration/CommercialAgreements'))
);
const Rooms = Loadable(
  lazy(() => import('pages/Hotel/HotelConfiguration/Rooms'))
);
const HotelConfiguration = Loadable(
  lazy(() => import('pages/Hotel/HotelConfiguration'))
);
const HotelList = Loadable(lazy(() => import('pages/Hotel/')));
const Import = Loadable(lazy(() => import('pages/Hotel/Import')));
const CommunicationEmail = Loadable(
  lazy(() => import('pages/Hotel/CommunicationEmail'))
);
const ImportRecap = Loadable(lazy(() => import('pages/Hotel/ImportRecap')));
const Proprietor = Loadable(
  lazy(() => import('pages/Hotel/HotelConfiguration/Proprietor'))
);
const GaragesParking = Loadable(
  lazy(() => import('pages/Hotel/HotelConfiguration/GaragesParking'))
);
const UserManagementList = Loadable(lazy(() => import('pages/UserManagment')));
const Contract = Loadable(
  lazy(() => import('pages/Hotel/HotelConfiguration/ContractPreview'))
);
