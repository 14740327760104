const CirclePlusKPIIcon = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.7" cx="22.5" cy="22.5" r="22.5" fill="white" />
      <path
        d="M21.565 31.4897C21.5566 31.4897 21.5483 31.4897 21.5399 31.4891C19.4006 31.3541 17.3539 30.4808 15.7778 29.0311C14.2009 27.582 13.1615 25.6176 12.8507 23.4995C12.4915 21.0531 13.1004 18.6048 14.5652 16.605C16.0307 14.6046 18.1797 13.2891 20.617 12.901C20.9581 12.8464 21.3166 12.8188 21.6635 12.7911C21.8257 12.7783 21.9898 12.7654 22.152 12.75C24.3949 12.7712 26.5632 13.5892 28.2597 15.0537C29.9627 16.5234 31.0794 18.5495 31.4037 20.7588C31.4469 21.0506 31.472 21.3449 31.4893 21.5691C31.4919 21.6032 31.4913 21.6649 31.4591 21.7002C31.4314 21.7304 31.3812 21.7382 31.3439 21.7388C31.1212 21.7433 30.8882 21.7452 30.6514 21.7452C30.4145 21.7452 30.1802 21.7433 29.937 21.7394C29.8533 21.7382 29.7934 21.7369 29.7754 21.5357C29.5926 19.4563 28.736 17.7392 27.23 16.4334C25.7182 15.1225 24.0461 14.4581 22.2595 14.4581C21.4402 14.4581 20.5919 14.6007 19.7385 14.8822C16.9575 15.7992 15.185 17.8015 14.6135 20.6721C14.2054 22.7233 14.6225 24.7848 15.7887 26.478C16.9736 28.1983 18.7904 29.3453 20.9047 29.7078C21.0823 29.738 21.2567 29.7534 21.426 29.7682C21.4704 29.772 21.5129 29.7759 21.5554 29.7797C21.7008 29.7932 21.7227 29.8292 21.7246 29.9468C21.7311 30.4461 21.7311 30.8992 21.7246 31.3304C21.7227 31.4467 21.6796 31.4904 21.565 31.4904V31.4897Z"
        fill="#1C74A2"
      />
      <path
        d="M27.6439 31.4992C27.5313 31.4992 27.4399 31.4973 27.3556 31.4941C27.147 31.4851 27.0762 31.4112 27.0756 31.1998C27.073 30.7178 27.0737 30.2269 27.0743 29.7526V28.4629H25.5329C25.4145 28.4629 25.296 28.4629 25.1776 28.4636H25.1384C25.0341 28.4636 24.9298 28.4642 24.8256 28.4642C24.6724 28.4642 24.5192 28.4636 24.366 28.461C24.1092 28.4565 24.0423 28.3871 24.041 28.1223V28.0304C24.0391 27.8088 24.0378 27.5819 24.041 27.3615C24.0442 27.161 24.1234 27.0826 24.3216 27.0813C24.6428 27.0794 24.9639 27.0787 25.2877 27.0787C25.5149 27.0787 27.0743 27.08 27.0743 27.08V24.4781C27.0743 24.0829 27.1071 24.0508 27.5139 24.0508H27.8794C27.9573 24.0508 28.0358 24.0508 28.1144 24.0508C28.4001 24.0521 28.4619 24.1157 28.4632 24.4074C28.4645 24.8772 28.4645 25.3469 28.4638 25.8167V27.0794H30.3438C30.627 27.0787 30.9102 27.0794 31.1933 27.08C31.4122 27.0813 31.4888 27.1546 31.4945 27.3718C31.501 27.5967 31.501 27.8357 31.4945 28.1468C31.49 28.3916 31.4167 28.4629 31.1695 28.4642C30.8587 28.4655 30.5485 28.4661 30.2357 28.4661C30.0104 28.4661 28.487 28.4649 28.487 28.4649L28.4696 28.8324C28.4683 28.862 28.4664 28.8903 28.4651 28.9172C28.4613 28.9847 28.4574 29.0464 28.4574 29.1132C28.4574 29.4037 28.4574 29.6948 28.4574 29.9859C28.4574 30.3599 28.4574 30.7345 28.4568 31.1085C28.4561 31.4395 28.4001 31.4935 28.0603 31.4947C27.9979 31.4947 27.9354 31.4954 27.873 31.496C27.7964 31.4967 27.7198 31.4973 27.6432 31.4973L27.6439 31.4992Z"
        fill="#1C74A2"
      />
    </svg>
  );
};

export default CirclePlusKPIIcon;
