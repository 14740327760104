import { makePath } from 'utils/navigation';

export const HOTELS = 'hotels';
export const HOTELS_KPI = 'hotels/kpi';
export const HOTEL_CONFIG_CARDS = 'cards';
export const HOTELS_FILTERS = 'hotels/filters';
export const HOTELS_EXPORT = 'hotels/export';
export const HOTELS_IMPORT = 'hotels/import';
export const HOTEL_ID = 'hotels/id';
export const HOTELS_SINGLE_DELETE = 'hotels/:id';
export const HOTELS_MULTIPLE_DELETE = 'hotels/batch';
export const HOTELS_CREDENTIALS = 'hotels/credentials';
export const CITY_SEARCH = 'places/searchcity';
export const HOTEL_CARDS = 'hotels/cards/:id';
export const HOTEL_PROPRIETOR_INFO = 'hotels/proprietorinfo';
export const HOTEL_PROPRIETOR_INFO_UPDATE = 'hotels/proprietorinfo/:id';
export const HOTEL_WORKS_ID = makePath('/works', '/:id');
export const HOTEL_BREAKFAST_ID = makePath('/breakfasts', '/:id');
export const HOTEL_ACCOMODATION_ID = makePath('/accomodations', '/:id');
export const HOTEL_EQUIPMENT_ID = makePath('/equipments', '/:id');
export const HOTEL_GARAGES_ID = makePath('/garages', '/:id');
export const HOTEL_HALL_ID = makePath('/halls', '/:id');
export const HOTEL_ROOMS_ID = makePath('/rooms', '/:id');

export const HOTEL_CONTRACTS_ID = makePath('/contracts/:id');
export const HOTEL_CONTRACTS_ROOM_TYPES = makePath(
  HOTEL_CONTRACTS_ID,
  '/roomtypes'
);
export const HOTEL_CONTRACTS_ROOMS = makePath(HOTEL_CONTRACTS_ID, '/rooms');

export const HOTEL_COMMERCIAL_AGREEMENTS_ID = makePath('/agreements', '/:id');
export const PLACES_CITY = 'places/city';

export const USERS = 'users';
export const USER_ID = makePath(USERS, '/:id');
export const USER_MULTIPLE_DELETE = makePath(USERS, '/batch');
export const USER_LOGIN_KEYS = makePath(USERS, '/loginKeys');
export const USER_RESET_PASSWORD = makePath(USER_ID, '/resetPassword');

export const MY_AREA = 'myArea';
export const MY_AREA_CONTACT_INFO = makePath(MY_AREA, '/contactInfo');
export const MY_AREA_LOGIN_EMAIL = makePath(MY_AREA, '/email');
export const MY_AREA_LOGIN_PHONE = makePath(MY_AREA, '/phone');
export const MY_AREA_LOGIN_USERNAME = makePath(MY_AREA, '/username');

export const HOTEL_ROOMS_ENDPOINTS = {
  STEP: 'step',
  PERIODS: 'periods',
  AVAILABILITY: 'availability',
  GUARANTEED: 'guaranteed',
  PRICES: 'price',
};
