const HotelKPIIcon = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="22.5" cy="22.5" r="22.5" fill="white" />
      <g clipPath="url(#clip0_1126_83683)">
        <path
          d="M31.7273 34.7985C31.9149 34.7985 32.0851 34.791 32.2547 34.8C32.6161 34.8194 32.8552 35.0532 32.8625 35.3841C32.8704 35.7247 32.6256 35.9726 32.2539 35.9973C32.1815 36.0018 32.109 35.9988 32.0366 35.9988C25.6354 35.9988 19.2335 35.9988 12.8324 35.9988C12.7693 35.9988 12.7056 36.0003 12.6425 35.998C12.2506 35.9809 11.9978 35.7396 12.0029 35.3886C12.0079 35.048 12.255 34.8142 12.6324 34.7985C12.7932 34.7918 12.9555 34.7978 13.1359 34.7978V13.3586C12.2629 13.139 12.0014 12.7909 12.0014 11.8618C12.0014 11.5638 11.9978 11.2658 12.0021 10.9677C12.0116 10.3194 12.4346 9.83692 13.0598 9.76074C13.167 9.7473 13.2764 9.75028 13.3851 9.75028C19.4154 9.75028 25.4456 9.75028 31.4759 9.75028C32.4321 9.75028 32.8654 10.1977 32.8654 11.1851C32.8654 11.4181 32.8654 11.6512 32.8654 11.8842C32.8654 12.7872 32.5814 13.1584 31.728 13.3556V34.7985H31.7273ZM30.5232 34.7813V13.3436H14.3435V34.7798H17.8013C17.8013 34.6536 17.8013 34.5446 17.8013 34.4348C17.8013 32.2172 17.8006 30.0004 17.8013 27.7828C17.8013 26.9224 18.2591 26.4533 19.0937 26.4526C21.3025 26.4526 23.5121 26.4713 25.7209 26.4414C26.4475 26.4317 27.0879 26.9015 27.0749 27.8426C27.0437 30.0407 27.0647 32.2396 27.0647 34.4385C27.0647 34.5476 27.0647 34.6566 27.0647 34.7813H30.5232ZM13.1685 12.1254H31.7106C31.7106 11.8065 31.712 11.501 31.7099 11.1963C31.7099 11.1238 31.6983 11.0521 31.6918 10.9752H13.1685V12.1254ZM18.9648 34.7791H21.835V30.0519H18.9648V34.7791ZM25.8919 34.7828V30.0489H23.0339V34.7828H25.8919ZM25.9035 27.6626H18.9727V28.8218H25.9027V27.6626H25.9035Z"
          fill="#1C74A2"
        />
        <path
          d="M18.9706 20.4844C19.3321 20.4844 19.6944 20.4791 20.0558 20.4859C20.7817 20.4986 21.2657 20.987 21.2758 21.7309C21.286 22.4935 21.2867 23.2569 21.2758 24.0202C21.2657 24.7529 20.7897 25.2489 20.0805 25.2616C19.33 25.2743 18.5787 25.275 17.8289 25.2616C17.1211 25.2489 16.6452 24.7484 16.6358 24.018C16.6263 23.2546 16.6256 22.492 16.6358 21.7287C16.6452 20.987 17.132 20.4986 17.8579 20.4859C18.2288 20.4791 18.5997 20.4851 18.9699 20.4851L18.9706 20.4844ZM20.0928 24.0486V21.71H17.8202V24.0486H20.0928Z"
          fill="#1C74A2"
        />
        <path
          d="M25.8789 25.265C25.5261 25.265 25.1733 25.2702 24.8205 25.2643C24.0801 25.2516 23.5969 24.7601 23.5897 24.0012C23.5824 23.2476 23.5817 22.4932 23.5897 21.7396C23.5984 20.995 24.0794 20.4968 24.8024 20.4871C25.5442 20.4773 26.2853 20.4766 27.0272 20.4871C27.7364 20.4975 28.2189 20.9897 28.2305 21.7202C28.2428 22.4828 28.2406 23.2461 28.2312 24.0095C28.2225 24.7564 27.7429 25.2471 27.0185 25.2628C26.6389 25.271 26.2585 25.2643 25.8789 25.2643V25.265ZM27.0475 24.0491V21.7105H24.7749V24.0491H27.0475Z"
          fill="#1C74A2"
        />
        <path
          d="M18.9617 14.5306C19.3413 14.5306 19.7216 14.5246 20.1012 14.5321C20.7598 14.5455 21.2459 14.9974 21.2683 15.6748C21.2959 16.4927 21.2944 17.3128 21.2698 18.1314C21.2488 18.8283 20.7467 19.2914 20.0614 19.2958C19.3196 19.3003 18.5785 19.3011 17.8366 19.2958C17.1462 19.2906 16.6514 18.8036 16.637 18.0918C16.6217 17.3009 16.6203 16.5091 16.637 15.7189C16.6529 15.0161 17.1361 14.5448 17.8207 14.5328C18.2003 14.5261 18.5806 14.5313 18.9602 14.5313L18.9617 14.5306ZM20.0897 18.0926V15.7331H17.8178V18.0926H20.0897Z"
          fill="#1C74A2"
        />
        <path
          d="M25.9098 14.5306C26.2894 14.5306 26.6698 14.5246 27.0494 14.5321C27.7209 14.5448 28.2056 15.0056 28.2251 15.6965C28.2483 16.5054 28.2483 17.3158 28.2251 18.1254C28.2049 18.8231 27.705 19.2914 27.0226 19.2958C26.2807 19.3003 25.5396 19.3003 24.7978 19.2958C24.1038 19.2914 23.6076 18.8096 23.5931 18.0978C23.5764 17.3068 23.5764 16.5159 23.5931 15.7249C23.6076 15.0206 24.0893 14.5455 24.771 14.5328C25.1506 14.5254 25.5309 14.5313 25.9106 14.5313L25.9098 14.5306ZM27.0472 15.7346H24.7732V18.0933H27.0472V15.7346Z"
          fill="#1C74A2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1126_83683">
          <rect
            width="20.8654"
            height="26.25"
            fill="white"
            transform="translate(12 9.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HotelKPIIcon;
