const ExportWhiteIcon = ({ fillColor = '#212B36' }: { fillColor?: string }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.83325"
        y="15"
        width="13.3333"
        height="1.66667"
        rx="0.833333"
        fill={fillColor}
      />
      <rect
        x="3.83325"
        y="16.6667"
        width="3.33333"
        height="1.66667"
        rx="0.833333"
        transform="rotate(-90 3.83325 16.6667)"
        fill={fillColor}
      />
      <rect
        x="15.5"
        y="16.6667"
        width="3.33333"
        height="1.66667"
        rx="0.833333"
        transform="rotate(-90 15.5 16.6667)"
        fill={fillColor}
      />
      <path
        d="M10.4999 12.5C10.3272 12.5013 10.1583 12.4489 10.0166 12.35L6.68328 9.99997C6.30926 9.73465 6.21989 9.21701 6.48328 8.84163C6.61084 8.65959 6.80584 8.53606 7.02493 8.4985C7.24402 8.46095 7.46903 8.51248 7.64994 8.64163L10.4999 10.6333L13.3333 8.49997C13.7015 8.22383 14.2238 8.29844 14.4999 8.66663C14.7761 9.03482 14.7015 9.55716 14.3333 9.8333L10.9999 12.3333C10.8557 12.4415 10.6803 12.5 10.4999 12.5Z"
        fill={fillColor}
      />
      <path
        d="M10.5001 10.8333C10.0398 10.8333 9.66675 10.4602 9.66675 10V3.33333C9.66675 2.8731 10.0398 2.5 10.5001 2.5C10.9603 2.5 11.3334 2.8731 11.3334 3.33333V10C11.3334 10.4602 10.9603 10.8333 10.5001 10.8333Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ExportWhiteIcon;
