import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
type Props = {
  isOpen: boolean;
  title: string;
  alertContentText: string;
  cancelButtonText: string;
  deleteButtonText: string;
  handleClose: () => void;
  handleConfirm: () => void;
};

export const DeleteAlert = ({
  isOpen,
  title,
  alertContentText,
  cancelButtonText,
  deleteButtonText,
  handleClose,
  handleConfirm,
}: Props) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertContentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {cancelButtonText}
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            {deleteButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
