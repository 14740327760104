const ProprietorCardIcon = () => {
  return (
    <svg
      width="80"
      height="61"
      viewBox="0 0 80 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M33.0671 5.4082H35.1929C35.2994 5.45561 35.406 5.51487 35.5185 5.53858C39.539 6.386 42.2391 9.14753 42.9022 13.1535C43.0266 13.8943 43.0088 14.6647 43.0562 15.4884C43.3759 15.3462 43.672 15.2276 43.9562 15.0913C48.4859 12.9461 52.9978 10.7476 57.5572 8.6616C60.9263 7.12083 65.4382 7.66602 67.9725 11.5061C70.1693 14.8306 70.1574 18.4573 67.7771 21.853C66.9837 22.9848 66.0185 23.9982 65.1303 25.0767C65.7461 24.8456 66.3679 24.5849 67.0074 24.3775C72.6088 22.57 76.8543 24.9049 78.8142 29.782C80.0872 32.9406 79.0688 35.8325 77.0082 38.2681C75.5753 39.9629 73.799 41.3733 72.1114 42.8489C67.0192 47.2994 61.927 51.7617 56.7696 56.141C52.2162 60.0107 45.9576 58.1677 43.6601 54.0195C43.5476 53.818 43.3996 53.6402 43.2575 53.4328C42.9437 53.5632 42.6713 53.6699 42.4049 53.7825C37.6738 55.7677 32.9487 57.7707 28.2118 59.7381C22.6222 62.0552 16.476 58.0907 16.1859 52.0343C16.097 50.1439 16.6063 48.4431 17.7135 46.6357C16.0852 47.4179 14.7115 48.1409 13.2845 48.7453C10.241 50.0431 7.28633 49.8772 4.55666 47.9335C2.53754 46.4994 1.19343 44.5675 0.625 42.1259V39.8207C1.18751 37.7881 1.99871 35.9095 3.47901 34.3273C11.4608 25.7878 19.4188 17.2306 27.3532 8.64382C28.5078 7.39343 29.8105 6.45711 31.3915 5.88821C31.9362 5.69265 32.5106 5.56821 33.0671 5.4082Z"
        fill="#CBE7F6"
      />
      <path
        opacity="0.24"
        d="M63.6209 4.52914C63.0408 4.52914 62.4606 4.30821 62.0189 3.86664C61.1356 2.98336 61.1356 1.54602 62.0189 0.662579C62.9022 -0.22086 64.3397 -0.22086 65.223 0.662579C66.1063 1.54602 66.1063 2.98321 65.223 3.86664C64.7814 4.30821 64.2013 4.52914 63.6209 4.52914ZM63.6211 1.5618C63.4409 1.5618 63.2609 1.63023 63.1239 1.76742C62.8498 2.04149 62.8498 2.48758 63.1239 2.7618C63.398 3.03586 63.8441 3.03602 64.1183 2.7618C64.3924 2.48774 64.3924 2.04164 64.1183 1.76742C63.9811 1.63023 63.8011 1.5618 63.6211 1.5618Z"
        fill="#1C74A2"
      />
      <path
        opacity="0.48"
        d="M67.5498 12.1687C66.9696 12.1689 66.3895 11.948 65.9478 11.5062C65.5198 11.0783 65.2842 10.5094 65.2842 9.9042C65.2842 9.29905 65.5198 8.73014 65.9478 8.30217C66.8309 7.41889 68.2682 7.41873 69.1517 8.30217C69.5797 8.73014 69.8153 9.29905 69.8153 9.9042C69.8153 10.5095 69.5796 11.0784 69.1515 11.5062C68.7103 11.9478 68.13 12.1687 67.5498 12.1687ZM67.5498 9.20139C67.3698 9.20139 67.1897 9.26983 67.0526 9.40702C66.9197 9.53983 66.8467 9.71639 66.8467 9.9042C66.8467 10.092 66.9198 10.2686 67.0526 10.4014C67.3268 10.6756 67.7729 10.6756 68.047 10.4014C68.18 10.2686 68.2529 10.092 68.2529 9.9042C68.2529 9.71639 68.1798 9.53998 68.047 9.40702C67.91 9.26983 67.73 9.20139 67.5498 9.20139Z"
        fill="#1C74A2"
      />
      <path
        opacity="0.24"
        d="M15.8397 28.652C15.2595 28.652 14.6792 28.4311 14.2377 27.9895C13.3544 27.1061 13.3544 25.6687 14.2377 24.7854C15.1209 23.9023 16.5584 23.9022 17.4417 24.7854C18.325 25.6689 18.325 27.1061 17.4417 27.9895C17.0002 28.4311 16.4198 28.652 15.8397 28.652ZM15.8397 25.6847C15.6597 25.6847 15.4795 25.7531 15.3425 25.8903C15.0683 26.1644 15.0683 26.6104 15.3425 26.8847C15.6166 27.1589 16.0628 27.1587 16.3369 26.8847C16.6111 26.6106 16.6111 26.1645 16.3369 25.8903C16.1998 25.7531 16.0198 25.6847 15.8397 25.6847Z"
        fill="#1C74A2"
      />
      <path
        opacity="0.48"
        d="M14.833 35.6737C14.633 35.6737 14.4331 35.5974 14.2805 35.4449L13.3486 34.5131L12.4167 35.4449C12.1116 35.7499 11.617 35.7499 11.3119 35.4449C11.0067 35.1398 11.0067 34.6451 11.3119 34.3401L12.7962 32.8557C13.1014 32.5507 13.5959 32.5507 13.9011 32.8557L15.3855 34.3401C15.6906 34.6452 15.6906 35.1399 15.3855 35.4449C15.2328 35.5973 15.033 35.6737 14.833 35.6737Z"
        fill="#1C74A2"
      />
      <path
        opacity="0.48"
        d="M66.0654 58.2987C65.8654 58.2987 65.6655 58.2224 65.5129 58.0699L64.581 57.138L63.6491 58.0699C63.344 58.3749 62.8495 58.3749 62.5443 58.0699C62.2391 57.7648 62.2391 57.2701 62.5443 56.9651L64.0287 55.4807C64.3338 55.1757 64.8284 55.1757 65.1335 55.4807L66.6179 56.9651C66.923 57.2702 66.923 57.7649 66.6179 58.0699C66.4652 58.2223 66.2654 58.2987 66.0654 58.2987Z"
        fill="#1C74A2"
      />
      <g clipPath="url(#clip0_2057_37992)">
        <path
          d="M57.0926 45.0766C57.3918 45.0766 57.6634 45.0647 57.9338 45.079C58.5104 45.1101 58.8918 45.4842 58.9033 46.0136C58.9161 46.5585 58.5255 46.9553 57.9327 46.9947C57.8171 47.0019 57.7015 46.9971 57.586 46.9971C47.3753 46.9971 37.1634 46.9971 26.9527 46.9971C26.8522 46.9971 26.7505 46.9995 26.6499 46.9959C26.0248 46.9684 25.6215 46.5824 25.6296 46.0207C25.6377 45.4758 26.0317 45.1017 26.6338 45.0766C26.8903 45.0659 27.1492 45.0754 27.4369 45.0754V10.7728C26.0444 10.4214 25.6273 9.86452 25.6273 8.37788C25.6273 7.90106 25.6215 7.42423 25.6284 6.94741C25.6434 5.9101 26.3183 5.1381 27.3156 5.01621C27.4866 4.9947 27.6611 4.99948 27.8344 4.99948C37.4535 4.99948 47.0725 4.99948 56.6916 4.99948C58.2169 4.99948 58.908 5.71531 58.908 7.29517C58.908 7.66802 58.908 8.04088 58.908 8.41373C58.908 9.85855 58.455 10.4525 57.0937 10.768V45.0766H57.0926ZM55.172 45.0492V10.7489H29.3632V45.0468H34.8788C34.8788 44.8448 34.8788 44.6703 34.8788 44.4947C34.8788 40.9465 34.8777 37.3996 34.8788 33.8515C34.8788 32.4748 35.6092 31.7244 36.9404 31.7232C40.4638 31.7232 43.9883 31.753 47.5116 31.7052C48.6707 31.6897 49.6922 32.4414 49.6714 33.9471C49.6217 37.4642 49.6552 40.9824 49.6552 44.5006C49.6552 44.6751 49.6552 44.8496 49.6552 45.0492H55.172ZM27.4889 8.79973H57.066C57.066 8.28945 57.0683 7.80067 57.0648 7.31309C57.0648 7.19717 57.0463 7.08245 57.0359 6.95936H27.4889V8.79973ZM36.7347 45.0456H41.3131V37.4821H36.7347V45.0456ZM47.7844 45.0515V37.4773H43.2256V45.0515H47.7844ZM47.8028 33.6591H36.7474V35.5139H47.8017V33.6591H47.8028Z"
          fill="#85C7EA"
        />
        <path
          d="M36.744 22.175C37.3206 22.175 37.8984 22.1666 38.475 22.1774C39.6329 22.1977 40.4048 22.9792 40.421 24.1695C40.4372 25.3897 40.4383 26.611 40.421 27.8323C40.4048 29.0047 39.6456 29.7982 38.5143 29.8185C37.3171 29.8388 36.1188 29.84 34.9228 29.8185C33.7938 29.7982 33.0345 28.9975 33.0195 27.8288C33.0045 26.6074 33.0033 25.3873 33.0195 24.1659C33.0345 22.9792 33.8111 22.1977 34.969 22.1774C35.5606 22.1666 36.1523 22.1762 36.7428 22.1762L36.744 22.175ZM38.5339 27.8777V24.136H34.9089V27.8777H38.5339Z"
          fill="#85C7EA"
        />
        <path
          d="M47.7633 29.8238C47.2005 29.8238 46.6377 29.8322 46.075 29.8226C44.894 29.8023 44.1232 29.016 44.1116 27.8018C44.1001 26.596 44.0989 25.389 44.1116 24.1832C44.1255 22.9917 44.8928 22.1946 46.0461 22.1791C47.2294 22.1636 48.4116 22.1624 49.5949 22.1791C50.7262 22.1958 51.4958 22.9834 51.5143 24.1521C51.5339 25.3723 51.5305 26.5936 51.5155 27.8149C51.5016 29.01 50.7366 29.7951 49.581 29.8202C48.9755 29.8334 48.3688 29.8226 47.7633 29.8226V29.8238ZM49.6272 27.8783V24.1366H46.0022V27.8783H49.6272Z"
          fill="#85C7EA"
        />
        <path
          d="M36.7303 12.6491C37.3358 12.6491 37.9425 12.6396 38.548 12.6515C39.5984 12.673 40.3738 13.396 40.4096 14.4799C40.4535 15.7885 40.4512 17.1007 40.4119 18.4105C40.3784 19.5254 39.5776 20.2664 38.4844 20.2735C37.3011 20.2807 36.119 20.2819 34.9356 20.2735C33.8344 20.2652 33.0451 19.486 33.022 18.3471C32.9977 17.0816 32.9954 15.8148 33.022 14.5505C33.0474 13.4259 33.8182 12.6718 34.9102 12.6527C35.5157 12.642 36.1224 12.6503 36.728 12.6503L36.7303 12.6491ZM38.5295 18.3483V14.5732H34.9056V18.3483H38.5295Z"
          fill="#85C7EA"
        />
        <path
          d="M47.8131 12.6491C48.4187 12.6491 49.0253 12.6396 49.6309 12.6515C50.7021 12.6718 51.4752 13.4092 51.5064 14.5146C51.5433 15.8088 51.5433 17.1055 51.5064 18.4009C51.474 19.5171 50.6767 20.2664 49.5881 20.2735C48.4048 20.2807 47.2226 20.2807 46.0393 20.2735C44.9323 20.2664 44.1407 19.4956 44.1176 18.3567C44.091 17.0911 44.091 15.8256 44.1176 14.56C44.1407 13.4331 44.9092 12.673 45.9966 12.6527C46.6021 12.6408 47.2088 12.6503 47.8143 12.6503L47.8131 12.6491ZM49.6274 14.5755H46V18.3495H49.6274V14.5755Z"
          fill="#85C7EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_2057_37992">
          <rect
            width="33.283"
            height="42"
            fill="white"
            transform="translate(25.625 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProprietorCardIcon;
