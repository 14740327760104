const ViewIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 14.5C23.79 14.5 27.17 16.63 28.82 20C27.17 23.37 23.8 25.5 20 25.5C16.2 25.5 12.83 23.37 11.18 20C12.83 16.63 16.21 14.5 20 14.5ZM20 12.5C15 12.5 10.73 15.61 9 20C10.73 24.39 15 27.5 20 27.5C25 27.5 29.27 24.39 31 20C29.27 15.61 25 12.5 20 12.5ZM20 17.5C21.38 17.5 22.5 18.62 22.5 20C22.5 21.38 21.38 22.5 20 22.5C18.62 22.5 17.5 21.38 17.5 20C17.5 18.62 18.62 17.5 20 17.5ZM20 15.5C17.52 15.5 15.5 17.52 15.5 20C15.5 22.48 17.52 24.5 20 24.5C22.48 24.5 24.5 22.48 24.5 20C24.5 17.52 22.48 15.5 20 15.5Z"
        fill="#637381"
      />
    </svg>
  );
};

export default ViewIcon;
