import { Localization } from '@mui/material/locale';
export interface ILang {
  label: string;
  value: Locales;
  systemValue: Localization;
  icon: string;
}

export enum Locales {
  IT = 'it',
  EN = 'en',
}

export interface ICustomi18n {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
