import { FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { UploadMultiFile } from 'components/upload';
import UploadSingleFile from 'components/upload/UploadSingleFile/UploadSingleFile';
import { MultiFileProps, SingleFileProps } from 'types/file';

const RHFUploadSingleFile = ({ name, ...other }: SingleFileProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;
        return (
          <UploadSingleFile
            file={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
};

const RHFUploadMultiFile = ({ name, ...other }: MultiFileProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadMultiFile
            files={field.value}
            error={checkError}
            helperText={
              checkError && (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
};

export { RHFUploadSingleFile, RHFUploadMultiFile };
