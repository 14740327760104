import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { IInfoPanelProps } from './InfoPanel.types';
import { olympicBluePreset } from 'utils/getColorPresets';

const InfoPanel = ({ text, sx, bgColorVariant }: IInfoPanelProps) => {
  const theme = useTheme();

  const backgroundColor =
    bgColorVariant === 'lightBlue'
      ? olympicBluePreset.lighter
      : theme.palette.grey[300];

  return (
    <Paper
      sx={{
        p: theme.spacing(3),
        ...sx,
        backgroundColor,
      }}
    >
      <Stack>
        <Typography
          variant="caption"
          sx={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {text}
        </Typography>
      </Stack>
    </Paper>
  );
};
export { InfoPanel };
