import { IconButton } from '@mui/material';

interface Action {
  icon: JSX.Element;
  clickHandler(): void;
  disabled?: boolean | undefined;
}

interface ActionsProps {
  actions: Action[];
}

export const TableActions = ({ actions }: ActionsProps) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ cursor: 'pointer' }}
    >
      {actions.map((action: Action, idx: number) => (
        <IconButton
          key={idx}
          disabled={action.disabled}
          onClick={() => action.clickHandler()}
        >
          {action.icon}
        </IconButton>
      ))}
    </div>
  );
};
