import { LazyMotion } from 'framer-motion';

import { MotionLazyContainerProps } from './MotionLazyContainer.types';

// eslint-disable-next-line import/extensions
const loadFeatures = () => import('../features').then(res => res.default);

export default function MotionLazyContainer({
  children,
}: MotionLazyContainerProps) {
  return (
    <LazyMotion strict features={loadFeatures}>
      {children}
    </LazyMotion>
  );
}
