import { Box, Typography } from '@mui/material';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { ButtonsWrapper } from './PageHeader.styles';
import { IPageHeaderProps } from './PageHeader.types';
import { Breadcrumbs } from 'components';
import useTranslations from 'hooks/useTranslations';
import {
  excludePaths,
  getCustomBreadcrumbs,
} from 'routes/customBreadcrumbsMap';

const PageHeader = ({ title, buttons }: IPageHeaderProps) => {
  const i18n = useTranslations();
  const routes = getCustomBreadcrumbs(i18n);

  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths,
  });

  return (
    <div>
      <Box sx={{ mb: 5 }}>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
            <Breadcrumbs links={breadcrumbs} />
          </Box>

          {buttons && <ButtonsWrapper>{buttons}</ButtonsWrapper>}
        </Box>
      </Box>
    </div>
  );
};

export { PageHeader };
